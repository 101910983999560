/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DistributionListType } from '@seeeverything/ui.forms/src/redux/distributionList/types.ts';
import {
  MaterialList,
  MaterialListItemType,
} from '@seeeverything/ui.primitives/src/components/MaterialList/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export type PreviewListProps = {
  formLabel: string;
  type: DistributionListType;
  items: MaterialListItemType[];
};

export const PreviewList: React.FC<PreviewListProps> = ({
  items,
  type,
  formLabel,
}) => (
  <div css={styles.base}>
    <Text size={48} color={color.format(-0.25)} weight={300}>
      {'Preview'}
    </Text>
    <div css={styles.body}>
      <Text size={18} color={color.format(-0.7)}>
        {`Below is the list of results for this distribution list. Using this list in the scheduler will create a ${formLabel} for each of the ${
          type === 'Team' ? 'below teams' : 'below people'
        }.`}
      </Text>
      <MaterialList items={items} style={styles.selectionList} />
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flex: '1 1 auto',
    minHeight: 600,
    padding: '20px 40px',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  selectionList: css({
    maxHeight: 530,
  }),
};
