import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { Person } from '../types.ts';

export type GetTeamPersonMembershipsResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  people: Person[];
};

export type GetTeamPersonMembershipsArgs = {
  teamId: string;
  pageNumber?: number;
  name?: string;
  includeInactive?: boolean;
};

export const getTeamPersonMemberships = async (
  client: IGraphQLClient,
  args: GetTeamPersonMembershipsArgs,
): Promise<QueryResult<GetTeamPersonMembershipsResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        team: {
          memberships: {
            pageInfo: {
              hasNextPage: boolean;
              currentPage: number;
            };
            nodes: Array<{ person: Person }>;
          };
        };
      };
    }>({
      query: gql`
        query GetTeamPersonMemberships(
          $teamId: ID!
          $name: String
          $pageNumber: Int
          $includeInactive: Boolean
        ) {
          orgHierarchy {
            team(id: $teamId) {
              memberships(
                name: $name
                pagination: { pageNumber: $pageNumber, size: 50 }
                includeInactive: $includeInactive
              ) {
                pageInfo {
                  hasNextPage
                  currentPage
                }
                nodes {
                  person {
                    id
                    firstName
                    lastName
                    positionTitle
                    isActive
                    email
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        name: args.name,
        teamId: args.teamId,
        pageNumber: args.pageNumber ?? 1,
        includeInactive: args.includeInactive,
      },
      fetchPolicy: 'network-only',
    });

    const nodes = response.data.orgHierarchy.team.memberships.nodes;
    if (!nodes) {
      log.error(
        `Failed to retrieve team person memberships for team ${args.teamId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        people: nodes.map(
          ({ person }): Person => ({
            id: person.id,
            email: person.email,
            firstName: person.firstName,
            isActive: person.isActive,
            lastName: person.lastName,
            positionTitle: person.positionTitle,
          }),
        ),
        pageInfo: {
          currentPage:
            response.data.orgHierarchy.team.memberships.pageInfo.currentPage,
          hasNextPage:
            response.data.orgHierarchy.team.memberships.pageInfo.hasNextPage,
        },
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query team person memberships for team ${args.teamId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
