/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import List from '@mui/material/List';
import { MaterialListItem } from './components/MaterialListItem.tsx';
import { MaterialListItemType } from './types.ts';

export type MaterialListProps = {
  items: MaterialListItemType[];
  onListItemClick?: (id: string) => void;
  selectedId?: string;
  style?: SerializedStyles;
};

export const MaterialList: React.FC<MaterialListProps> = ({
  items = [],
  onListItemClick,
  selectedId,
  style,
}) => (
  <List sx={css(styles.base, style)}>
    {items.length === 0 ? (
      <MaterialListItem
        isSelected={false}
        item={{
          id: 'EMPTY_LIST_MESSAGE',
          value: 'EMPTY',
          isDimmed: true,
          text: 'Nothing to show.',
        }}
      />
    ) : (
      items.map((item, index) => (
        <MaterialListItem
          key={`MaterialListItem-${item.id}-${index}`}
          item={item}
          onClick={
            onListItemClick ? () => onListItemClick?.(item.id) : undefined
          }
          isSelected={item.id === selectedId}
        />
      ))
    )}
  </List>
);

const styles = {
  base: css({
    padding: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }),
};
