/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MaterialSwitch from '@mui/material/Switch';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export interface ISwitchProps {
  dataTest?: string;
  label?: string;
  labelPosition?: 'end' | 'start' | 'top' | 'bottom';
  labelStyle?: React.CSSProperties;
  helperText?: string;
  error?: string;
  defaultIsChecked?: boolean;
  isChecked?: boolean;
  isEnabled?: boolean;
  onChange?: (to: boolean) => void;
  style?: SerializedStyles;
  tooltip?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

/**
 * An on/off toggle switch.
 */
export const Switch: React.FC<ISwitchProps> = ({
  isEnabled = true,
  dataTest,
  defaultIsChecked,
  error,
  helperText,
  isChecked,
  label,
  labelPosition,
  labelStyle,
  onChange,
  onClick,
  onMouseDown,
  style,
  tooltip,
}) => (
  <div
    css={css(styles.base, style)}
    onClick={onClick}
    onMouseDown={onMouseDown}
    data-test={dataTest}
  >
    <Tooltip title={tooltip}>
      <FormControlLabel
        control={
          <MaterialSwitch
            disabled={!isEnabled}
            onChange={(_, to) => onChange?.(to)}
            checked={isChecked ?? defaultIsChecked}
          />
        }
        label={label}
        labelPlacement={labelPosition}
        style={labelStyle}
      />
    </Tooltip>
    {(error || helperText) && (
      <FormHelperText error={Boolean(error)}>
        {error || helperText}
      </FormHelperText>
    )}
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
};
