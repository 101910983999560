/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@seeeverything/ui.primitives/src/components/Accordion/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export type SectionProps = {
  children: React.ReactNode;
  id: string;
  title: string;
  overdueLabel?: string;
  isDefaultExpanded: boolean;
  isArchived: boolean;
};

export const Section: React.FC<SectionProps> = ({
  id,
  children,
  title,
  overdueLabel,
  isDefaultExpanded,
  isArchived,
}) => (
  <Accordion id={id} isDefaultExpanded={isDefaultExpanded}>
    <AccordionSummary>
      <div css={styles.summaryLabelContainer}>
        <Text
          cursor={'pointer'}
          color={isArchived ? color.format(-0.5) : color.format(-0.8)}
          italic={isArchived}
        >
          {title}
        </Text>
        {overdueLabel && (
          <Text size={12} color={COLORS.ERROR_RED} cursor={'pointer'}>
            {overdueLabel}
          </Text>
        )}
      </div>
    </AccordionSummary>
    <AccordionDetails style={styles.details}>{children}</AccordionDetails>
  </Accordion>
);

const styles = {
  summaryLabelContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1 1 auto',
    cursor: 'pointer',
  }),
  details: css({
    padding: 16,
  }),
};
