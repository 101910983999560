/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { InputProps as MaterialInputProps } from '@mui/material/Input';
import MaterialTextField from '@mui/material/TextField';
import { useState } from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { MarkdownTextField } from '../MarkdownTextField/MarkdownTextField.tsx';
import { OutsideAlerter } from '../OutsideAlerter/OutsideAlerter.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type TextFieldProps = {
  dataTest?: string;
  defaultValue?: string;
  error?: string;
  helperText?: string;
  id: string;
  InputProps?: MaterialInputProps;
  inputStyle?: SerializedStyles;
  isEnabled?: boolean;
  label?: string;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  onBlur?: () => void;
  onChange?: (to: string) => void;
  onFocus?: () => void;
  onKeyDown?: (key: string) => void;
  outlinedInputStyle?: SerializedStyles;
  placeholder?: string;
  required?: boolean;
  style?: SerializedStyles;
  tooltip?: string;
  value?: string;
  variant?: 'standard' | 'filled' | 'outlined';
};

/**
 * A text input field.
 */
export const TextField: React.FC<TextFieldProps> = ({
  dataTest,
  defaultValue,
  error,
  helperText,
  id,
  InputProps,
  inputStyle,
  isEnabled = true,
  label,
  maxRows,
  minRows,
  multiline = false,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  outlinedInputStyle,
  placeholder,
  required,
  style,
  tooltip,
  value = '',
  variant = 'standard',
}) => {
  const [focused, setFocused] = useState(false);
  const [selectAllOnFocus, setSelectAllOnFocus] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  const handleParentFocus = (
    selectAll: boolean,
    autoFocusOnParentFocus: boolean = false,
  ) => {
    if (!isEnabled) return;
    setSelectAllOnFocus(selectAll);
    setAutoFocus(autoFocusOnParentFocus);
    setFocused(true);
  };
  const handleParentBlur = () => {
    if (!focused || !isEnabled) return;

    setTimeout(() => {
      setFocused(false);
    }, 125);
  };

  const computedStyles = {
    base: css(
      styles.base,
      error ? CommonStyles.AnimationShake : undefined,
      style,
    ),
    textField: css({
      '.MuiOutlinedInput-root': outlinedInputStyle,
      '.MuiInput-root': inputStyle,
    }),
  };

  const requiredHint =
    required !== undefined
      ? `(${required ? 'Required' : 'Optional'})`
      : undefined;

  const labelWithHint =
    requiredHint && !label.endsWith(requiredHint)
      ? `${label} ${requiredHint}`.trim()
      : label;

  const isError = Boolean(error);

  // These aren't supported in the substitute component, so just render the material text field if these are encountered.
  const forceRenderMaterialTextField = Boolean(
    InputProps || variant !== 'standard' || maxRows,
  );

  return (
    <div css={computedStyles.base}>
      <FormControl fullWidth={true}>
        <OutsideAlerter onClickedOutside={handleParentBlur}>
          <div
            data-test={dataTest}
            onClick={() => handleParentFocus(false)}
            onMouseEnter={() => handleParentFocus(false)}
            onFocus={() => handleParentFocus(true, true)} // Invoked when TAB-bing into field.
            onTouchStart={() => handleParentFocus(false, true)}
            tabIndex={0}
          >
            {focused || forceRenderMaterialTextField ? (
              <Tooltip title={tooltip || labelWithHint}>
                <MaterialTextField
                  autoComplete={'off'}
                  id={id}
                  defaultValue={defaultValue}
                  fullWidth={true}
                  value={value}
                  label={labelWithHint}
                  error={isError}
                  onBlur={() => {
                    const trimmed = value?.trim();
                    if (trimmed !== value) onChange?.(trimmed);
                    onBlur?.();
                  }}
                  onFocus={(e) => {
                    onFocus?.();

                    if (!selectAllOnFocus && !autoFocus) return;

                    const inputLength = e.target.value.length;
                    const cursorStartPos = autoFocus ? 0 : inputLength;
                    e.target.setSelectionRange(cursorStartPos, inputLength);
                  }}
                  onChange={(e) => onChange?.(e.target.value)}
                  onKeyDown={(e) => onKeyDown?.(e.key)}
                  disabled={!isEnabled}
                  multiline={multiline}
                  minRows={minRows}
                  maxRows={maxRows}
                  placeholder={placeholder}
                  variant={variant}
                  slotProps={{
                    input: {
                      type: 'text',
                      autoComplete: 'off',
                      autoFocus,
                      ...InputProps,
                    },
                  }}
                  sx={computedStyles.textField}
                />
              </Tooltip>
            ) : (
              <MarkdownTextField
                label={labelWithHint}
                value={value}
                isEnabled={isEnabled}
                isError={isError}
                inputStyle={inputStyle}
              />
            )}
          </div>
        </OutsideAlerter>
      </FormControl>
      {(error || helperText) && (
        <FormHelperText error={isError}>{error || helperText}</FormHelperText>
      )}
    </div>
  );
};

const styles = {
  base: css({
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  }),
};
