/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

export type CheckboxProps = {
  dataTest?: string;
  error?: string;
  helperText?: string;
  indeterminate?: boolean;
  isChecked?: boolean;
  isEnabled?: boolean;
  label?: React.ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  onCheck?: (to: boolean) => void;
  style?: SerializedStyles;
  width?: number | string;
};

/**
 * A Checkbox that can be ticked and unticked.
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  dataTest,
  error,
  helperText,
  indeterminate = false,
  isChecked = false,
  isEnabled = true,
  label,
  labelPlacement,
  onCheck,
  style,
  width,
}) => {
  const computedStyles = {
    base: css({
      display: helperText ? 'block' : 'inline-block',
      width,
    }),
  };

  const elCheckbox = (
    <MaterialCheckbox
      checked={isChecked}
      indeterminate={indeterminate}
      onChange={(_, to) => onCheck?.(to)}
      disabled={!isEnabled}
      sx={styles.checkbox}
    />
  );

  return (
    <div css={[computedStyles.base, style]} data-test={dataTest}>
      {label ? (
        <FormControlLabel
          control={elCheckbox}
          label={label}
          labelPlacement={labelPlacement}
        />
      ) : (
        elCheckbox
      )}
      {helperText && (
        <FormHelperText error={Boolean(error)}>
          {error || helperText}
        </FormHelperText>
      )}
    </div>
  );
};

const styles = {
  checkbox: css({
    '&.MuiCheckbox-root': css({
      padding: 9,
      marginRight: 0,
    }),
  }),
};
