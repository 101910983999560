/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CoachingSummarySectionInsight } from '@se/data/forms/types.ts';
import {
  Accordion,
  IAccordionChangeEvent,
} from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useState } from 'react';
import { CoachingSummaryHighlight } from './CoachingSummaryHighlight.tsx';

export type CoachingSummaryHighlightGroupProps = {
  instanceId?: string;
  label: string;
  highlights: CoachingSummarySectionInsight[];
  sectionExpanded: boolean;
  onAccordionChange?: () => void;
  size: 'large' | 'small';
};

export const CoachingSummaryHighlightGroup: React.FC<
  CoachingSummaryHighlightGroupProps
> = ({
  instanceId,
  label,
  highlights,
  sectionExpanded,
  onAccordionChange,
  size,
}) => {
  const [isExpanded, setIsExpanded] = useState(sectionExpanded);

  const handleExpandCollapse = useCallback(
    (e: IAccordionChangeEvent) => {
      setIsExpanded(e.isExpanded);
      onAccordionChange?.();
    },
    [onAccordionChange],
  );

  const elSummaryContent = (
    <div css={styles.contentInner}>
      <Icons.emojiEvents
        fill={COLORS.GOLD}
        style={styles.iconPadding}
        tooltip={'Highlight'}
        cursor={'pointer'}
        size={size === 'small' ? 22 : undefined}
      />
      <div css={styles.summaryText}>
        <Text
          color={color.format(-0.8)}
          ellipsis={true}
          style={styles.highlightLabel}
          cursor={'pointer'}
          weight={500}
          size={'inherit'}
        >
          {label}
        </Text>
      </div>
    </div>
  );

  return (
    <Accordion
      id={`highlight-${label}`}
      elevation={0}
      isExpanded={isExpanded}
      onChange={handleExpandCollapse}
      hideDividers={true}
      isDefaultExpanded={true}
    >
      <AccordionSummary
        rootStyle={
          size === 'small'
            ? styles.accordionSummarySmall
            : styles.accordionSummary
        }
        expandedStyle={styles.accordionExpanded}
        summaryContentStyle={
          size === 'small' ? styles.accordionSummaryContentSmall : undefined
        }
      >
        <div
          css={[
            styles.summaryContent,
            size === 'small' ? styles.smallFont : styles.largeFont,
          ]}
        >
          {elSummaryContent}
          <Text
            color={COLORS.BLUE}
            size={size === 'small' ? 13 : 14}
            cursor={'pointer'}
            style={styles.summaryShowHideText}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </Text>
        </div>
      </AccordionSummary>
      <AccordionDetails style={styles.detailsBase}>
        <div
          css={[
            styles.highlights,
            size === 'small' ? styles.smallFont : styles.largeFont,
          ]}
        >
          {highlights.map((highlight) => (
            <CoachingSummaryHighlight
              key={highlight.id}
              instanceId={instanceId}
              highlight={highlight}
              size={size}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  accordionSummary: css({
    backgroundColor: '#fafad2',
  }),
  accordionSummarySmall: css({
    backgroundColor: '#fafad2',
    minHeight: '44px',
  }),
  accordionExpanded: css({
    backgroundColor: '#fafad2',
  }),
  accordionSummaryContentSmall: css({
    margin: '7px 0',
  }),
  summaryText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'start',
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  detailsBase: css({
    background: '#fefefe',
    border: `1px solid #cecece`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    padding: '12px 16px',
  }),
  iconPadding: {
    paddingRight: 10,
  },
  summaryContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
  summaryShowHideText: css({
    alignSelf: 'center',
    padding: '0 5px 0 20px',
  }),
  highlightLabel: css({
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
  }),
  highlights: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  smallFont: css({
    fontSize: 14,
  }),
  largeFont: css({
    fontSize: 16,
  }),
};
