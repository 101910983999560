import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy, PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  FormAction,
  FormActionStatusWithOverdue,
  FormGoal,
  FormGoalStatus,
} from '../types.ts';

type GoalsResponse = {
  pageInfo: PageInfoResponse;
  goals: FormGoal[];
};

type GetGoalsArgs = {
  actionLabel: string;
  endDate?: string;
  fetchAllPages?: boolean;
  orderBy: OrderBy[];
  pageNumber?: number;
  pageSize?: number;
  personId?: string;
  startDate?: string;
  status: {
    include?: FormActionStatusWithOverdue[];
    exclude?: FormActionStatusWithOverdue[];
  };
  teamId?: string;
};

export const getGoals = async (
  client: IGraphQLClient,
  args: GetGoalsArgs,
): Promise<QueryResult<GoalsResponse>> => {
  if (!args.fetchAllPages) return getGoalsPage(client, args);

  try {
    const goals = await getGoalsRecursive(client, args);
    return {
      isSuccess: true,
      data: {
        goals,
        pageInfo: {
          currentPage: 1,
          hasNextPage: false,
          pageSize: goals.length,
          totalCount: goals.length,
          totalPages: 1,
        },
      },
    };
  } catch (error) {
    log.error(error);
    return { isSuccess: false, error, errorReason: 'UNKNOWN' };
  }
};

const getGoalsRecursive = async (
  client: IGraphQLClient,
  args: GetGoalsArgs,
  goals: FormGoal[] = [],
  pageNumber = 1,
): Promise<FormGoal[]> => {
  const response = await getGoalsPage(client, {
    actionLabel: args.actionLabel,
    endDate: args.endDate,
    orderBy: args.orderBy,
    pageNumber,
    pageSize: args.pageSize,
    personId: args.personId,
    startDate: args.startDate,
    status: args.status,
    teamId: args.teamId,
  });

  if (!response.isSuccess) {
    throw new Error(
      `Failed to retrieve goals for page ${pageNumber} and entity id ${args.personId ?? args.teamId}`,
    );
  }

  const combinedGoals = goals.concat(response.data.goals);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return combinedGoals;

  return getGoalsRecursive(
    client,
    args,
    combinedGoals,
    pageInfo.currentPage + 1,
  );
};

const getGoalsPage = async (
  client: IGraphQLClient,
  args: GetGoalsArgs,
): Promise<QueryResult<GoalsResponse>> => {
  try {
    const response = await client.query<{
      forms: {
        formGoals: {
          pageInfo: {
            currentPage: number;
            hasNextPage: boolean;
            pageSize: number;
            totalCount: number;
            totalPages: number;
          };
          nodes: Array<{
            id: string;
            actions?: Array<{
              id: string;
              assignedTo: {
                id: string;
                name: string;
              };
              createdAt: string;
              description: string;
              dueBy: string;
              status: FormActionStatusWithOverdue;
            }>;
            assignedTo: {
              id: string;
              name: string;
            };
            createdAt: string;
            description: string;
            dueBy: string;
            goalCategory?: {
              id: string;
              name: string;
            };
            status: FormGoalStatus;
          }>;
        };
      };
    }>({
      query: gql`
        query FormGoals(
          $orderBy: [OrderByInput!]
          $pageNumber: Int!
          $pageSize: Int!
          $personId: ID
          $teamId: ID
          $startDate: String
          $endDate: String
          $status: FormGoalStatusFilterInput!
        ) {
          forms {
            formGoals(
              assignedToPersonId: $personId
              assignedToTeamId: $teamId
              orderBy: $orderBy
              pagination: { size: $pageSize, pageNumber: $pageNumber }
              startDate: $startDate
              endDate: $endDate
              status: $status
            ) {
              pageInfo {
                currentPage
                hasNextPage
                pageSize
                totalCount
                totalPages
              }
              nodes {
                id
                actions {
                  id
                  assignedTo {
                    id
                    name
                  }
                  createdAt
                  description
                  dueBy
                  status
                }
                assignedTo {
                  id
                  name
                }
                createdAt
                description
                dueBy
                goalCategory {
                  id
                  name
                }
                status
              }
            }
          }
        }
      `,
      variables: {
        orderBy: args.orderBy,
        personId: args.personId,
        teamId: args.teamId,
        status: args.status,
        startDate: args.startDate,
        endDate: args.endDate,
        pageNumber: args.pageNumber ?? 1,
        pageSize: args.pageSize ?? 10,
      },
      fetchPolicy: 'network-only',
    });

    const formGoals = response.data.forms.formGoals;

    return {
      isSuccess: true,
      data: {
        pageInfo: formGoals.pageInfo,
        goals: formGoals.nodes.map(
          (g): FormGoal => ({
            id: g.id,
            actions: (g.actions ?? [])
              .filter((a) => !(args.status.exclude ?? []).includes(a.status))
              .map(
                (a): FormAction => ({
                  id: a.id,
                  assignedTo: a.assignedTo && {
                    id: a.assignedTo.id,
                    name: a.assignedTo.name,
                  },
                  createdAt: a.createdAt,
                  description: a.description,
                  dueBy: a.dueBy,
                  goalId: g.id,
                  status: a.status,
                  statusLabel: a.status,
                  type: 'FormAction',
                  typeLabel: args.actionLabel,
                }),
              )
              .sort((first, second) => {
                for (const orderBy of args.orderBy) {
                  if (orderBy.fieldName === 'type') return 0;

                  const key = orderBy.fieldName as keyof FormAction;

                  if (first[key] < second[key])
                    return orderBy.direction === 'Ascending' ? -1 : 1;

                  if (first[key] > second[key])
                    return orderBy.direction === 'Ascending' ? 1 : -1;
                }
                return 0;
              }),
            assignedTo: g.assignedTo && {
              id: g.assignedTo.id,
              name: g.assignedTo.name,
            },
            createdAt: g.createdAt,
            description: g.description ?? undefined,
            dueBy: g.dueBy ?? undefined,
            goalCategory: g.goalCategory && {
              id: g.goalCategory.id,
              name: g.goalCategory.name,
            },
            type: 'FormGoal',
            typeLabel: g.goalCategory?.name ?? 'Goal',
            statusLabel: g.status,
            status: g.status,
          }),
        ),
      },
    };
  } catch (error) {
    log.error(
      `Failed to retrieve goals page for entity id ${args.personId ?? args.teamId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
