import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { Team } from '../types.ts';
import { toBusinessUnit } from '../utils/businessUnits.ts';

export type GetTeamsResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  teams: Team[];
};

type ServerTeams = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  nodes: Array<{
    id: string;
    name: string;
    businessUnit: string;
    path: string;
    hierarchyLevel: number;
    hasMembers: boolean;
    isActive: boolean;
  }>;
};

export type GetTeamsArgs = {
  tenantConfig: TenantConfiguration;
  pageNumber?: number;
  businessUnit?: string;
  name?: string;
  search?: string;
  path?: string;
  parentAndChildTeamsFilter?: string;
  excludeNonManagerMemberships?: boolean;
  excludeNonParentTeams?: boolean;
  includeInactive?: boolean;
  fetchAllPages?: boolean;
};

export const getTeams = async (
  client: IGraphQLClient,
  args: GetTeamsArgs,
): Promise<QueryResult<GetTeamsResponseDto>> => {
  if (!args.fetchAllPages) return getTeamsPage(client, args);
  const allResults = await getTeamsRecursive(client, args);
  return {
    isSuccess: true,
    data: {
      teams: allResults,
      pageInfo: { hasNextPage: false, currentPage: 1 },
    },
  };
};

const getTeamsRecursive = async (
  client: IGraphQLClient,
  args: GetTeamsArgs,
  teams: Team[] = [],
  pageNumber = 1,
): Promise<Team[]> => {
  const response = await getTeamsPage(client, {
    tenantConfig: args.tenantConfig,
    businessUnit: args.businessUnit,
    name: args.name,
    search: args.search,
    path: args.path,
    excludeNonManagerMemberships: args.excludeNonManagerMemberships,
    excludeNonParentTeams: args.excludeNonParentTeams,
    includeInactive: args.includeInactive,
    pageNumber,
  });

  if (!response.isSuccess) throw new Error('Failed to retrieve teams');

  const results = teams.concat(response.data.teams);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return results;

  return getTeamsRecursive(client, args, results, pageInfo.currentPage + 1);
};

const getTeamsPage = async (
  client: IGraphQLClient,
  args: GetTeamsArgs,
): Promise<QueryResult<GetTeamsResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: { teams: ServerTeams };
    }>({
      query: gql`
        query GetTeamsPage(
          $pageNumber: Int!
          $businessUnit: String
          $name: String
          $search: String
          $path: String
          $excludeNonManagerMemberships: Boolean
          $excludeNonParentTeams: Boolean
          $includeInactive: Boolean
          $parentAndChildTeamsFilter: ID
        ) {
          orgHierarchy {
            teams(
              pagination: { pageNumber: $pageNumber, size: 100 }
              businessUnit: $businessUnit
              name: $name
              search: $search
              orderBy: [{ fieldName: "path", direction: Ascending }]
              path: $path
              excludeNonManagerMemberships: $excludeNonManagerMemberships
              excludeNonParentTeams: $excludeNonParentTeams
              includeInactive: $includeInactive
              parentAndChildTeamsFilter: $parentAndChildTeamsFilter
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                id
                name
                businessUnit
                path
                hierarchyLevel
                hasMembers
                isActive
              }
            }
          }
        }
      `,
      variables: {
        businessUnit: args.businessUnit,
        excludeNonManagerMemberships:
          args.excludeNonManagerMemberships ?? false,
        excludeNonParentTeams: args.excludeNonParentTeams ?? false,
        name: args.name,
        pageNumber: args.pageNumber ?? 1,
        path: args.path,
        includeInactive: args.includeInactive,
        parentAndChildTeamsFilter: args.parentAndChildTeamsFilter,
      },
      fetchPolicy: 'network-only',
    });

    const teams = response.data.orgHierarchy.teams.nodes;
    if (!teams) {
      log.error(`Failed to retrieve teams`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        teams: teams.map((team) => ({
          id: team.id,
          name: team.name,
          businessUnit: toBusinessUnit(team.businessUnit, args.tenantConfig),
          path: team.path,
          hierarchyLevel: team.hierarchyLevel,
          hasMembers: team.hasMembers,
          isActive: team.isActive,
        })),
        pageInfo: {
          currentPage: response.data.orgHierarchy.teams.pageInfo.currentPage,
          hasNextPage: response.data.orgHierarchy.teams.pageInfo.hasNextPage,
        },
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query teams - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
