import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { DashboardTimespanVariable } from '../../dashboards/query/types.ts';
import { QueryResult, SimpleEntity } from '../../types.ts';
import {
  CoachingSummarySectionIssue,
  FormActionStatus,
  FormInstanceStatus,
} from '../types.ts';

type GetDashboardCoachingSummaryIssuesArgs = {
  entityId: string;
  entityType: 'Person' | 'Team';
  timespan: DashboardTimespanVariable;
  fetchAllPages?: boolean;
  pageNumber?: number;
};

export type DashboardCoachingSummaryIssuesDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
    totalCount: number;
  };
  issues: CoachingSummarySectionIssue[];
};

export const getDashboardCoachingSummaryIssues = async (
  client: IGraphQLClient,
  args: GetDashboardCoachingSummaryIssuesArgs,
): Promise<QueryResult<DashboardCoachingSummaryIssuesDto>> => {
  if (!args.fetchAllPages) {
    return await getDashboardCoachingSummaryIssuesPage(client, args);
  }

  try {
    const allIssues = await getDashboardCoachingSummaryIssuesRecursive(
      client,
      args,
    );
    return {
      isSuccess: true,
      data: {
        issues: allIssues,
        pageInfo: {
          hasNextPage: false,
          currentPage: 1,
          totalCount: allIssues.length,
        },
      },
    };
  } catch (error) {
    log.error(error);
    return { isSuccess: false, error, errorReason: 'UNKNOWN' };
  }
};

const getDashboardCoachingSummaryIssuesRecursive = async (
  client: IGraphQLClient,
  args: GetDashboardCoachingSummaryIssuesArgs,
  issues: CoachingSummarySectionIssue[] = [],
  pageNumber = 1,
): Promise<CoachingSummarySectionIssue[]> => {
  const response = await getDashboardCoachingSummaryIssuesPage(client, {
    entityId: args.entityId,
    entityType: args.entityType,
    timespan: args.timespan,
    pageNumber,
  });

  if (!response.isSuccess) {
    throw new Error(
      `Failed to retrieve coaching summary issues for page ${pageNumber}`,
    );
  }

  const nextIssues = issues.concat(response.data.issues);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return nextIssues;

  return getDashboardCoachingSummaryIssuesRecursive(
    client,
    args,
    nextIssues,
    pageInfo.currentPage + 1,
  );
};

const getDashboardCoachingSummaryIssuesPage = async (
  client: IGraphQLClient,
  args: GetDashboardCoachingSummaryIssuesArgs,
): Promise<QueryResult<DashboardCoachingSummaryIssuesDto>> => {
  try {
    const response = await client.query<{
      forms: {
        dashboardCoachingSummaryIssues: {
          pageInfo: {
            hasNextPage: boolean;
            currentPage: number;
            totalCount: number;
          };
          nodes: Array<{
            id: string;
            issueCoachingRequired?: boolean;
            instance: {
              id: string;
              dueBy: string;
              name: string;
              status: FormInstanceStatus;
            };
            label: string;
            notesDetail: { label: string; value: string };
            classifications?: string[];
            answer?: {
              id: string;
              key: string;
              displayValue?: string;
              value: string;
            };
            actions: Array<{
              id: string;
              assignedTo: SimpleEntity;
              description: string;
              dueBy?: string;
              status: FormActionStatus;
            }>;
            reviewActions: Array<{
              id: string;
              assignedTo: SimpleEntity;
              description: string;
              dueBy?: string;
              status: FormActionStatus;
            }>;
            formSectionName: string;
            formQuestionText: string;
          }>;
        };
      };
    }>({
      query: gql`
        query DashboardCoachingSummaryIssues(
          $entityId: ID!
          $entityType: DashboardPersonOrTeam!
          $timespan: DashboardTimespanFilterInput
          $pageNumber: Int!
        ) {
          forms {
            dashboardCoachingSummaryIssues(
              entityId: $entityId
              entityType: $entityType
              timespan: $timespan
              pagination: { pageNumber: $pageNumber, size: 100 }
            ) {
              pageInfo {
                hasNextPage
                totalCount
                pageSize
                currentPage
              }
              nodes {
                id
                issueCoachingRequired
                instance {
                  id
                  dueBy
                  name
                  status
                }
                label
                notesDetail {
                  label
                  value
                }
                classifications
                answer {
                  id
                  key
                  displayValue
                  value
                }
                actions {
                  id
                  assignedTo {
                    id
                    name
                  }
                  description
                  dueBy
                  status
                }
                reviewActions {
                  id
                  assignedTo {
                    id
                    name
                  }
                  description
                  dueBy
                  status
                }
                formSectionName
                formQuestionText
              }
            }
          }
        }
      `,
      variables: {
        entityId: args.entityId,
        entityType: args.entityType,
        timespan: args.timespan,
        pageNumber: args.pageNumber ?? 1,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } =
      response.data.forms.dashboardCoachingSummaryIssues;

    if (!nodes)
      return {
        isSuccess: false,
        errorReason: 'NOT_FOUND',
      };

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
          totalCount: pageInfo.totalCount,
        },
        issues: nodes.map((sectionIssue) => ({
          id: sectionIssue.id,
          issueCoachingRequired: sectionIssue.issueCoachingRequired,
          instance: sectionIssue.instance
            ? {
                id: sectionIssue.instance.id,
                dueBy: sectionIssue.instance.dueBy,
                name: sectionIssue.instance.name,
                status: sectionIssue.instance.status,
              }
            : undefined,
          label: sectionIssue.label,
          notesDetail: sectionIssue.notesDetail
            ? {
                label: sectionIssue.notesDetail.label,
                value: sectionIssue.notesDetail.value,
              }
            : undefined,
          classifications: sectionIssue.classifications ?? [],
          answer: sectionIssue.answer
            ? {
                id: sectionIssue.answer.id,
                key: sectionIssue.answer.key,
                displayValue: sectionIssue.answer.displayValue,
                value: sectionIssue.answer.value,
              }
            : undefined,
          actions: (sectionIssue.actions ?? []).map((action) => ({
            id: action.id,
            assignedTo: action.assignedTo
              ? { id: action.assignedTo.id, name: action.assignedTo.name }
              : undefined,
            description: action.description,
            dueBy: action.dueBy,
            status: action.status,
          })),
          reviewActions: (sectionIssue.reviewActions ?? []).map((action) => ({
            id: action.id,
            assignedTo: action.assignedTo
              ? { id: action.assignedTo.id, name: action.assignedTo.name }
              : undefined,
            description: action.description,
            dueBy: action.dueBy,
            status: action.status,
          })),
          formSectionName: sectionIssue.formSectionName,
          formQuestionText: sectionIssue.formQuestionText,
        })),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query coaching summary issues for entity ${args.entityId} ${args.entityType} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
