import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';

export type GetPositionTitlesResponseDto = {
  pageInfo: { hasNextPage: boolean; currentPage: number };
  positionTitles: string[];
};

export const getPositionTitles = async (
  client: IGraphQLClient,
  pageNumber = 1,
  filter?: string,
): Promise<QueryResult<GetPositionTitlesResponseDto>> => {
  try {
    const response = await client.query<{
      orgHierarchy: {
        positionTitles: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: Array<{ positionTitle: string }>;
        };
      };
    }>({
      query: gql`
        query PositionTitles($filter: String, $pageNumber: Int!) {
          orgHierarchy {
            positionTitles(
              pagination: { pageNumber: $pageNumber, size: 30 }
              positionTitle: $filter
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                positionTitle
              }
            }
          }
        }
      `,
      variables: { filter, pageNumber },
      fetchPolicy: 'cache-first',
    });

    const result = response.data.orgHierarchy.positionTitles.nodes;
    if (!result) {
      log.error(`Failed to retrieve position titles`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage:
            response.data.orgHierarchy.positionTitles.pageInfo.hasNextPage,
          currentPage:
            response.data.orgHierarchy.positionTitles.pageInfo.currentPage,
        },
        positionTitles: result.map((node) => node.positionTitle),
      },
    };
  } catch (error) {
    log.error(`Something went wrong trying to query position titles`, error);
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
