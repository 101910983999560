import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormAutomatedActionConfiguration,
  FormTemplate,
} from '@se/data/forms/types.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';

type GridRowId = string;

export type AutomatedActionsConfigurationState = {
  automatedActions: FormAutomatedActionConfiguration[];
  editingRows: Record<GridRowId, boolean>;
  savingRows: Record<GridRowId, boolean>;
  errorRows: Record<GridRowId, string>;
  gridData: IGridRow<FormAutomatedActionConfiguration>[];
  isLoading: boolean;
  isFetching: boolean;
  selectedTemplate?: FormTemplate;
  showAll: boolean;
};

const DEFAULT_STATE: AutomatedActionsConfigurationState = {
  automatedActions: [],
  editingRows: {},
  savingRows: {},
  errorRows: {},
  gridData: [],
  isLoading: false,
  isFetching: false,
  selectedTemplate: undefined,
  showAll: false,
};

const slice = createSlice({
  name: 'libs/forms/automatedActionConfiguration',
  initialState: DEFAULT_STATE,
  reducers: {
    reset(state) {
      state.showAll = false;
      state.selectedTemplate = undefined;
      state.isLoading = false;
      state.isFetching = false;
      state.automatedActions = [];
      state.gridData = [];
      state.editingRows = {};
    },
    selectTemplate(state, action: PayloadAction<FormTemplate>) {
      state.selectedTemplate = action.payload;
      state.automatedActions = [];
      state.gridData = [];
      state.editingRows = {};
    },
    toggleShowAll(state) {
      state.showAll = !state.showAll;
    },
    loadTemplateConfigurations(
      state,
      action: PayloadAction<{
        templateId: string;
        showIsLoading: boolean;
        gridRowId?: string;
      }>,
    ) {
      if (action.payload.showIsLoading) {
        state.isLoading = true;
      } else {
        state.isFetching = true;
      }
    },
    loadedTemplateConfigurations(
      state,
      action: PayloadAction<{
        templateId: string;
        automatedActions: FormAutomatedActionConfiguration[];
        gridData: IGridRow<FormAutomatedActionConfiguration>[];
        gridRowId?: string;
      }>,
    ) {
      if (state.selectedTemplate?.id !== action.payload.templateId) return;
      state.automatedActions = action.payload.automatedActions;
      state.gridData = action.payload.gridData;
      state.isLoading = false;
      state.isFetching = false;
      state.savingRows[action.payload.gridRowId] = false;
    },
    exportToCsv(state) {
      return state;
    },
    exportToCsvResult(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ isSuccess: boolean }>,
    ) {
      return state;
    },
    editRow(state, action: PayloadAction<{ gridRowId: string }>) {
      state.editingRows[action.payload.gridRowId] = true;
      state.errorRows[action.payload.gridRowId] = undefined;
    },
    cancelEditingRow(state, action: PayloadAction<{ gridRowId: string }>) {
      state.savingRows[action.payload.gridRowId] = false;
      state.editingRows[action.payload.gridRowId] = false;
      state.errorRows[action.payload.gridRowId] = undefined;
    },
    clearRowError(state, action: PayloadAction<{ gridRowId: string }>) {
      state.errorRows[action.payload.gridRowId] = undefined;
    },
    clearRowSave(state, action: PayloadAction<{ gridRowId: string }>) {
      state.savingRows[action.payload.gridRowId] = false;
    },
    saveAutomatedAction(
      state,
      action: PayloadAction<{
        gridRowId: string;
        description: string;
        isDone: boolean;
      }>,
    ) {
      state.savingRows[action.payload.gridRowId] = true;

      if (action.payload.isDone)
        state.editingRows[action.payload.gridRowId] = false;
    },
    errorEditingRow(
      state,
      action: PayloadAction<{ gridRowId: string; errorMessage: string }>,
    ) {
      state.savingRows[action.payload.gridRowId] = false;
      state.editingRows[action.payload.gridRowId] = true;
      state.errorRows[action.payload.gridRowId] = action.payload.errorMessage;
    },
  },
});

export const {
  cancelEditingRow,
  clearRowError,
  clearRowSave,
  editRow,
  errorEditingRow,
  exportToCsv,
  exportToCsvResult,
  loadedTemplateConfigurations,
  loadTemplateConfigurations,
  reset,
  saveAutomatedAction,
  selectTemplate,
  toggleShowAll,
} = slice.actions;
export const reducer = slice.reducer;
export type State = AutomatedActionsConfigurationState;
