/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ErrorRetry } from '@seeeverything/ui.primitives/src/components/ErrorRetry/index.ts';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback } from 'react';
import { DOCUMENT_UPLOAD } from '../../common/constants.ts';
import { IFormFile } from '../../redux/form-file/types.ts';
import { FormFilesPanel } from '../FormFilesPanel/FormFilesPanel.tsx';
import { FilesSkeleton, VideoStreamer } from './components/index.ts';

export interface IDigitalContentProps {
  description?: string;
  error?: string;
  files?: IFormFile[];
  id: string;
  isLoading?: boolean;
  onContentDownload?: (packId: string, fileId?: string) => void;
  onContentView?: (fileId: string) => void;
  onRefreshClick?: () => void;
  onStreamCloseClick?: () => void;
  onStreamError?: () => void;
  onStreamMaskClick?: () => void;
  onStreamRefreshClick?: () => void;
  streamError?: string;
  streamFileId?: string;
  streamLink?: string;
  title?: string;
}

/**
 * Component for digital content in form.
 */
export const DigitalContent: React.FC<IDigitalContentProps> = ({
  description,
  error,
  files,
  id,
  isLoading,
  onContentDownload,
  onContentView,
  onRefreshClick,
  onStreamCloseClick,
  onStreamError,
  onStreamMaskClick,
  onStreamRefreshClick,
  streamError,
  streamFileId,
  streamLink,
  title,
}) => {
  const handleFileDownload = useCallback(
    (fileId: string) => onContentDownload?.(id, fileId),
    [id, onContentDownload],
  );

  const elStream = streamLink && (
    <VideoStreamer
      error={streamError}
      onError={onStreamError}
      onMaskClick={onStreamMaskClick}
      onRefreshClick={onStreamRefreshClick}
      onStreamCloseClick={onStreamCloseClick}
      title={
        files?.find((file) => file.id === streamFileId)?.name ?? 'Play Video'
      }
      url={streamLink}
    />
  );

  const elLoading = isLoading && (
    <>
      <Skeleton
        variant={'rectangular'}
        width={'50%'}
        height={21}
        style={css({ margin: '18.72px 0' })}
      />
      <Skeleton variant={'rectangular'} height={18} />
      <FilesSkeleton />
    </>
  );

  const elContent = !isLoading && (
    <>
      <Markdown text={title ? `### ${title}` : 'Digital Content'} />
      <Markdown text={description} />
      <FormFilesPanel
        canUpload={false}
        canDelete={false}
        emptySize={'large'}
        maxSize={DOCUMENT_UPLOAD.maxSize}
        allowedTypesReadable={DOCUMENT_UPLOAD.allowedTypesReadable}
        allowedMimeTypes={DOCUMENT_UPLOAD.allowedMimeTypes}
        files={files}
        style={styles.files}
        onDownload={handleFileDownload}
        onView={onContentView}
      />
    </>
  );

  return error ? (
    <div css={styles.base}>
      <ErrorRetry onClick={onRefreshClick} message={error} />
    </div>
  ) : (
    <div css={styles.base}>
      {elStream}
      {elLoading}
      {elContent}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 10,
  }),
  files: css({
    display: 'flex',
    marginTop: 25,
    background: '#fcfcfc',
    borderRadius: 4,
    border: `1px solid ${color.format(-0.1)}`,
    flexWrap: 'wrap',
  }),
};
