import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { DashboardTimespanVariable } from '../../dashboards/query/types.ts';
import { QueryResult, SimpleEntity } from '../../types.ts';
import {
  CoachingSummaryInsightSectionTypeFilter,
  CoachingSummarySectionInsight,
  FormActionStatus,
  FormInstanceStatus,
  InsightType,
} from '../types.ts';

type GetDashboardCoachingSummaryInsightsArgs = {
  entityId: string;
  entityType: 'Person' | 'Team';
  sectionType: CoachingSummaryInsightSectionTypeFilter;
  timespan: DashboardTimespanVariable;
  fetchAllPages?: boolean;
  pageNumber?: number;
};

export type DashboardCoachingSummaryInsightsDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
    totalCount: number;
  };
  insights: CoachingSummarySectionInsight[];
};

export const getDashboardCoachingSummaryInsights = async (
  client: IGraphQLClient,
  args: GetDashboardCoachingSummaryInsightsArgs,
): Promise<QueryResult<DashboardCoachingSummaryInsightsDto>> => {
  if (!args.fetchAllPages) {
    return await getDashboardCoachingSummaryInsightsPage(client, args);
  }

  try {
    const allInsights = await getDashboardCoachingSummaryInsightsRecursive(
      client,
      args,
    );
    return {
      isSuccess: true,
      data: {
        insights: allInsights,
        pageInfo: {
          hasNextPage: false,
          currentPage: 1,
          totalCount: allInsights.length,
        },
      },
    };
  } catch (error) {
    log.error(error);
    return { isSuccess: false, error, errorReason: 'UNKNOWN' };
  }
};

const getDashboardCoachingSummaryInsightsRecursive = async (
  client: IGraphQLClient,
  args: GetDashboardCoachingSummaryInsightsArgs,
  insights: CoachingSummarySectionInsight[] = [],
  pageNumber = 1,
): Promise<CoachingSummarySectionInsight[]> => {
  const response = await getDashboardCoachingSummaryInsightsPage(client, {
    entityId: args.entityId,
    entityType: args.entityType,
    sectionType: args.sectionType,
    timespan: args.timespan,
    pageNumber,
  });

  if (!response.isSuccess) {
    throw new Error(
      `Failed to retrieve coaching summary insights for page ${pageNumber}`,
    );
  }

  const nextInsights = insights.concat(response.data.insights);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return nextInsights;

  return getDashboardCoachingSummaryInsightsRecursive(
    client,
    args,
    nextInsights,
    pageInfo.currentPage + 1,
  );
};

const getDashboardCoachingSummaryInsightsPage = async (
  client: IGraphQLClient,
  args: GetDashboardCoachingSummaryInsightsArgs,
): Promise<QueryResult<DashboardCoachingSummaryInsightsDto>> => {
  try {
    const response = await client.query<{
      forms: {
        dashboardCoachingSummaryInsights: {
          pageInfo: {
            hasNextPage: boolean;
            currentPage: number;
            totalCount: number;
          };
          nodes: Array<{
            id: string;
            instance: {
              id: string;
              dueBy: string;
              name: string;
              status: FormInstanceStatus;
            };
            label: string;
            type: InsightType;
            notes: { label: string; value: string };
            classifications: { label: string; values: string[] };
            answer?: {
              id: string;
              key: string;
              displayValue?: string;
              value: string;
            };
            actions: Array<{
              id: string;
              assignedTo: SimpleEntity;
              description: string;
              dueBy?: string;
              status: FormActionStatus;
            }>;
            formSectionName: string;
            formQuestionText: string;
          }>;
        };
      };
    }>({
      query: gql`
        query DashboardCoachingSummaryInsights(
          $entityId: ID!
          $entityType: DashboardPersonOrTeam!
          $sectionType: FormCoachingSummarySectionType!
          $timespan: DashboardTimespanFilterInput
          $pageNumber: Int!
        ) {
          forms {
            dashboardCoachingSummaryInsights(
              entityId: $entityId
              entityType: $entityType
              sectionType: $sectionType
              timespan: $timespan
              pagination: { pageNumber: $pageNumber, size: 100 }
            ) {
              pageInfo {
                hasNextPage
                totalCount
                pageSize
                currentPage
              }
              nodes {
                id
                instance {
                  id
                  dueBy
                  name
                  status
                }
                label
                type
                notes {
                  label
                  value
                }
                classifications {
                  label
                  values
                }
                answer {
                  id
                  key
                  displayValue
                  value
                }
                actions {
                  id
                  assignedTo {
                    id
                    name
                  }
                  description
                  dueBy
                  status
                }
                formSectionName
                formQuestionText
              }
            }
          }
        }
      `,
      variables: {
        entityId: args.entityId,
        entityType: args.entityType,
        sectionType: args.sectionType,
        timespan: args.timespan,
        pageNumber: args.pageNumber ?? 1,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } =
      response.data.forms.dashboardCoachingSummaryInsights;

    if (!nodes)
      return {
        isSuccess: false,
        errorReason: 'NOT_FOUND',
      };

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
          totalCount: pageInfo.totalCount,
        },
        insights: nodes.map((sectionInsight) => ({
          id: sectionInsight.id,
          instance: sectionInsight.instance
            ? {
                id: sectionInsight.instance.id,
                dueBy: sectionInsight.instance.dueBy,
                name: sectionInsight.instance.name,
                status: sectionInsight.instance.status,
              }
            : undefined,
          label: sectionInsight.label,
          type: sectionInsight.type,
          notes: sectionInsight.notes
            ? {
                label: sectionInsight.notes.label,
                value: sectionInsight.notes.value,
              }
            : undefined,
          classifications: sectionInsight.classifications
            ? {
                label: sectionInsight.classifications.label,
                values: sectionInsight.classifications.values,
              }
            : undefined,
          answer: sectionInsight.answer
            ? {
                id: sectionInsight.answer.id,
                key: sectionInsight.answer.key,
                displayValue: sectionInsight.answer.displayValue,
                value: sectionInsight.answer.value,
              }
            : undefined,
          actions: (sectionInsight.actions ?? []).map((action) => ({
            id: action.id,
            assignedTo: action.assignedTo
              ? { id: action.assignedTo.id, name: action.assignedTo.name }
              : undefined,
            description: action.description,
            dueBy: action.dueBy,
            status: action.status,
          })),
          formSectionName: sectionInsight.formSectionName,
          formQuestionText: sectionInsight.formQuestionText,
        })),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query coaching summary ${args.sectionType} for entity ${args.entityId} ${args.entityType} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
