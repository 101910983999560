/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';
import * as R from 'ramda';
import React from 'react';

/**
 * Comment placeholder for loading.
 */
export const CommentSkeleton: React.FC = () => {
  const generateSkeletonSentences = (numberOfSentences: number) =>
    R.range(1, numberOfSentences + 1).map((num) => (
      <Skeleton
        key={`skeleton-sentence-${num}`}
        width={num === numberOfSentences ? '40%' : '100%'}
        height={19}
      />
    ));
  return (
    <div css={styles.base}>
      <Skeleton variant={'rectangular'} height={18} width={'60%'} />
      <div css={styles.body}>{generateSkeletonSentences(3)}</div>
      <Skeleton variant={'rectangular'} height={16} width={100} />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    margin: '12px 0',
  }),
  body: css({
    margin: '6px 0 12px 0',
  }),
};
