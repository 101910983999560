import { SliderMark } from '@seeeverything/ui.primitives/src/components/Slider/Slider.tsx';
import { is } from 'ramda';
import {
  INumberSliderAnswerMark,
  INumberSliderLine,
  ISectionLine,
} from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import {
  NumberSliderDefinition,
  NumberSliderItemDefinition,
  NumberSliderItemsDefinition,
} from './types/numberSlider.types.ts';

export function parseNumberSliderAnswer(
  item: NumberSliderDefinition,
  parentSection: ISectionLine,
  indices: number[],
): INumberSliderLine | undefined {
  if (!item) return;

  return {
    group: getProp(item, 'group')?.toString(),
    highlightOnHover: true,
    id: getProp(item, 'id') || defaultId(indices),
    isEnabled: getProp(item, 'isEnabled', true),
    isRequired: getProp(item, 'isRequired', false),
    parentId: parentSection.id,
    marks: sliderMarks(getProp(item, 'items')),
    question: getProp(item, 'question'),
    showIndex: false,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    type: 'numberSliderAnswer',
    value: getProp(item, 'value'),
  };
}

const sliderMarks = (
  items?: NumberSliderItemsDefinition,
): SliderMark[] | boolean | undefined => {
  if (!items || items === true || is(Number, items)) return Boolean(items);

  const firstDefaultValue = items.some((item) => item.value === 0) ? -1 : 0;

  const hasNumericValues = items.some(({ value }) => !isNaN(value as number));
  const hasValue = (value: number) =>
    items.some((item) => item.value === value);

  const toNextPosition = (itemIndex: number): number => {
    if (!hasNumericValues) {
      return itemIndex;
    }

    if (itemIndex === 0) {
      return firstDefaultValue;
    }

    return hasValue(itemIndex) ? toNextPosition(itemIndex + 1) : itemIndex;
  };

  return items.map(definitionToSliderMarks(toNextPosition));
};

const definitionToSliderMarks =
  (toNextSliderPosition: (index: number) => number) =>
  (
    item: NumberSliderItemDefinition,
    index: number,
  ): INumberSliderAnswerMark => {
    const value = getProp(item, 'value');

    const numericValue =
      is(String, value) || value === undefined
        ? toNextSliderPosition(index)
        : (value as number);

    const label = getProp(item, 'label', numericValue.toString());
    const description = getProp(item, 'description');

    return {
      label,
      description,
      answerDisplayValue: toAnswerDisplayValue({
        description,
        label,
        numericValue,
      }),
      value: numericValue,
    };
  };

type DisplayValueInputType = {
  description?: string;
  label?: string;
  numericValue: number;
};

const toAnswerDisplayValue = ({
  description,
  label,
  numericValue,
}: DisplayValueInputType) => {
  if (description) {
    return `${numericValue} (${description})`;
  }

  if (label && label !== numericValue.toString()) {
    return `${numericValue} (${label})`;
  }

  return (label ?? numericValue)?.toString();
};
