/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanGoal } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconContent } from '@seeeverything/ui.primitives/src/components/IconContent/IconContent.tsx';
import { Paper } from '@seeeverything/ui.primitives/src/components/Paper/Paper.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import momentTz from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { formEditGoalSlice } from '../../../redux/form-edit-goal/index.ts';
import { FormActionSource } from '../../../redux/formAction/formActionSlice.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { formatDueBy } from '../../../util/util.instance.ts';
import { Action } from './Action.tsx';
import { AddAction } from './AddAction.tsx';
import { ItemTrack } from './ItemTrack.tsx';

const getTheme = (status: 'Open' | 'Completed' | 'Cancelled' | 'Overdue') => {
  if (status === 'Cancelled')
    return {
      border: color.format(-0.15),
      description: color.format(-0.4),
      meta: {
        assignedToIcon: color.format(-0.3),
        assignedTo: color.format(-0.4),
      },
      icon: Icons.cancel,
      tooltip: 'Canceled',
    };

  if (status === 'Completed')
    return {
      border: color.create(COLORS.GREEN).alpha(0.4).css(),
      description: color.format(-0.4),
      meta: {
        assignedToIcon: color.format(-0.3),
        assignedTo: color.format(-0.4),
      },
      icon: Icons.verifiedUser,
      tooltip: 'Completed',
    };

  if (status === 'Open')
    return {
      border: color.create(COLORS.BLUE).alpha(1).css(),
      description: color.format(-0.8),
      meta: {
        assignedToIcon: color.format(-0.45),
        assignedTo: color.format(-0.6),
        dueIcon: color.format(-0.45),
        due: color.format(-0.6),
      },
      icon: Icons.info,
      tooltip: 'Open',
    };

  if (status === 'Overdue')
    return {
      border: COLORS.ERROR_RED,
      description: color.format(-0.8),
      meta: {
        assignedToIcon: color.format(-0.45),
        assignedTo: color.format(-0.6),
        dueIcon: COLORS.ERROR_RED,
        due: COLORS.ERROR_RED,
      },
      icon: Icons.accessTime,
      tooltip: 'Overdue',
    };
};

export type GoalProps = {
  goal: FormActionPlanGoal;
  instanceId: string;
  source: FormActionSource;
};

export const Goal: React.FC<GoalProps> = ({ goal, instanceId, source }) => {
  const dispatch = useFormsDispatch();
  const { isPdfExport } = usePdfExportContext();

  const isLoading = useFormsSelector(
    (state) => state.formActionPlan.isLoadingGoal[goal.id],
  );

  const isEditable = useMemo(
    () => goal.status !== 'Cancelled' && goal.status !== 'Completed',
    [goal.status],
  );

  const dateContext = useDateContext();

  const dueIn = useMemo(
    () =>
      formatDueBy({ dueBy: goal.dueBy, timezone: dateContext.tenantTimezone }),
    [dateContext.tenantTimezone, goal.dueBy],
  );

  const theme = useMemo(() => getTheme(goal.status), [goal.status]);

  const canCreateActions = useMemo(() => {
    if (isPdfExport) return false;

    const goalStatus = goal.status;
    if (goalStatus === 'Cancelled') return false;
    if (goalStatus === 'Completed') return false;

    return true;
  }, [goal.status, isPdfExport]);

  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const handleLoadGoal = useCallback(
    () => dispatch(formEditGoalSlice.loadGoal({ goalId: goal.id, source })),
    [dispatch, goal.id, source],
  );

  const computedStyles = {
    statusIcon: {
      padding: '0 6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      background: theme.border,
    },
  };

  const elDescription = (
    <div
      data-test={`forms-actionPlanItem-${goal.description}`}
      css={styles.description}
    >
      <Text
        color={theme.description}
        size={18}
        cursor={'inherit'}
        ellipsis={true}
        block={true}
        italic={!isEditable}
      >
        {goal.description}
      </Text>
    </div>
  );

  const elAssignedTo = goal.assignedTo && (
    <IconContent
      icon={'face'}
      iconProps={{
        fill: theme.meta.assignedToIcon,
        size: 12,
      }}
      content={goal.assignedTo.name}
      contentStyle={styles.paddingLeft}
      textProps={{
        color: theme.meta.assignedTo,
        size: 12,
        weight: FontWeight.light,
        cursor: 'inherit',
        italic: !isEditable,
        ellipsis: true,
      }}
      style={styles.actionDataContainer}
    />
  );

  const elDueDate = goal.dueBy && isEditable && (
    <>
      <IconContent
        icon={'notifications'}
        iconProps={{
          fill: theme.meta.dueIcon,
          size: 12,
        }}
        content={momentTz(goal.dueBy)
          .tz(dateContext.tenantTimezone)
          .format(`D MMM YY`)}
        contentStyle={styles.paddingLeft}
        textProps={{
          color: theme.meta.due,
          size: 12,
          weight: FontWeight.light,
          cursor: 'inherit',
          ellipsis: true,
        }}
        style={styles.actionDataContainer}
      />
      {dueIn && (
        <Text
          size={12}
          color={theme.meta.due}
          weight={FontWeight.light}
          cursor={'inherit'}
          ellipsis={true}
        >
          {dueIn}
        </Text>
      )}
    </>
  );

  const Icon = theme.icon;

  return (
    <>
      {isLoading && (
        <div css={styles.skeleton}>
          <SkeletonDetailedItemsList numberOfSkeletonItems={1} />
        </div>
      )}
      {!isLoading && (
        <div css={styles.base}>
          <div css={styles.outer}>
            <div css={styles.leftContainer}>
              <Icons.myLocation fill={color.format(-0.3)} tooltip={'Goal'} />
            </div>
            <Paper
              style={styles.contentPaper}
              variant={!isEditable ? 'outlined' : 'elevation'}
            >
              <Button onClick={handleLoadGoal} style={styles.button}>
                <div css={styles.itemOuter}>
                  <Icon
                    fill={'white'}
                    tooltip={theme.tooltip}
                    style={computedStyles.statusIcon}
                  />
                  <div css={styles.itemContainer}>
                    {elDescription}
                    <div css={styles.metaContainer}>
                      {elAssignedTo}
                      {elDueDate}
                    </div>
                  </div>
                </div>
              </Button>
            </Paper>
          </div>
          {goal.actions.map((action, index) => (
            <div css={styles.action} key={`Action-${action.id}`}>
              <ItemTrack
                continueToNextTrack={Boolean(
                  goal.actions[index + 1] || canCreateActions,
                )}
              />
              <Action action={action} source={source} />
            </div>
          ))}
          {canCreateActions && (
            <div css={styles.addActionButton}>
              <ItemTrack continueToNextTrack={false} height={18} />
              <AddAction
                goalId={goal.id}
                instanceId={instanceId}
                message={
                  goal.actions.length
                    ? `Add another ${actionLabel} to this goal.`
                    : `Add a new ${actionLabel} to this goal.`
                }
                existingActions={goal.actions}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '-10px -12px -3px -12px',
    padding: '10px 12px 3px 12px',
    borderRadius: 3,
    ':hover': {
      backgroundColor: color.format(-0.03),
    },
  }),
  action: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  }),
  addActionButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 13,
  }),
  itemContainer: css({
    padding: '5px 22px',
    flex: '1 1 auto',
    width: 0, // Allows text to ellipsis.
    boxSizing: 'border-box',
  }),
  outer: css({
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    alignItems: 'center',
    gap: 8,
  }),
  leftContainer: css({
    flex: '0 0 auto',
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
  }),
  itemOuter: css({
    display: 'flex',
    alignItems: 'stretch',
  }),
  paddingLeft: css({
    paddingLeft: 5,
  }),
  metaContainer: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    ...CommonStyles.MaterialCubicTransitions,
  }),
  skeleton: css({
    position: 'relative',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    margin: '0px 8px',
  }),
  button: css({
    display: 'flex',
    flex: '1 1 0px',
    flexDirection: 'column',
    background: '#fcfcfc',
    overflow: 'hidden',
    alignItems: 'stretch',
    width: '100%',
    position: 'relative',
    '&:hover > div div': {
      color: COLORS.BLUE,
    },
    '&:hover > div div div svg': {
      fill: `${COLORS.BLUE} !important`,
    },
    cursor: 'pointer',
  }),
  description: css({
    width: '100%',
    paddingTop: 8,
    paddingBottom: 7,
    marginBottom: 3,
    borderBottom: `solid 1px ${color.format(-0.1)}`,
    cursor: 'pointer',
  }),
  actionDataContainer: css({
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0',
    marginRight: 12,
    cursor: 'pointer',
  }),
  contentPaper: css({
    flex: '1 1 auto',
    borderRadius: 4,
    width: '100%',
    margin: '6px 0',
    maxWidth: '100%',
    zIndex: 0,
  }),
};
