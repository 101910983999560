/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { CommonStyles } from '../../common/commonStyles.ts';
import { Markdown } from '../Markdown/Markdown.tsx';

export type MarkdownTextFieldProps = {
  error?: string;
  inputStyle?: SerializedStyles;
  isEnabled?: boolean;
  isError?: boolean;
  label?: string;
  maxHeight?: string | number;
  value?: string;
  stripMarkdown?: boolean;
};

export const MarkdownTextField: React.FC<MarkdownTextFieldProps> = ({
  error,
  inputStyle,
  isEnabled,
  isError,
  label,
  maxHeight,
  value,
  stripMarkdown = true,
}) => {
  const showError = Boolean(isError || error);

  const computedStyles = {
    base: css({
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      paddingBottom: 4,
      minHeight: label ? 43 : 27,
      justifyContent: 'flex-end',
    }),
    baseEnabled: css({
      borderBottom: showError
        ? `solid 1px ${COLORS.ERROR_RED}`
        : 'solid 1px #949494',
      ':hover': {
        marginBottom: 0,
        borderBottom: showError
          ? `solid 1px ${COLORS.ERROR_RED}`
          : 'solid 1px #1f1f1f',
      },
    }),
    baseDisabled: css({
      borderBottom: 'dotted 1px #949494',
    }),
    content: css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: value ? 'space-between' : 'flex-end',
      gap: 8,
      maxHeight,
      width: '100%',
      overflowY: maxHeight !== undefined ? 'auto' : 'unset',
    }),
  };

  return (
    <>
      <div
        css={
          isEnabled
            ? [computedStyles.base, computedStyles.baseEnabled]
            : [computedStyles.base, computedStyles.baseDisabled]
        }
      >
        {label && (
          <div css={css({ position: 'relative' })}>
            <Text
              size={value ? 12 : undefined}
              color={
                showError
                  ? COLORS.ERROR_RED
                  : isEnabled
                    ? color.format(-0.45)
                    : '#888888'
              }
              weight={400}
              ellipsis={true}
              style={value ? undefined : styles.label}
            >
              {label}
            </Text>
          </div>
        )}
        {value && (
          <div css={computedStyles.content}>
            {isEnabled && stripMarkdown ? (
              <Text
                color={color.format(-0.87)}
                style={[styles.value, inputStyle]}
              >
                {value}
              </Text>
            ) : (
              <Markdown
                text={value}
                color={isEnabled ? color.format(-0.87) : '#8c8c8c'}
                style={styles.value}
              />
            )}
          </div>
        )}
      </div>
      {error && (
        <Text
          size={12}
          color={COLORS.ERROR_RED}
          style={CommonStyles.AnimationShake}
        >
          {error}
        </Text>
      )}
    </>
  );
};

const styles = {
  label: css({
    position: 'absolute',
    top: -23,
    lineHeight: '1.4375em',
    width: '100%',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
  }),
  value: css({
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    lineHeight: '1.4375em',
    width: '100%',
    overflow: 'hidden',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
  }),
};
