/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { ReactNode, useCallback } from 'react';
import { Checkbox } from '../Checkbox/Checkbox.tsx';
import { ItemLabel } from './components/ItemLabel.tsx';
import { ItemLabelValues } from './types.ts';

export type CheckboxOption = {
  id: string;
  label: string | ItemLabelValues;
  tooltip?: string;
  dataTestId?: string;
  isDimmed?: boolean;
  isEnabled?: boolean;
};

export type CheckboxValue = {
  id: string;
  checked: boolean;
  value?: string;
};

export type CheckboxGroupEvent = {
  id: string;
  index: number;
  to: boolean;
};

export type CheckboxGroupProps = {
  value?: CheckboxValue[];
  onChange?: (e: CheckboxGroupEvent) => void;
  isEnabled?: boolean;
  options?: CheckboxOption[];
  title?: string;
  direction?: 'horizontal' | 'vertical';
  error?: string;
  helperText?: string;
  style?: SerializedStyles;
  renderLabelOverride?: (
    label: string | ItemLabelValues,
    tooltip?: string,
    isDimmed?: boolean,
  ) => ReactNode;
};

/**
 * Supports a group of check boxes.
 */
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  direction = 'vertical',
  error,
  helperText,
  isEnabled = true,
  onChange,
  options = [],
  renderLabelOverride,
  style,
  title,
  value = [],
}) => {
  const isHorizontal = direction === 'horizontal';

  const styles = {
    base: css({
      '&.MuiFormControl-root': style,
    }),
    checkboxGroup: css({
      '&.MuiFormGroup-root': {
        color: color.format(-0.6),
        display: 'flex',
        flexWrap: 'nowrap',
      },
    }),
    checkbox: css({
      alignSelf: isHorizontal ? 'flex-end' : 'flex-start',
    }),
  };

  const handleOnChange = useCallback(
    (id: string, index: number) => (to: boolean) =>
      onChange?.({ id, index, to }),
    [onChange],
  );

  const renderLabel = useCallback(
    (label: string | ItemLabelValues, tooltip?: string, isDimmed?: boolean) =>
      renderLabelOverride ? (
        renderLabelOverride(label, tooltip, isDimmed)
      ) : (
        <ItemLabel
          value={label}
          tooltip={tooltip}
          center={isHorizontal}
          isEnabled={isEnabled}
        />
      ),
    [renderLabelOverride, isHorizontal, isEnabled],
  );

  return (
    <FormControl
      error={Boolean(error)}
      fullWidth={true}
      disabled={!isEnabled}
      sx={styles.base}
    >
      {title && <FormLabel>{title}</FormLabel>}
      <FormGroup row={isHorizontal} sx={styles.checkboxGroup}>
        {options.map((option, index) => (
          <Checkbox
            key={option.id}
            data-test={option.dataTestId}
            isChecked={Boolean(value.find((v) => v.id === option.id)?.checked)}
            onCheck={handleOnChange(option.id, index)}
            isEnabled={isEnabled && option.isEnabled !== false}
            label={renderLabel(option.label, option.tooltip, option.isDimmed)}
            labelPlacement={isHorizontal ? 'top' : 'end'}
            style={styles.checkbox}
          />
        ))}
      </FormGroup>
      {Boolean(error || helperText) && (
        <FormHelperText>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
