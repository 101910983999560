/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import MaterialSkeleton from '@mui/material/Skeleton';

export type SkeletonProps = {
  animation?: 'pulse' | 'wave' | false;
  height?: number | string;
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
  width?: number | string;
  style?: SerializedStyles;
};

/**
 * Placeholder for data that is not yet loaded.
 */
export const Skeleton: React.FC<SkeletonProps> = ({
  animation = 'wave',
  height,
  variant,
  width,
  style,
}) => (
  <MaterialSkeleton
    animation={animation}
    height={height}
    variant={variant}
    width={width}
    sx={style}
  />
);
