import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';

export const getAutomatedActionsExport = async (
  client: IGraphQLClient,
): Promise<QueryResult<{ downloadUrl: string }>> => {
  try {
    const response = await client.query<{
      forms: {
        automatedActionsExport: {
          downloadUrl: string;
        };
      };
    }>({
      query: gql`
        query AutomatedActionsExport {
          forms {
            automatedActionsExport {
              downloadUrl
            }
          }
        }
      `,
      variables: {},
      fetchPolicy: 'network-only',
    });

    const downloadUrl = response.data.forms.automatedActionsExport.downloadUrl;

    if (!downloadUrl) {
      log.error(`Failed to retrieve automated actions export`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: { downloadUrl },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query automated actions export`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
