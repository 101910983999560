/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RadioGroup } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/RadioGroup.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { useEmitDebounce } from '@seeeverything/ui.primitives/src/hooks/useEmitDebounce.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback } from 'react';
import {
  FormResult,
  RemediationOutcome,
} from '../../redux/form-score/types.ts';
import { SpeechBlockContainer } from '../SpeechBlock/SpeechBlockContainer.tsx';
import { ResultDropdown } from './components/ResultDropdown.tsx';
import { IScoreErrorMap } from './types.ts';

const REMEDIATION_OPTIONS = [
  { id: RemediationOutcome.Pending, label: 'Pending' },
  { id: RemediationOutcome.Yes, label: 'Yes' },
  { id: RemediationOutcome.No, label: 'No' },
];

export type ScoreProps = {
  calculatedResult?: FormResult;
  canMarkProvisional: boolean;
  canOverruleNotApplicable: boolean;
  canOverruleResult: boolean;
  canRemediateProvisional?: boolean;
  errors?: IScoreErrorMap;
  failLabel: string;
  hasScoreLabels?: boolean;
  instanceId: string;
  isProvisionalEditable?: boolean;
  isResultEditable?: boolean;
  onRemediationChange: (id: RemediationOutcome) => void;
  onRemediationReasonChange: (reason: string) => void;
  onResultChange: (result: FormResult) => void;
  overruledResult?: FormResult;
  passLabel: string;
  percentageScore?: number;
  remediation?: RemediationOutcome;
  remediationReason?: string;
  speechText?: string;
};

/**
 * Container for displaying provisional scoring in a form.
 */
export const Score: React.FC<ScoreProps> = ({
  calculatedResult = FormResult.Pass,
  canMarkProvisional,
  canOverruleNotApplicable,
  canOverruleResult,
  canRemediateProvisional = true,
  errors,
  failLabel,
  hasScoreLabels = true,
  instanceId,
  isProvisionalEditable = true,
  isResultEditable,
  onRemediationChange,
  onRemediationReasonChange,
  onResultChange,
  overruledResult,
  passLabel,
  percentageScore,
  remediation = RemediationOutcome.Pending,
  remediationReason: propRemediationReason = '',
  speechText,
}) => {
  const [remediationReason, setRemediationReason] = useEmitDebounce({
    value: propRemediationReason,
    delay: 5000,
    onDebounce: onRemediationReasonChange,
  });

  const handleRemediationReasonChange = useCallback(
    (to: string) => setRemediationReason(to),
    [setRemediationReason],
  );

  const isProvisional = overruledResult === FormResult.Provisional;

  const result =
    remediation === RemediationOutcome.Pending
      ? (overruledResult ?? calculatedResult)
      : remediation === RemediationOutcome.No
        ? FormResult.Fail
        : FormResult.Pass;

  const handleRemediationReasonBlur = useCallback(() => {
    if (remediationReason === propRemediationReason) return;

    onRemediationReasonChange(remediationReason);
  }, [onRemediationReasonChange, propRemediationReason, remediationReason]);

  return (
    <div css={styles.base}>
      <div css={styles.row}>
        <Text color={color.format(-0.8)} style={styles.resultLabel}>
          {'Result:'}
        </Text>
        <ResultDropdown
          result={result}
          calculatedFormResult={calculatedResult}
          canMarkProvisional={canMarkProvisional}
          canOverruleResult={canOverruleResult}
          canOverruleNotApplicable={canOverruleNotApplicable}
          onChange={onResultChange}
          error={errors?.overriddenResult}
          passLabel={passLabel}
          failLabel={failLabel}
          isEnabled={isResultEditable}
          percentageScore={percentageScore}
          showLabels={hasScoreLabels}
        />
      </div>
      <Transition.Collapse in={isProvisional && canRemediateProvisional}>
        <SpeechBlockContainer
          id={'provisional-speech'}
          markdown={speechText}
          instanceId={instanceId}
        />
      </Transition.Collapse>
      <Transition.Collapse in={isProvisional && canRemediateProvisional}>
        <div css={styles.row}>
          <Text color={color.format(-0.8)} align={'center'}>
            {'Remediation completed to expectation?'}
          </Text>
          <RadioGroup
            direction={'horizontal'}
            value={remediation}
            isFullWidth={false}
            onChange={onRemediationChange}
            options={REMEDIATION_OPTIONS}
            isEnabled={isProvisionalEditable}
            error={errors?.remediation}
          />
        </div>
      </Transition.Collapse>
      <Transition.Collapse
        in={
          remediation !== RemediationOutcome.Pending &&
          isProvisional &&
          canRemediateProvisional
        }
        mountOnEnter={true}
      >
        <TextField
          id={'remediation-reason'}
          label={'Remediation Commentary'}
          InputProps={{ onBlur: handleRemediationReasonBlur }}
          onChange={handleRemediationReasonChange}
          value={remediationReason}
          isEnabled={isProvisionalEditable}
          error={errors?.remediationReason}
        />
      </Transition.Collapse>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  row: css({
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 0',
    flexWrap: 'wrap',
  }),
  resultLabel: css({
    flex: '1 1 auto',
    minWidth: '50%',
  }),
};
