/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import {
  Slider,
  SliderMark,
} from '@seeeverything/ui.primitives/src/components/Slider/Slider.tsx';
import { useEmitDebounce } from '@seeeverything/ui.primitives/src/hooks/useEmitDebounce.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useCallback, useMemo } from 'react';

type SliderAnswerMark = {
  id: number;
  label?: string;
  tooltip?: string;
};

export type SliderAnswerProps = {
  error?: string;
  isEnabled?: boolean;
  marks?: SliderAnswerMark[];
  onChange: (to: number, displayValue: string) => void;
  question?: string;
  value?: number;
};

/**
 * Slider question component on form instances.
 */
export const SliderAnswer: React.FC<SliderAnswerProps> = ({
  error,
  isEnabled,
  marks,
  onChange,
  question,
  value,
}) => {
  const getMarkId = useCallback(
    (index: number) => marks?.[index]?.id ?? index,
    [marks],
  );

  const handleBubbleChange = useCallback(
    (toIndex: number) => {
      const customMark = toIndex !== -1 ? marks?.[toIndex] : undefined;
      const displayValue = customMark?.label?.toString();

      onChange(getMarkId(toIndex), displayValue);
    },
    [getMarkId, marks, onChange],
  );

  const [selectedIndex, setSelectedIndex] = useEmitDebounce({
    value,
    onDebounce: handleBubbleChange,
  });

  const displayedMarks = useMemo(
    () =>
      marks?.reduce(
        (acc, mark, index): SliderMark[] => [
          ...acc,
          {
            value: index,
            description: mark.tooltip,
            label: mark.label,
          },
        ],
        [{ value: -1 }],
      ),
    [marks],
  );

  return (
    <div>
      {question && (
        <Markdown
          text={question}
          style={error ? styles.markdownError : undefined}
        />
      )}
      <Slider
        error={error}
        errorStyle={styles.error}
        isEnabled={isEnabled}
        marks={displayedMarks}
        onSelectedIndexChanged={setSelectedIndex}
        style={styles.slider}
        value={selectedIndex}
      />
    </div>
  );
};

const styles = {
  slider: css({
    padding: '0 15px 0 5px',
    boxSizing: 'border-box',
  }),
  error: {
    marginLeft: -30,
  },
  markdownError: css({
    color: COLORS.ERROR_RED,
  }),
};
