import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { Team } from '../types.ts';
import { toBusinessUnit } from '../utils/businessUnits.ts';

type ServerTeam = {
  id: string;
  name: string;
  businessUnit: string;
  path: string;
  hierarchyLevel: number;
  hasMembers: boolean;
  isActive: boolean;
};

export const getTeam = async (
  client: IGraphQLClient,
  teamId: string,
  tenantConfig: TenantConfiguration,
): Promise<QueryResult<Team>> => {
  try {
    const response = await client.query<{ orgHierarchy: { team: ServerTeam } }>(
      {
        query: gql`
          query TeamById($teamId: ID!) {
            orgHierarchy {
              team(id: $teamId) {
                id
                name
                path
                businessUnit
                hierarchyLevel
                hasMembers
                isActive
              }
            }
          }
        `,
        variables: {
          teamId,
        },
        fetchPolicy: 'cache-first',
      },
    );

    const team = response.data.orgHierarchy.team;
    if (!team) {
      log.error(`Failed to retrieve team with id ${teamId}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        id: team.id,
        name: team.name,
        businessUnit: toBusinessUnit(team.businessUnit, tenantConfig),
        path: team.path,
        hierarchyLevel: team.hierarchyLevel,
        hasMembers: team.hasMembers,
        isActive: team.isActive,
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query team with id - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
