import { isAnyOf } from '@reduxjs/toolkit';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { exportToXlsx } from '@seeeverything/ui.util/src/xlsx/index.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, map, mergeMap } from 'rxjs';
import { utils } from 'xlsx';
import { formEditGoalSlice } from '../form-edit-goal/index.ts';
import { formActionSlice } from '../formAction/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as goalsActionsGridSlice from './goalsActionsGridSlice.ts';

export const goalsActionsGridEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(downloadGridEpic, reloadGridOnGoalChangedEpic);

function reloadGridOnGoalChangedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formEditGoalSlice.createdGoal.match,
        formEditGoalSlice.updatedGoal.match,
        formActionSlice.saved.match,
      ),
    ),
    filter((action) => action.payload.isSuccess !== false),
    map(() => goalsActionsGridSlice.reloadGrid()),
  );
}

function downloadGridEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(goalsActionsGridSlice.downloadGrid.match),
    mergeMap(async (action) => {
      const timezone = state$.value.tenantState.tenant.configuration.timezone;
      const response = await formsQuery.getGoalsActionsGrid(
        client,
        action.payload.queryArgs,
        timezone,
      );

      if (!response.isSuccess) return EMPTY;

      const spreadsheetRows = response.data.rowData.map((row) => row.data);
      spreadsheetRows.unshift(action.payload.columnHeaderLabels);

      exportToXlsx({
        exportFileName: `${action.payload.title}.xlsx`,
        sheetName: 'Sheet1',
        title: action.payload.title,
        worksheetData: utils.aoa_to_sheet(spreadsheetRows),
      });

      return EMPTY;
    }),
    concatAll(),
  );
}
