/** @jsxImportSource @emotion/react */
import { DistributionListType } from '@seeeverything/ui.forms/src/redux/distributionList/types.ts';
import { MaterialListItemType } from '@seeeverything/ui.primitives/src/components/MaterialList/index.ts';
import React from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DialogOuter } from '../DialogOuter/index.ts';
import { PreviewList } from './PreviewList.tsx';

export const PreviewListContainer: React.FC<{
  items: MaterialListItemType[];
  type: DistributionListType;
}> = ({ items, type }) => {
  const dispatch = useShellDispatch();
  const locale = useShellSelector((state) => state.tenantState.tenant?.locale);

  return (
    <DialogOuter
      actions={[
        { id: 'OK', label: 'OK', onAction: () => dispatch(hideModalDialog()) },
      ]}
      buttonWidth={110}
    >
      <PreviewList
        items={items}
        formLabel={locale.label.form.toLowerCase()}
        type={type}
      />
    </DialogOuter>
  );
};
