import { GraphQLFormattedError } from 'graphql';

export type GraphQLError = Error & {
  graphQLErrors?: Array<GraphQLFormattedError>;
  extensions?: {
    errorType: string;
  };
};

/**
 * A type-guard for the ValidationError class.
 * @param {Error} target The target error object to check.
 * @returns {target is ValidationError} True if the object is a ValidationError, false otherwise.
 */
export function isValidationError(
  error: GraphQLError | GraphQLFormattedError,
): error is GraphQLFormattedError {
  if (!error) return false;

  const errorExtensions = error.extensions;
  if (!errorExtensions) return false;

  return errorExtensions.errorType === 'VALIDATION';
}

/**
 * Gets the validation error code from a GraphQL formatted validation error.
 */
export function getValidationError(error: GraphQLFormattedError) {
  if (!isValidationError(error)) return;

  return {
    errorCode: error.extensions['errorCode']?.toString(),
    message: error.message,
  };
}
