import { isAnyOf } from '@reduxjs/toolkit';
import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, filter, of } from 'rxjs';
import { automatedActionSlice } from '../automatedAction/index.ts';
import { GlobalFormsState } from '../store.ts';
import * as issueSlice from './issueSlice.ts';

export const getIssueClassificationsObservable = (
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) =>
  action$.pipe(
    filter(
      isAnyOf(
        issueSlice.addClassification.match,
        issueSlice.deleteClassification.match,
        issueSlice.saveClassifications.match,
      ),
    ),
    filter((action) =>
      state$.value.formIssue.issues.some(
        (issue) => issue.answerId === action.payload.answerId,
      ),
    ),
  );

export const getIssueNotesObservable = (
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) =>
  action$.pipe(
    filter(issueSlice.setNotes.match),
    filter((action) =>
      state$.value.formIssue.issues.some(
        (issue) => issue.answerId === action.payload.answerId,
      ),
    ),
  );

export const handleIssueNotesChangeAction = async (
  payload: { answerId: string; instanceId: string; notes: string },
  client: IGraphQLClient,
) => {
  const response = await formsMutation.updateFormAnswerIssueNotes(client, {
    answerId: payload.answerId,
    instanceId: payload.instanceId,
    notes: payload.notes,
  });

  return response.isSuccess
    ? EMPTY
    : of(
        issueSlice.complianceErrors({
          answerId: payload.answerId,
          notes: 'There was a problem saving this response.',
        }),
      );
};

export const handleIssueClassificationsChangeAction = async (
  instanceId: string,
  answerId: string,
  client: IGraphQLClient,
  state$: StateObservable<GlobalFormsState>,
) => {
  const issue = state$.value.formIssue.issues.find(
    (formIssue) => formIssue.answerId === answerId,
  );

  const response = await formsMutation.updateFormAnswerIssueClassifications(
    client,
    {
      answerId,
      instanceId,
      issueId: issue.id,
      classifications: (issue.classifications ?? []).map(({ value }) => value),
      questionKeys: state$.value.formInstance.questionKeys,
      issueInsightAnswerDefinitions:
        state$.value.formInstance.issueInsightAnswerDefinitions,
    },
  );

  if (!response.isSuccess)
    return of(
      issueSlice.complianceErrors({
        answerId,
        classification: 'There was a problem saving this response.',
      }),
    );

  return of(
    automatedActionSlice.setAnswerAutomatedActions({
      answerId,
      automatedActions: response.data.answerAutomatedActions,
    }),
  );
};
