/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useRef } from 'react';
import {
  formInstanceAnswerChange,
  formInstanceAnswerClearQuestionError,
} from '../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { ShowOnAnswer, ShowOnReportingDateMonth } from '../types/types.ts';
import { useShowOnAnswer } from './useShowOnAnswer.ts';
import { useShowOnReportingDateMonth } from './useShowOnReportingDate.ts';
import { useShowOnSwitch } from './useShowOnSwitch.ts';

export type UseHandleAnswerVisibilityOptions = {
  instanceId: string;
  questionKeys: string[];
  showOnAnswer?: ShowOnAnswer;
  showOnReportingDateMonth?: ShowOnReportingDateMonth[];
  showOnSwitch?: string;
};

export const useHandleAnswerVisibility = ({
  instanceId,
  questionKeys,
  showOnAnswer,
  showOnReportingDateMonth,
  showOnSwitch,
}: UseHandleAnswerVisibilityOptions) => {
  const dispatch = useFormsDispatch();

  const isShowOnSwitchVisible = useShowOnSwitch({
    showOnSwitch,
    instanceId,
  });

  const isShowOnAnswerVisible = useShowOnAnswer({
    showOnAnswer,
    instanceId,
  });

  const isShowOnReportingDateVisible = useShowOnReportingDateMonth({
    showOnReportingDateMonth,
    instanceId,
  });

  const isVisible = useMemo(
    () =>
      isShowOnAnswerVisible &&
      isShowOnReportingDateVisible &&
      isShowOnSwitchVisible,
    [
      isShowOnAnswerVisible,
      isShowOnReportingDateVisible,
      isShowOnSwitchVisible,
    ],
  );

  const isQuestionVisibleOnce = useRef(isVisible);

  const answers = useFormsSelector((state) => {
    if (!questionKeys.length) return [];

    const instanceAnswers = state.formInstance.instances[instanceId]?.answers;
    if (!instanceAnswers) return [];

    return questionKeys.map((key) => instanceAnswers[key]).filter(Boolean);
  });

  // Deletes answers when they are no longer visible.
  useEffect(() => {
    if (isVisible) {
      isQuestionVisibleOnce.current = true;
      return;
    }

    if (!isQuestionVisibleOnce.current) return;
    isQuestionVisibleOnce.current = false;

    questionKeys.forEach((questionKey) => {
      dispatch(formInstanceAnswerClearQuestionError(instanceId, questionKey));
    });

    if (!answers.length) return;

    answers.forEach((answer) => {
      dispatch(
        formInstanceAnswerChange(
          instanceId,
          { type: 'DELETE', fromAnswer: answer },
          true,
        ),
      );
    });
  }, [isVisible, dispatch, answers, instanceId, questionKeys]);

  return isVisible;
};
