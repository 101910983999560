/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Slider } from '@seeeverything/ui.primitives/src/components/Slider/Slider.tsx';
import { useEmitDebounce } from '@seeeverything/ui.primitives/src/hooks/useEmitDebounce.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useCallback } from 'react';
import { INumberSliderAnswerMark } from '../../types/types.ts';

export interface INumberSliderAnswerProps {
  error?: string;
  isEnabled?: boolean;
  marks?: boolean | INumberSliderAnswerMark[];
  onChange?: (to: number, toDisplayValue: string) => void;
  question?: string;
  value?: number;
}

/**
 * Displays a set of options along an incremental slider.
 * @deprecated Use `<SliderAnswer>`.
 */
export const NumberSliderAnswer: React.FC<INumberSliderAnswerProps> = ({
  error,
  isEnabled,
  marks,
  onChange,
  question,
  value,
}) => {
  const getAnswerDisplayValue = useCallback(
    (to?: number) => {
      if (to === undefined || !marks) return undefined;
      if (marks === true) return to.toString();

      const mark = marks.find((m) => m.value === to);
      return mark?.answerDisplayValue?.toString();
    },
    [marks],
  );

  const handleBubbleChange = useCallback(
    (to: number) => {
      onChange(to, getAnswerDisplayValue(to));
    },
    [getAnswerDisplayValue, onChange],
  );

  const [statefulValue, setState] = useEmitDebounce({
    value,
    onDebounce: handleBubbleChange,
  });

  return (
    <div>
      {question && (
        <Markdown
          text={question}
          style={error ? styles.markdownError : undefined}
        />
      )}
      <Slider
        error={error}
        errorStyle={styles.error}
        isEnabled={isEnabled}
        marks={marks}
        onSelectedIndexChanged={setState}
        style={styles.slider}
        value={statefulValue}
      />
    </div>
  );
};

const styles = {
  slider: css({
    padding: '0 15px 0 5px',
    boxSizing: 'border-box',
  }),
  error: {
    marginLeft: -30,
  },
  markdownError: css({
    color: COLORS.ERROR_RED,
  }),
};
