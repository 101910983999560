/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RadioGroup } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/RadioGroup.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import React, { useCallback, useState } from 'react';
import { FormInstanceAppealState } from '../../../redux/formInstanceAppeal/formInstanceAppealSlice.ts';
import {
  AppealInputChangeEventHandler,
  AppealOutcome,
} from '../../../redux/formInstanceAppeal/types.ts';
import { FormListItem } from '../../FormListItem/FormListItem.tsx';
import { SpeechBlockContainer } from '../../SpeechBlock/SpeechBlockContainer.tsx';

const APPEAL_OUTCOME_OPTIONS = [
  { id: 'Accepted', label: 'Accepted' },
  { id: 'PartiallyAccepted', label: 'Partially Accepted' },
  { id: 'Declined', label: 'Declined' },
  { id: 'Withdrawn', label: 'Withdrawn' },
];

const APPEAL_SPEECH_TEXT = `**Appeal**

This review has been appealed. Details are outlined below.
`;

export type AppealInputProps = {
  appeal: FormInstanceAppealState;
  appealOutcomeReasons: ISelectionListItem[];
  appealReasonCategories: ISelectionListItem[];
  assignedToLabel: string;
  canChange: boolean;
  instanceId: string;
  onAppealInputChange?: AppealInputChangeEventHandler;
};

export const AppealInput: React.FC<AppealInputProps> = ({
  appeal,
  appealOutcomeReasons,
  appealReasonCategories,
  assignedToLabel = 'Reviewer',
  canChange,
  instanceId,
  onAppealInputChange,
}) => {
  const { isPdfExport } = usePdfExportContext();
  const [isOutcomeReasonVisible, setIsOutcomeReasonVisible] = useState(
    appeal.outcome === 'Declined',
  );
  const { outcome, outcomeReason, reason, request, response } = appeal;

  const [currentAppealReason, setCurrentAppealReason] =
    useState<ISelectionListItem>(
      reason?.length ? { id: reason, value: reason } : undefined,
    );

  const [currentAppealOutcomeReason, setCurrentAppealOutcomeReason] =
    useState<ISelectionListItem>(
      outcomeReason?.length
        ? {
            id: outcomeReason,
            value: outcomeReason,
          }
        : undefined,
    );

  const updateResponse = useCallback(
    (to: string) => {
      if (canChange) onAppealInputChange?.({ field: 'response', to });
    },
    [onAppealInputChange, canChange],
  );

  const handleOutcomeReasonChange = useCallback(
    (to: ISelectionListItem) => {
      onAppealInputChange({ field: 'outcomeReason', to: to.value });
      setCurrentAppealOutcomeReason(to);
    },
    [onAppealInputChange],
  );

  const handleReasonChange = useCallback(
    (to: ISelectionListItem) => {
      onAppealInputChange({ field: 'reason', to: to.value });
      setCurrentAppealReason(to);
    },
    [onAppealInputChange],
  );

  const handleOutcomeChange = useCallback(
    (selectedId: AppealOutcome) => {
      setIsOutcomeReasonVisible(selectedId === 'Declined');
      onAppealInputChange({ field: 'outcome', to: selectedId });
      onAppealInputChange({ field: 'outcomeReason', to: undefined });
      setCurrentAppealOutcomeReason(undefined);
    },
    [onAppealInputChange],
  );

  return (
    <div css={styles.base}>
      <SpeechBlockContainer
        id={'appeal-speech'}
        instanceId={instanceId}
        markdown={APPEAL_SPEECH_TEXT}
      />
      <FormListItem
        id={'form-appeal-request'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        marginTop={30}
        paddingLeft={-32}
        paddingRight={-32}
        bulletTop={28}
        bulletLeft={-37}
      >
        <TextField
          id={'appeal-request'}
          multiline={true}
          label={'Appeal Request'}
          error={
            appeal.errors?.requestError
              ? 'You must supply a valid request.'
              : undefined
          }
          value={request}
          isEnabled={false}
        />
      </FormListItem>
      <FormListItem
        id={'form-appeal-response'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        marginTop={10}
        paddingLeft={-32}
        paddingRight={-32}
        bulletTop={28}
        bulletLeft={-37}
      >
        <TextField
          id={'appeal-response'}
          multiline={true}
          label={`${assignedToLabel} Appeal Response`}
          isEnabled={canChange}
          error={
            appeal.errors?.responseError
              ? 'You must supply a valid response.'
              : undefined
          }
          value={response}
          onChange={updateResponse}
        />
      </FormListItem>
      <FormListItem
        id={'form-appeal-outcome'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        paddingLeft={-32}
        paddingRight={-32}
        marginTop={40}
        bulletTop={-5}
        bulletLeft={-37}
      >
        <div css={styles.outcomeBase}>
          <Markdown
            style={
              !canChange
                ? styles.outcomeLabelDisabled
                : appeal.errors?.outcomeError
                  ? styles.outcomeLabelError
                  : styles.outcomeLabel
            }
            text={'#### Appeal Outcome'}
          />
          <div css={styles.options}>
            <RadioGroup
              direction={'vertical'}
              error={
                appeal.errors?.outcomeError
                  ? 'You must supply a valid outcome.'
                  : undefined
              }
              isEnabled={canChange}
              onChange={handleOutcomeChange}
              options={APPEAL_OUTCOME_OPTIONS}
              value={outcome}
            />
          </div>
        </div>
      </FormListItem>
      <div css={styles.categorisationHeader}>
        <Markdown
          style={
            !canChange
              ? styles.outcomeLabelDisabled
              : appeal.errors?.reasonError || appeal.errors?.outcomeReasonError
                ? styles.outcomeLabelError
                : styles.outcomeLabel
          }
          text={`#### Appeal Categorisation (for ${str.plural(
            assignedToLabel,
          )})`}
          selectable={true}
        />
      </div>
      <FormListItem
        id={'form-appeal-reason'}
        bullet={isPdfExport ? 'none' : 'small-open'}
        marginTop={20}
        paddingLeft={-32}
        paddingRight={-32}
        bulletTop={-25}
        bulletLeft={-37}
      >
        <div css={styles.categorisationRow}>
          <TextFieldDropdown
            id={'appeal-reason'}
            error={
              appeal.errors?.reasonError
                ? 'You must supply a valid reason.'
                : undefined
            }
            isEnabled={canChange}
            label={'Appeal Reason'}
            onChange={handleReasonChange}
            selections={appealReasonCategories}
            style={styles.reasonDropdown}
            value={currentAppealReason}
          />
          <Transition.Slide
            in={isOutcomeReasonVisible}
            direction={'left'}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <div css={styles.reasonDropdown}>
              <TextFieldDropdown
                id={'appeal-outcome-reason'}
                error={
                  appeal.errors?.outcomeReasonError
                    ? 'You must supply a valid outcome reason.'
                    : undefined
                }
                isEnabled={canChange}
                label={'Appeal Outcome Reason'}
                onChange={handleOutcomeReasonChange}
                selections={appealOutcomeReasons}
                value={currentAppealOutcomeReason}
              />
            </div>
          </Transition.Slide>
        </div>
      </FormListItem>
    </div>
  );
};

const styles = {
  base: css({
    paddingBottom: 25,
  }),
  categorisationHeader: css({
    marginBottom: -10,
    paddingTop: 25,
  }),
  categorisationRow: css({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  }),
  options: css({
    paddingLeft: 25,
  }),
  outcomeBase: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    paddingTop: 25,
  }),
  outcomeLabel: css({
    marginTop: -35,
  }),
  outcomeLabelDisabled: css({
    color: '#888888',
    marginTop: -35,
  }),
  outcomeLabelError: css({
    color: COLORS.ERROR_RED,
    marginTop: -35,
  }),
  reasonDropdown: css({
    flex: '1 1 auto',
    width: 300,
  }),
};
