/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CoachingSummarySectionInsight } from '@se/data/forms/types.ts';
import {
  Accordion,
  IAccordionChangeEvent,
} from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useState } from 'react';
import { formActionSlice } from '../../../../redux/formAction/index.ts';
import { useFormsDispatch } from '../../../../redux/store.ts';
import { CoachingSummaryLearningArea } from './CoachingSummaryLearningArea.tsx';

export type CoachingSummaryLearningAreaGroupProps = {
  instanceId?: string;
  label: string;
  learningAreas: CoachingSummarySectionInsight[];
  sectionExpanded: boolean;
  learningAreaListId?: string;
  onAccordionChange?: () => void;
  size: 'large' | 'small';
};

export const CoachingSummaryLearningAreaGroup: React.FC<
  CoachingSummaryLearningAreaGroupProps
> = ({
  instanceId,
  label,
  learningAreas,
  sectionExpanded,
  learningAreaListId,
  onAccordionChange,
  size,
}) => {
  const dispatch = useFormsDispatch();
  const momentTz = useMomentTenantTimezone();

  const [isExpanded, setIsExpanded] = useState(sectionExpanded);

  const insightActionLabel = useTenantLocale((l) => l.label.insightAction);

  const handleViewAction = useCallback(
    (actionId: string) => {
      if (!instanceId) {
        dispatch(
          formActionSlice.load({
            actionId,
            source: { type: 'DASHBOARD', gridKey: learningAreaListId },
          }),
        );
      }
      dispatch(
        formActionSlice.load({
          actionId,
          source: { type: 'FORM', instanceId },
        }),
      );
    },
    [dispatch, instanceId, learningAreaListId],
  );

  const handleExpandCollapse = useCallback(
    (e: IAccordionChangeEvent) => {
      setIsExpanded(e.isExpanded);
      onAccordionChange?.();
    },
    [onAccordionChange],
  );

  const actions = learningAreas.flatMap((learningArea) => learningArea.actions);

  const elSummaryContent = (
    <div css={styles.contentInner}>
      <Icons.school
        fill={COLORS.PURPLE}
        style={styles.iconPadding}
        tooltip={'Learning Area'}
        cursor={'pointer'}
        size={size === 'small' ? 22 : undefined}
      />
      <div css={styles.summaryText}>
        <Text
          color={color.format(-0.8)}
          ellipsis={true}
          style={styles.learningAreaLabel}
          cursor={'pointer'}
          weight={500}
          size={'inherit'}
        >
          {label}
        </Text>
      </div>
      {actions.length > 0 && (
        <Tooltip
          arrow={true}
          interactive={true}
          title={
            <div
              css={styles.createdActionTooltip}
              onClick={(e) => e.stopPropagation()}
            >
              {actions.map((action, index) => {
                const subText =
                  action.status === 'Completed' || action.status === 'Cancelled'
                    ? `Assigned to ${action.assignedTo.name} (${action.status}):`
                    : `Assigned to ${action.assignedTo.name} (Due ${momentTz(action.dueBy).format('D MMM YY')}):`;

                return (
                  <Tooltip key={index} title={'Click to View'}>
                    <div
                      onClick={() => handleViewAction(action.id)}
                      css={styles.viewActionTooltip}
                    >
                      <Text
                        size={15}
                        color={'white'}
                        style={styles.viewActionTooltipText}
                      >
                        {subText}
                      </Text>
                      <Text
                        size={13}
                        color={'white'}
                        style={styles.viewActionTooltipText}
                      >{`• ${str.removeMarkdownAndSpecialCharacters(action.description)}`}</Text>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          }
        >
          <div css={styles.actionsCreatedCount}>
            <Icons.actionList
              fill={'white'}
              size={size === 'small' ? 22 : undefined}
            />
            <Text
              size={size === 'small' ? 13 : 14}
              color={'white'}
              cursor={'pointer'}
              ellipsis={true}
            >{`${actions.length} ${str.plural(insightActionLabel, actions.length)} Created`}</Text>
          </div>
        </Tooltip>
      )}
    </div>
  );

  return (
    <Accordion
      id={`learningArea-${label}`}
      elevation={0}
      isExpanded={isExpanded}
      onChange={handleExpandCollapse}
      hideDividers={true}
      isDefaultExpanded={true}
    >
      <AccordionSummary
        rootStyle={
          size === 'small'
            ? styles.accordionSummarySmall
            : styles.accordionSummary
        }
        expandedStyle={styles.accordionExpanded}
        summaryContentStyle={
          size === 'small' ? styles.accordionSummaryContentSmall : undefined
        }
      >
        <div
          css={[
            styles.summaryContent,
            size === 'small' ? styles.smallFont : styles.largeFont,
          ]}
        >
          {elSummaryContent}
          <Text
            color={COLORS.BLUE}
            size={size === 'small' ? 13 : 14}
            cursor={'pointer'}
            style={styles.summaryShowHideText}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </Text>
        </div>
      </AccordionSummary>
      <AccordionDetails style={styles.detailsBase}>
        <div
          css={[
            styles.learningAreas,
            size === 'small' ? styles.smallFont : styles.largeFont,
          ]}
        >
          {learningAreas.map((learningArea) => (
            <CoachingSummaryLearningArea
              key={learningArea.id}
              instanceId={instanceId}
              learningArea={learningArea}
              size={size}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  accordionSummary: css({
    backgroundColor: '#fcf6ff',
  }),
  accordionSummarySmall: css({
    backgroundColor: '#fcf6ff',
    minHeight: '44px',
  }),
  accordionExpanded: css({
    backgroundColor: '#fcf6ff',
  }),
  accordionSummaryContentSmall: css({
    margin: '7px 0',
  }),
  summaryText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'start',
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  detailsBase: css({
    background: '#fefefe',
    border: `1px solid #cecece`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    padding: '12px 16px',
  }),
  iconPadding: {
    paddingRight: 10,
  },
  summaryContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
  summaryShowHideText: css({
    alignSelf: 'center',
    padding: '0 5px 0 20px',
  }),
  learningAreaLabel: css({
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
  }),
  actionsCreatedCount: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginLeft: 10,
    backgroundColor: color.format(-0.3),
    borderRadius: 5,
    border: `solid 1px ${color.format(-0.1)}`,
    padding: '3px 9px 3px 8px',
  }),
  createdActionTooltip: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  }),
  viewActionTooltip: css({
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  }),
  viewActionTooltipText: css({
    cursor: 'pointer',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    textWrap: 'wrap',
    overflow: 'hidden',
    display: '-webkit-box',
    whiteSpace: 'wrap',
  }),
  learningAreas: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  smallFont: css({
    fontSize: 14,
  }),
  largeFont: css({
    fontSize: 16,
  }),
};
