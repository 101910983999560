/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CoachingSummarySectionFilter,
  CoachingSummarySectionIssue,
} from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { groupBy } from 'ramda';
import { CoachingSummaryIssueGroup } from './CoachingSummaryIssueGroup.tsx';

export type CoachingSummaryIssuesProps = {
  filter: CoachingSummarySectionFilter;
  instanceId: string;
  isReloading: boolean;
  issues: CoachingSummarySectionIssue[];
};

export const CoachingSummaryIssues: React.FC<CoachingSummaryIssuesProps> = ({
  filter,
  instanceId,
  isReloading,
  issues,
}) => {
  const { isPdfExport } = usePdfExportContext();

  const issueGroups = Object.entries(
    groupBy((issue) => `${issue.label}-${issue.issueCoachingRequired}`, issues),
  );

  return (
    <div css={[styles.base, isReloading && styles.reloading]}>
      {issueGroups.length === 0 && (
        <Text>
          {filter === 'Last3Months'
            ? `No issues identified in QA reviews in the last 3 months.`
            : 'No issues identified.'}
        </Text>
      )}
      {issueGroups.length > 0 && (
        <>
          <Text>
            {filter === 'Last3Months'
              ? `Below are all issues that have been identified in QA reviews in the last 3 months:`
              : `Below are all issues that have been identified in QA reviews over this period:`}
          </Text>
          <div css={styles.groups}>
            {issueGroups.map(([label, group]) => (
              <CoachingSummaryIssueGroup
                key={label}
                label={group[0].label}
                issues={group}
                instanceId={instanceId}
                sectionExpanded={issueGroups.length < 3 || isPdfExport}
                size={'large'}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  reloading: css({
    opacity: 0.6,
  }),
  groups: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};
