/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import MaterialButton from '@mui/material/Button';
import { color as colorUtil } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { CommonStyles } from '../../common/commonStyles.ts';
import { IIcon } from '../Icon/types.ts';
import { Text } from '../Text/Text.tsx';

export type TextButtonProps = {
  buttonSize?: 'small' | 'medium' | 'large';
  buttonStyle?: SerializedStyles;
  children?: React.ReactNode;
  color?: string;
  cursor?: React.CSSProperties['cursor'];
  dataTest?: string;
  ellipsis?: boolean;
  fullWidth?: boolean;
  highlightOnHover?: boolean;
  icon?: IIcon;
  iconSide?: 'LEFT' | 'RIGHT';
  iconSize?: number;
  iconStyle?: React.CSSProperties;
  isEnabled?: boolean;
  justifyContent?: React.CSSProperties['justifyContent'];
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  size?: string | number;
  textStyle?: SerializedStyles;
  tooltip?: string | React.ReactElement;
  transitionDuration?: string;
  uppercase?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  weight?: React.CSSProperties['fontWeight'];
};

/**
 * A button that is made of text.
 */
export const TextButton: React.FC<TextButtonProps> = ({
  buttonSize = 'small',
  buttonStyle,
  children,
  color,
  cursor,
  dataTest,
  ellipsis = true,
  fullWidth,
  highlightOnHover,
  icon: Icon,
  iconSide = 'LEFT',
  iconSize = 24,
  iconStyle,
  isEnabled = true,
  justifyContent,
  onClick,
  onMouseEnter,
  onMouseLeave,
  size,
  textStyle,
  tooltip,
  transitionDuration,
  uppercase = false,
  variant = 'text',
  weight,
}) => {
  const textColor = color ?? (isEnabled ? COLORS.BLUE : '#888888');

  const computedStyles = {
    icon: css({
      cursor: isEnabled ? 'pointer' : 'default',
      display: 'flex',
      margin: '0 auto',
    }),
    text: css({
      cursor: isEnabled ? 'pointer' : 'default',
    }),
    button: css({
      '&.MuiButton-root': css(
        {
          ...CommonStyles.MaterialCubicTransitions,
          display: 'flex',
          alignItems: 'center',
          justifyContent,
          color: colorUtil.create(textColor).alpha(0.4).css(), // Changes the ripple color.
          '&:hover': {
            background: highlightOnHover
              ? colorUtil.create(textColor).alpha(0.04).css()
              : 'transparent',
          },
          transitionDuration,
        },
        buttonStyle,
      ),
    }),
  };

  const elIcon = Icon && (
    <Icon size={iconSize} fill={textColor} style={iconStyle} />
  );
  const elLeftIcon = elIcon && iconSide === 'LEFT' && (
    <div css={computedStyles.icon}>{elIcon}</div>
  );
  const elRightIcon = elIcon && iconSide === 'RIGHT' && (
    <div css={computedStyles.icon}>{elIcon}</div>
  );

  return (
    <MaterialButton
      data-test={dataTest}
      startIcon={elLeftIcon}
      endIcon={elRightIcon}
      disabled={!isEnabled}
      size={buttonSize}
      variant={variant}
      fullWidth={fullWidth}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={computedStyles.button}
    >
      <Text
        color={textColor}
        cursor={cursor}
        ellipsis={ellipsis}
        size={size}
        style={[computedStyles.text, textStyle]}
        tooltip={tooltip}
        tooltipArrow={true}
        uppercase={uppercase}
        weight={weight}
      >
        {children}
      </Text>
    </MaterialButton>
  );
};
