/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Image } from '@seeeverything/ui.primitives/src/components/Image/Image.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { useImageLoads } from '@seeeverything/ui.primitives/src/hooks/useImageLoads.ts';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import React from 'react';
import { getFileIcon, getFileLogo } from '../fileIcons.ts';
import ClickToConfirmDelete from './ClickToConfirmDelete.tsx';

export type HeaderProps = {
  size?: 'LARGE' | 'SMALL';
  extension: string;
  onDeleteClick?: () => void;
  onViewClick?: () => void;
};

export const Header: React.FC<HeaderProps> = ({
  size = 'SMALL',
  onDeleteClick,
  extension,
  onViewClick,
}) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();
  const fileLogo = React.useMemo(
    () => getFileLogo(extension, size),
    [extension, size],
  );

  const hasLogo = useImageLoads(fileLogo?.url);

  const isLarge = size === 'LARGE';

  const elFileLogo =
    hasLogo && fileLogo ? (
      <Image
        path={fileLogo.url}
        width={fileLogo.width}
        height={fileLogo.height}
      />
    ) : undefined;

  const Icon = Icons[getFileIcon(extension)];
  const elFileIcon = !hasLogo && (
    <>
      <Icon size={isLarge ? 100 : 43} fill={COLORS.WHITE} />
      <Text
        marginTop={10}
        color={COLORS.WHITE}
        size={isLarge ? 20 : 15}
        weight={FontWeight.bold}
        selectable={false}
      >
        {`${extension} file`}
      </Text>
    </>
  );

  const elInner = (
    <>
      {isMouseOver && onViewClick && (
        <Tooltip title={'Play'}>
          <div css={styles.viewFile}>
            <Transition.Zoom in={isMouseOver}>
              <div>
                <Icons.playArrow fill={'white'} size={isLarge ? 70 : 40} />
              </div>
            </Transition.Zoom>
          </div>
        </Tooltip>
      )}
      {elFileLogo || elFileIcon}
      {onDeleteClick && <ClickToConfirmDelete onConfirmClick={onDeleteClick} />}
    </>
  );

  return onViewClick ? (
    <Button
      style={styles.header(size, true)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onViewClick}
    >
      {elInner}
    </Button>
  ) : (
    <div css={styles.header(size, false)}>{elInner}</div>
  );
};

const styles = {
  viewFile: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: color.create(COLORS.BLUE).darken().alpha(0.4).css(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
  }),

  header: (size: 'LARGE' | 'SMALL' = 'SMALL', isButton?: boolean) =>
    css({
      position: 'relative' as const,
      width: '100%',
      flex: `${size === 'LARGE' ? 200 : 85}px 0 0`,
      borderRadius: '7px 7px 0 0',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: isButton ? COLORS.BLUE : '#DEDEDE',
      cursor: isButton ? 'pointer' : 'auto',
    }),
};
