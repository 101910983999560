/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Text } from '../Text/Text.tsx';
import { ChartFigure, ListFigure } from './components/index.ts';

export type ContentPlaceholderProps = {
  kind: 'CHART' | 'LIST';
  emptyText?: string | boolean;
  hintText?: string | boolean;
};

/**
 * A placeholder component to replace e.g. Grid/Chart when no data is available.
 */
export const ContentPlaceholder: React.FC<ContentPlaceholderProps> = ({
  kind,
  emptyText = true,
  hintText = true,
}) => (
  <div css={styles.base}>
    <div css={styles.outer}>
      {kind === 'CHART' ? <ChartFigure /> : <ListFigure />}
      <div css={styles.line} />
      {emptyText && (
        <Text
          italic={true}
          color={'rgba(0, 0, 0, 0.2)'}
          size={14}
          align={'center'}
        >
          {emptyText === true ? 'No items to display.' : emptyText}
        </Text>
      )}
      {emptyText && <div css={styles.gap} />}
      {hintText && (
        <Text align={'center'} color={color.format(-0.8)}>
          {hintText === true ? 'Create new sessions to get started.' : hintText}
        </Text>
      )}
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  outer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  line: css({
    minWidth: 200,
    borderTop: 'solid 3px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    paddingBottom: '10px',
  }),
  gap: css({
    height: 15,
  }),
};
