/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { is } from 'ramda';
import { Button } from '../../Button/Button.tsx';
import { Text } from '../../Text/Text.tsx';
import { ItemLabelValues } from '../types.ts';

export interface IItemLabelProps {
  ellipsis?: boolean;
  isEnabled?: boolean;
  textSize?: number;
  tooltip?: string;
  value: string | ItemLabelValues;
  center: boolean;
}

export const ItemLabel: React.FC<IItemLabelProps> = ({
  ellipsis = false,
  isEnabled = true,
  textSize = 16,
  tooltip,
  value,
  center,
}) => {
  const labelValue = is(String, value)
    ? { text: value as string }
    : (value as ItemLabelValues) || { text: 'Untitled' };

  const elDescription = labelValue.description && (
    <Text
      selectable={isEnabled}
      cursor={isEnabled ? 'pointer' : 'auto'}
      size={11}
      ellipsis={ellipsis}
      color={isEnabled ? undefined : '#888888'}
      style={styles.description}
    >
      {labelValue.description.trim()}
    </Text>
  );

  const computedStyles = {
    text: css({
      display: 'flex',
      textWrap: 'wrap',
      paddingTop: 2,
      textAlign: center ? 'center' : undefined,
    }),
  };

  return (
    <Button style={styles.base} isEnabled={isEnabled}>
      <>
        <Text
          cursor={isEnabled ? 'pointer' : 'auto'}
          size={textSize}
          ellipsis={ellipsis}
          style={computedStyles.text}
          color={isEnabled ? undefined : '#888888'}
          tooltip={tooltip}
        >
          {labelValue.text.trim() || 'Untitled'}
        </Text>
        {elDescription}
      </>
    </Button>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: -6,
    padding: 6,
  }),
  description: css({
    display: 'flex',
    paddingTop: 3,
  }),
};
