/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useMouseOver } from '../../hooks/useMouseOver.ts';
import { Button } from '../Button/Button.tsx';
import { IIcon } from '../Icon/types.ts';
import { Text } from '../Text/Text.tsx';
import { DropdownPopup } from './components/DropdownPopup.tsx';
import {
  ILabelButtonDropdown,
  LabelButtonDropdownClickEventHandler,
  LabelButtonSize,
  LabelButtonTheme,
} from './types.ts';

export interface LabelButtonProps {
  backgroundAlways?: boolean;
  bold?: boolean;
  capitalized?: boolean;
  dataTest?: string;
  dropdown?: ILabelButtonDropdown;
  dropdownMargin?: string | number;
  ellipsis?: boolean;
  emptyDropdownMessage?: string;
  isEnabled?: boolean;
  isVisible?: boolean;
  label: React.ReactNode;
  leftIcon?: IIcon;
  margin?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onDropdownClick?: LabelButtonDropdownClickEventHandler;
  onDropdownHide?: () => void;
  rightIcon?: IIcon;
  size?: LabelButtonSize;
  theme?: LabelButtonTheme;
  tooltip?: string;
  width?: string | number;
}

/**
 * A button that displays a text label.
 */
export const LabelButton: React.FC<LabelButtonProps> = ({
  backgroundAlways = true,
  bold = true,
  capitalized = true,
  dataTest,
  dropdown,
  dropdownMargin,
  ellipsis,
  emptyDropdownMessage,
  isEnabled,
  isVisible = true,
  label,
  leftIcon: LeftIcon,
  margin,
  maxWidth,
  minWidth,
  onClick,
  onDropdownClick,
  onDropdownHide,
  rightIcon: RightIcon,
  size = 'SMALL',
  theme = 'DARK',
  tooltip,
  width,
}) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();
  const [isMouseDown, handleMouseDown, handleMouseUp] = useMouseOver();

  const themeProps = getThemeProps({
    theme,
    isMouseOver,
    isMouseDown,
    leftIcon: Boolean(LeftIcon),
    rightIcon: Boolean(RightIcon),
    size,
    backgroundAlways,
  });

  const styles = {
    base: css({
      boxSizing: 'border-box',
      position: 'relative',
      visibility: isVisible ? 'visible' : 'hidden',
      maxWidth,
      minWidth,
      margin,
      paddingTop: themeProps.paddingTop,
      paddingBottom: themeProps.paddingBottom,
      background: themeProps.background,
      border: `${themeProps.border} ${themeProps.borderColor}`,
      borderTopColor: themeProps.borderTopColor,
      borderBottomColor: themeProps.borderBottomColor,
      borderRadius: 5,
      cursor: 'pointer',
      textAlign: 'center',
      boxShadow: themeProps.boxShadow,
    }),
    content: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      paddingLeft: themeProps.paddingLeft,
      paddingRight: themeProps.paddingRight,
    }),
    text: css({
      transform: isMouseDown ? `translateY(1px)` : undefined,
      textTransform: capitalized ? 'uppercase' : undefined,
    }),
    bevelTop: css({
      position: 'absolute',
      inset: '-1px 0 auto 0',
      height: 3,
      borderTop: `solid 1px ${color.format(0.2)}`,
      borderRadius: 4,
    }),
    iconLeft: css({
      position: 'absolute',
      top: isMouseDown ? themeProps.iconTop + 1 : themeProps.iconTop,
      height: themeProps.iconSize,
      left: 3,
    }),
    iconRight: css({
      position: 'absolute',
      top: isMouseDown ? themeProps.iconTop + 1 : themeProps.iconTop,
      height: themeProps.iconSize,
      right: 3,
    }),
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        dataTest={dataTest}
        isEnabled={isEnabled}
        onClick={onClick}
        margin={margin}
        width={width}
        tooltip={tooltip}
      >
        <div css={styles.base}>
          {themeProps.hasBevel && <div css={styles.bevelTop} />}
          <div css={styles.content}>
            {LeftIcon && (
              <div css={styles.iconLeft}>
                <LeftIcon
                  fill={themeProps.iconColor}
                  size={themeProps.iconSize}
                />
              </div>
            )}
            <Text
              color={themeProps.textColor}
              size={themeProps.textSize}
              selectable={false}
              weight={bold ? FontWeight.bold : FontWeight.normal}
              style={styles.text}
              ellipsis={ellipsis}
              textShadow={themeProps.textShadow}
            >
              {label}
            </Text>
            {RightIcon && (
              <div css={styles.iconRight}>
                <RightIcon
                  fill={themeProps.iconColor}
                  size={themeProps.iconSize}
                />
              </div>
            )}
          </div>
          {dropdown && (
            <DropdownPopup
              items={dropdown.items}
              width={dropdown.width}
              onClick={onDropdownClick}
              onHide={onDropdownHide}
              margin={dropdownMargin}
              emptyMessage={emptyDropdownMessage}
            />
          )}
        </div>
      </Button>
    </div>
  );
};

type GetThemePropsArgs = {
  backgroundAlways: boolean;
  isMouseDown: boolean;
  isMouseOver: boolean;
  leftIcon: boolean;
  rightIcon: boolean;
  size: 'SMALL' | 'MEDIUM';
  theme: LabelButtonTheme;
};

const getThemeProps = ({
  backgroundAlways,
  isMouseDown,
  isMouseOver,
  leftIcon,
  rightIcon,
  size,
  theme,
}: GetThemePropsArgs) => {
  switch (theme) {
    case 'WHITE':
      return {
        textColor: 'WHITE',
        border: 'solid 2px',
        borderColor:
          !backgroundAlways && !isMouseOver
            ? 'transparent'
            : isMouseOver
              ? color.format(1)
              : color.format(0.6),
        background:
          !backgroundAlways && !isMouseOver
            ? undefined
            : isMouseOver
              ? color.format(0.2)
              : color.format(0.1),
        textSize: size === 'SMALL' ? 12 : 14,
        paddingTop: size === 'SMALL' ? 5 : 7,
        paddingBottom: size === 'SMALL' ? 4 : 7,
        paddingLeft:
          size === 'SMALL' ? (leftIcon ? 33 : 15) : leftIcon ? 45 : 30,
        paddingRight:
          size === 'SMALL' ? (rightIcon ? 33 : 15) : rightIcon ? 45 : 30,
        iconSize: size === 'SMALL' ? 20 : 22,
        iconTop: size === 'SMALL' ? -4 : -3,
      };

    case 'DARK':
      return {
        textColor: isMouseOver ? 'WHITE' : color.format(-0.5),
        textShadow: isMouseOver ? [-1, -0.2] : [1, 0.9],
        border: 'solid 2px',
        borderColor:
          !backgroundAlways && !isMouseOver
            ? 'transparent'
            : isMouseOver
              ? 'transparent'
              : color.format(-0.1),
        background:
          !backgroundAlways && !isMouseOver
            ? undefined
            : isMouseOver
              ? COLORS.BLUE
              : color.format(-0.05),
        hasBevel: isMouseOver,
        iconColor: isMouseOver ? 'WHITE' : color.format(-0.5),
        textSize: size === 'SMALL' ? 12 : 14,
        paddingTop: size === 'SMALL' ? 5 : 7,
        paddingBottom: size === 'SMALL' ? 4 : 7,
        paddingLeft:
          size === 'SMALL' ? (leftIcon ? 33 : 15) : leftIcon ? 45 : 30,
        paddingRight:
          size === 'SMALL' ? (rightIcon ? 33 : 15) : rightIcon ? 45 : 30,
        iconSize: size === 'SMALL' ? 20 : 22,
        iconTop: size === 'SMALL' ? -4 : -3,
      };

    case 'GREEN_3D':
      return {
        textColor: 'WHITE',
        textShadow: [-1, -0.3],
        border: 'solid 1px',
        borderColor:
          !backgroundAlways && !isMouseOver
            ? 'transparent'
            : color.format(-0.1),
        borderTopColor: '#3AA753',
        borderBottomColor: '#318A44',
        background:
          !backgroundAlways && !isMouseOver
            ? undefined
            : isMouseOver
              ? 'linear-gradient(180deg, #46CD64 0%, #3BAC54 100%)'
              : 'linear-gradient(180deg, #3DCC5D 0%, #30A54A 100%)',
        boxShadow: isMouseDown
          ? 'inset 0px 4px 8px rgba(0, 0, 0, 0.25)'
          : undefined,
        iconColor: color.format(1),
        textSize: size === 'SMALL' ? 12 : 14,
        paddingTop: size === 'SMALL' ? 5 : 7,
        paddingBottom: size === 'SMALL' ? 4 : 7,
        paddingLeft:
          size === 'SMALL' ? (leftIcon ? 33 : 15) : leftIcon ? 45 : 30,
        paddingRight:
          size === 'SMALL' ? (rightIcon ? 33 : 15) : rightIcon ? 45 : 30,
        iconSize: size === 'SMALL' ? 20 : 22,
        iconTop: size === 'SMALL' ? -4 : -3,
      };

    case 'BLUE_3D':
      return {
        textColor: 'WHITE',
        textShadow: [-1, -0.3],
        border: 'solid 1px',
        borderColor:
          !backgroundAlways && !isMouseOver
            ? 'transparent'
            : color.format(-0.1),
        borderTopColor: '#527FD8',
        borderBottomColor: '#4971C8',
        background:
          !backgroundAlways && !isMouseOver
            ? undefined
            : isMouseOver
              ? 'linear-gradient(180deg, #70A6FA 0%, #608CDC 100%)'
              : 'linear-gradient(180deg, #659CF9 0%, #527FD8 100%)',
        boxShadow: isMouseDown
          ? 'inset 0px 4px 8px rgba(0, 0, 0, 0.25)'
          : undefined,
        iconColor: color.format(1),
        textSize: size === 'SMALL' ? 12 : 14,
        paddingTop: size === 'SMALL' ? 5 : 7,
        paddingBottom: size === 'SMALL' ? 4 : 7,
        paddingLeft:
          size === 'SMALL' ? (leftIcon ? 33 : 15) : leftIcon ? 45 : 30,
        paddingRight:
          size === 'SMALL' ? (rightIcon ? 33 : 15) : rightIcon ? 45 : 30,
        iconSize: size === 'SMALL' ? 20 : 22,
        iconTop: size === 'SMALL' ? -4 : -3,
      };

    case 'ERROR':
      return {
        background:
          !backgroundAlways && !isMouseOver
            ? undefined
            : isMouseOver
              ? COLORS.BLUE
              : color.format(-0.05),
        border: 'solid 1px',
        borderColor:
          !backgroundAlways && !isMouseOver ? 'transparent' : COLORS.ERROR_RED,
        hasBevel: isMouseOver && !isMouseDown,
        iconColor: COLORS.ERROR_RED,
        textColor: isMouseOver ? 'WHITE' : COLORS.ERROR_RED,
        textShadow: isMouseOver ? [-1, -0.2] : [1, 0.9],
        textSize: size === 'SMALL' ? 12 : 14,
        paddingTop: size === 'SMALL' ? 5 : 7,
        paddingBottom: size === 'SMALL' ? 4 : 7,
        paddingLeft:
          size === 'SMALL' ? (leftIcon ? 33 : 15) : leftIcon ? 45 : 30,
        paddingRight:
          size === 'SMALL' ? (rightIcon ? 33 : 15) : rightIcon ? 45 : 30,
        iconSize: size === 'SMALL' ? 20 : 22,
        iconTop: size === 'SMALL' ? -4 : -3,
      };

    default:
      throw new Error(`Theme '${theme}' not supported.`);
  }
};
