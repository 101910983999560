/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { Text, TextProps } from '../Text/Text.tsx';

/**
 * Wraps a `Text` component and provides standard link styling.
 */
export const TextLink: React.FC<TextProps> = ({
  style,
  color = COLORS.BLUE,
  ...restProps
}) => <Text {...restProps} style={[styles.link, style]} color={color} />;

const styles = {
  link: css({
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
    ':active': {
      ':hover': {
        transform: 'translateY(1px)', // 'Presses' the link.
        textDecoration: 'underline',
      },
    },
  }),
};
