/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Moment } from 'moment-timezone';
import { useCallback, useRef, useState } from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import {
  useDateContext,
  useMomentTenantTimezone,
} from '../../hooks/useDateContext.ts';
import { Icons } from '../Icon/Icons.tsx';
import { InputAdornment } from '../InputAdornment/InputAdornment.tsx';
import { OutsideAlerter } from '../OutsideAlerter/OutsideAlerter.tsx';
import { Popup } from '../Popup/Popup.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type DatePickerProps = {
  dataTest?: string;
  error?: string;
  helperText?: string;
  id: string;
  isEnabled?: boolean;
  label?: string;
  maxDate?: string;
  minDate?: string;
  onChange?: (toIso: string) => void;
  outputDate?: 'TenantEndOfDay' | 'TenantStartOfDay';
  style?: SerializedStyles;
  value?: string;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  dataTest,
  error,
  helperText,
  id,
  isEnabled = true,
  label = 'Choose a date',
  maxDate,
  minDate,
  onChange,
  outputDate = 'TenantStartOfDay',
  style,
  value,
}) => {
  const [showingCalendar, setShowingCalendar] = useState(false);
  const dateContext = useDateContext();
  const momentTz = useMomentTenantTimezone();

  const inputRef = useRef(null);

  const minDateMoment = minDate && momentTz(minDate);
  const maxDateMoment = maxDate && momentTz(maxDate);

  const handleDateChange = useCallback(
    (to: Moment, hideCalendar = true) => {
      const toDate = momentTz(to);
      const newTo =
        outputDate === 'TenantEndOfDay'
          ? toDate.endOf('day').toISOString()
          : toDate.hour(2).minute(0).second(0).toISOString();

      onChange?.(newTo);

      if (hideCalendar)
        setTimeout(() => {
          setShowingCalendar(false);
        }, 250);
    },
    [momentTz, onChange, outputDate],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <OutsideAlerter
        style={css(styles.base, style)}
        onClickedOutside={() => setShowingCalendar(false)}
      >
        <Tooltip
          title={
            value ? momentTz(value).format('dddd D MMMM, YYYY') : undefined
          }
        >
          <DateField
            id={id}
            data-test={dataTest}
            value={value ? momentTz(value) : undefined}
            onClick={() => setShowingCalendar(true)}
            label={label}
            disabled={!isEnabled}
            format={dateContext.format.toUpperCase()}
            helperText={error ?? helperText}
            variant={'standard'}
            onChange={(to, context) => {
              if (context?.validationError) return;
              return handleDateChange(to, false);
            }}
            fullWidth={true}
            minDate={minDateMoment}
            maxDate={maxDateMoment}
            sx={error ? CommonStyles.AnimationShake : undefined}
            slotProps={{
              textField: {
                error: Boolean(error),
                ref: inputRef,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Icons.event
                        fill={
                          isEnabled ? color.format(-0.54) : color.format(-0.26)
                        }
                        onClick={
                          isEnabled ? () => setShowingCalendar(true) : undefined
                        }
                      />
                    </InputAdornment>
                  ),
                },
              },
            }}
          />
        </Tooltip>
        {showingCalendar && (
          <Popup onHide={() => setShowingCalendar(false)} hideOnClick={false}>
            <DateCalendar
              onChange={(to, pickerSelectionState) => {
                if (pickerSelectionState !== 'finish') return;
                handleDateChange(to, true);
              }}
              value={momentTz(value)}
              minDate={minDate && momentTz(minDate)}
              maxDate={maxDate && momentTz(maxDate)}
              disabled={!isEnabled}
            />
          </Popup>
        )}
      </OutsideAlerter>
    </LocalizationProvider>
  );
};

const styles = {
  base: css({
    display: 'flex',
    position: 'relative',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    width: '100%',
  }),
};
