/** @jsxImportSource @emotion/react */
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { useDebounce } from '@seeeverything/ui.primitives/src/hooks/useDebounce.ts';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { safeParse } from '@seeeverything/ui.util/src/json/json.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo, useState } from 'react';
import { usePeopleQuery } from '../../query/usePeopleQuery.ts';
import { useTeamsQuery } from '../../query/useTeamsQuery.ts';
import { formInstanceAnswerChange } from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, IDropdownAnswerLine } from '../../types/types.ts';
import { DropdownAnswer } from '../DropdownAnswer/DropdownAnswer.tsx';

export interface IDropdownAnswerContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isVisible: boolean;
  line: IDropdownAnswerLine;
}

export const DropdownAnswerContainer: React.FC<
  IDropdownAnswerContainerProps
> = ({ canUpdateInstance, instanceId, isVisible, line }) => {
  const client = useGraphQL();
  const [inputFilter, setInputFilter] = useState('');
  const [inputDebounce] = useDebounce(inputFilter, 250);
  const { isPdfExport } = usePdfExportContext();

  const updateInputFilter = useCallback(
    (to: string, reason: 'input' | 'reset') => {
      const newTo = reason === 'input' ? to : '';
      setInputFilter(newTo);
    },
    [],
  );

  const isTeamDropdown = line.dropdownListName === 'TEAMS';

  const { people, isFetchingPeople } = usePeopleQuery(client, {
    inputFilter: inputDebounce,
    isEnabled: !isTeamDropdown && !isPdfExport,
  });

  const { teams, isFetchingTeams } = useTeamsQuery(client, {
    inputFilter: inputDebounce,
    isEnabled: isTeamDropdown && !isPdfExport,
  });

  const isLoading = isTeamDropdown ? isFetchingTeams : isFetchingPeople;

  const dispatch = useFormsDispatch();

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValueSelection = useMemo(() => {
    if (!answer) return;

    const answerJson = safeParse<{ id: string }>(answer.value);
    if (!answerJson?.id) return;

    return {
      id: answerJson.id,
      value: answerJson.id,
      content: { text: answer.displayValue },
    };
  }, [answer]);

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      if (!isVisible) return;

      const answerValue = JSON.stringify({
        id: to.id.toString(),
        kind: isTeamDropdown ? 'Team' : 'Person',
      });

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: answerValue,
        lineId: line.id,
        type: 'inputs',
        subType: 'dropdownAnswer',
        displayValue: to.content?.text?.toString(),
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isTeamDropdown,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  return (
    <DropdownAnswer
      error={error}
      floatingText={line.floatingText}
      helperText={line.helperText}
      id={line.id}
      isDropdownLoading={inputFilter !== inputDebounce || isLoading}
      isEnabled={canUpdateInstance}
      onChange={updateAnswer}
      onInputChange={updateInputFilter}
      selections={isTeamDropdown ? teams : people}
      value={answerValueSelection}
    />
  );
};
