/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { MaterialListItemType } from '../types.ts';

export type MaterialListItemProps = {
  item: MaterialListItemType;
  isSelected: boolean;
  onClick?: () => void;
};

export const MaterialListItem: React.FC<MaterialListItemProps> = ({
  item,
  onClick,
  isSelected,
}) => {
  const {
    isClickable = true,
    type = 'ITEM',
    icon: Icon,
    text,
    description,
    id,
    isDimmed,
    iconFill,
  } = item;

  const styles = {
    header: css({
      '&.MuiListSubheader-root': css({
        backgroundColor: '#ebebeb',
      }),
    }),
    listItemButton: css({
      '&.MuiListItemButton-root': css({
        padding: '5px 10px',
      }),
    }),
    itemHeading: css({
      '&.MuiTypography-root': {
        cursor: isClickable ? 'cursor' : undefined,
        fontStyle: isDimmed ? 'italic' : 'normal',
        userSelect: isDimmed ? 'none' : 'auto',
        display: 'block',
        color: isDimmed ? color.format(-0.3) : color.format(-1),
      },
    }),
    itemDescription: css({
      '&.MuiTypography-root': {
        cursor: isClickable ? 'cursor' : undefined,
        fontStyle: isDimmed ? 'italic' : 'normal',
        userSelect: isDimmed ? 'none' : 'auto',
        display: 'block',
        color: isDimmed ? color.format(-0.2) : color.format(-0.6),
        fontSize: 13,
      },
    }),
  };

  if (type === 'HEADER') {
    return <ListSubheader sx={styles.header}>{text}</ListSubheader>;
  }

  const elChildren = (
    <>
      {Icon && (
        <ListItemIcon>
          <Icon fill={isDimmed ? color.format(-0.3) : iconFill} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={<Typography sx={styles.itemHeading}>{text}</Typography>}
        secondary={
          description && (
            <Typography sx={styles.itemDescription}>{description}</Typography>
          )
        }
      />
    </>
  );

  return isClickable ? (
    <ListItemButton
      id={id}
      onClick={isClickable ? onClick : undefined}
      selected={isSelected}
      sx={styles.listItemButton}
    >
      {elChildren}
    </ListItemButton>
  ) : (
    <ListItem>{elChildren}</ListItem>
  );
};
