/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CoachingSummarySectionFilter,
  CoachingSummarySectionInsight,
} from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { groupBy, prop } from 'ramda';
import { CoachingSummaryLearningAreaGroup } from './CoachingSummaryLearningAreaGroup.tsx';

export type CoachingSummaryLearningAreasProps = {
  filter: CoachingSummarySectionFilter;
  instanceId: string;
  isReloading: boolean;
  learningAreas: CoachingSummarySectionInsight[];
};

export const CoachingSummaryLearningAreas: React.FC<
  CoachingSummaryLearningAreasProps
> = ({ instanceId, learningAreas, isReloading, filter }) => {
  const { isPdfExport } = usePdfExportContext();
  const formLabels = useTenantLocale((l) => ({
    singular: l.label.form.toLowerCase(),
    plural: str.plural(l.label.form.toLowerCase()),
  }));

  const learningAreaGroups = Object.entries(
    groupBy(prop('label'), learningAreas),
  );

  return (
    <div css={[styles.base, isReloading && styles.reloading]}>
      {learningAreaGroups.length === 0 && (
        <Text>
          {filter === 'Last3Months'
            ? `No learning areas identified in ${formLabels.plural} in the last 3 months.`
            : 'No learning areas identified.'}
        </Text>
      )}
      {learningAreaGroups.length > 0 && (
        <>
          <Text>
            {filter === 'Last3Months'
              ? `Below are all learning areas that have been identified in ${formLabels.plural} in the last 3 months. Consider if they should be a focus in this ${formLabels.singular}.`
              : `Below are all learning areas that have been identified in ${formLabels.plural} over this period:`}
          </Text>
          <div css={styles.groups}>
            {learningAreaGroups.map(([label, group]) => (
              <CoachingSummaryLearningAreaGroup
                key={label}
                label={label}
                learningAreas={group}
                instanceId={instanceId}
                sectionExpanded={learningAreaGroups.length < 3 || isPdfExport}
                size={'large'}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  reloading: css({
    opacity: 0.6,
  }),
  groups: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};
