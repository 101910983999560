import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatedUser, ModuleType } from '../../types.ts';
import {
  TenantConfiguration,
  TenantFeatureFlags,
  TenantLocale,
} from './types.ts';
import {
  getLocale,
  getTenantConfigWithDefaults,
  getTenantFeatureFlags,
} from './util.ts';

type SetConfigurationPayload = {
  id: string;
  module: ModuleType;
  configuration: TenantConfiguration;
};

export type TenantState = {
  isMultiTenant: boolean;
  reportingUrl?: string;
  tenant: {
    id: string;
    module: ModuleType;
    configuration: TenantConfiguration;
    flags: TenantFeatureFlags;
    locale: TenantLocale;
    authenticatedUser?: AuthenticatedUser;
    allowedModules: {
      coaching: boolean;
      compliance: boolean;
      cadence: boolean;
    };
  };
};

const DEFAULT_STATE: TenantState = {
  isMultiTenant: false,
  tenant: {
    id: 'UNDEFINED',
    module: 'coaching',
    configuration: getTenantConfigWithDefaults(),
    flags: getTenantFeatureFlags(),
    locale: getLocale(),
    allowedModules: {
      coaching: true,
      compliance: false,
      cadence: false,
    },
  },
};

const slice = createSlice({
  name: 'global/tenant',
  initialState: DEFAULT_STATE,
  reducers: {
    setAuthenticatedUser(state, action: PayloadAction<AuthenticatedUser>) {
      if (!state.tenant) return;

      state.tenant.authenticatedUser = action.payload;
    },
    setConfiguration(state, action: PayloadAction<SetConfigurationPayload>) {
      const { id, module, configuration } = action.payload;
      state.tenant = {
        id,
        module,
        configuration: getTenantConfigWithDefaults(configuration),
        flags: getTenantFeatureFlags(configuration, module),
        locale: getLocale(configuration, module),
        authenticatedUser: state.tenant?.authenticatedUser,
        allowedModules: {
          coaching: Boolean(configuration.modules?.coaching?.isEnabled),
          compliance: Boolean(configuration.modules?.compliance?.isEnabled),
          cadence: Boolean(configuration.modules?.cadence?.isEnabled),
        },
      };
    },
    setModule(state, action: PayloadAction<{ to: ModuleType }>) {
      if (!state.tenant) return;

      state.tenant.module = action.payload.to;
      state.tenant.flags = getTenantFeatureFlags(
        state.tenant.configuration,
        action.payload.to,
      );
      state.tenant.locale = getLocale(
        state.tenant.configuration,
        action.payload.to,
      );
    },
    setMultiTenant(state, action: PayloadAction<{ to: boolean }>) {
      state.isMultiTenant = action.payload.to;
    },
    setReportingUrl(state, action: PayloadAction<{ to: string }>) {
      state.reportingUrl = action.payload.to;
    },
  },
});

export const {
  setAuthenticatedUser,
  setConfiguration,
  setModule,
  setMultiTenant,
  setReportingUrl,
} = slice.actions;
export const reducer = slice.reducer;
export type State = TenantState;
