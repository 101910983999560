import { FormAction, FormGoal } from '@se/data/forms/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType, OrderBy } from '@seeeverything/ui.util/src/types.ts';
import momentTz from 'moment-timezone';
import { QueryResult } from '../../types.ts';
import { getActions } from './actions.ts';
import { getGoals } from './goals.ts';

export type GoalsActionsGridQueryArgs = {
  key: 'goalsAndActions';
  actionLabel: string;
  dueByDirection: 'Ascending' | 'Descending';
  endDate: string;
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  issueActionLabel: string;
  module: ModuleType;
  orderBy: OrderBy;
  sheetType: 'DASHBOARD' | 'FULL_SHEET';
  showCompleted: boolean;
  spreadsheetDownload: boolean;
  startDate: string;
};

type RowData = {
  id: string;
  data: string[];
  record: FormAction | FormGoal;
};

type GetGoalsActionsGridResult = {
  hasNextPage: boolean;
  totalCount: number;
  records: (FormAction | FormGoal)[];
  rowData: RowData[];
};

export const getGoalsActionsGrid = async (
  client: IGraphQLClient,
  args: GoalsActionsGridQueryArgs,
  timezone: string,
): Promise<QueryResult<GetGoalsActionsGridResult>> => {
  const {
    actionLabel,
    dueByDirection,
    entityId,
    entityType,
    issueActionLabel,
    orderBy,
    sheetType,
    showCompleted,
    startDate,
    endDate,
    module,
    spreadsheetDownload,
  } = args;

  const orderBys = [
    orderBy,
    { fieldName: 'dueBy', direction: dueByDirection },
  ].filter(Boolean);

  const goalsResponse = await getGoals(client, {
    actionLabel,
    endDate,
    fetchAllPages: sheetType === 'FULL_SHEET',
    orderBy: orderBys.filter(({ fieldName }) => fieldName !== 'type'),
    pageSize: sheetType === 'FULL_SHEET' ? 50 : 10,
    personId: entityType === 'PERSON' ? entityId : undefined,
    startDate,
    status: {
      include: [],
      exclude: showCompleted ? ['Cancelled'] : ['Completed', 'Cancelled'],
    },
    teamId: entityType === 'TEAM' ? entityId : undefined,
  });

  const standaloneActionsResponse = await getActions(client, {
    actionLabel,
    endDate,
    fetchAllPages: sheetType === 'FULL_SHEET',
    hasParentGoal: false,
    issueActionLabel,
    orderBy: orderBys.filter(({ fieldName }) => fieldName !== 'type'),
    pageSize: sheetType === 'FULL_SHEET' ? 50 : 10,
    personId: entityType === 'PERSON' ? entityId : undefined,
    startDate,
    status: {
      include: [],
      exclude: showCompleted ? ['Cancelled'] : ['Completed', 'Cancelled'],
    },
    teamId: entityType === 'TEAM' ? entityId : undefined,
  });

  const supportingActionsResponse = await getActions(client, {
    actionLabel,
    endDate,
    fetchAllPages: sheetType === 'FULL_SHEET',
    issueActionLabel,
    isSupporting: true,
    orderBy: orderBys.filter(({ fieldName }) => fieldName !== 'type'),
    pageSize: sheetType === 'FULL_SHEET' ? 50 : 10,
    personId: entityType === 'PERSON' ? entityId : undefined,
    startDate,
    status: {
      include: [],
      exclude: showCompleted ? ['Cancelled'] : ['Completed', 'Cancelled'],
    },
    teamId: entityType === 'TEAM' ? entityId : undefined,
  });

  if (
    !goalsResponse.isSuccess ||
    !standaloneActionsResponse.isSuccess ||
    !supportingActionsResponse.isSuccess
  )
    return {
      isSuccess: false,
      errorReason: 'NOT_FOUND',
    };

  const results = [
    ...goalsResponse.data.goals,
    ...standaloneActionsResponse.data.actions,
    ...supportingActionsResponse.data.actions,
  ];

  results.forEach((v) => {
    // Mutates the description field.
    v.description = str.removeMarkdownAndSpecialCharacters(v.description, {
      removeListLeaders: !spreadsheetDownload,
      removeNewlineChars: !spreadsheetDownload,
    });
  });

  const sorted = results.sort(
    (a: FormGoal | FormAction, b: FormGoal | FormAction) => {
      for (const field of orderBys) {
        const fieldName =
          field.fieldName === 'type'
            ? 'typeLabel'
            : (field.fieldName as keyof (FormGoal | FormAction));

        if (a[fieldName] < b[fieldName])
          return field.direction === 'Ascending' ? -1 : 1;

        if (a[fieldName] > b[fieldName])
          return field.direction === 'Ascending' ? 1 : -1;
      }
      return 0;
    },
  );

  const goalsPageInfo = goalsResponse.data.pageInfo;
  const standaloneActionsPageInfo = standaloneActionsResponse.data.pageInfo;
  const supportingActionsPageInfo = supportingActionsResponse.data.pageInfo;
  const hasNextPage =
    goalsPageInfo.hasNextPage ||
    standaloneActionsPageInfo.hasNextPage ||
    supportingActionsPageInfo.hasNextPage;

  const records = sheetType === 'DASHBOARD' ? sorted.slice(0, 10) : sorted;
  const includeTypeColumn = module === 'coaching';
  const rowData = (records ?? [])
    .map((record) => {
      const row = createRowData(record, includeTypeColumn, timezone);

      if (record.type !== 'FormGoal') return [row];
      if (!record.actions?.length) return [row];

      const childActionRows = record.actions.map((action) =>
        createRowData(action, includeTypeColumn, timezone),
      );
      return [row, ...childActionRows];
    })
    .flat();

  return {
    isSuccess: true,
    data: {
      hasNextPage,
      totalCount:
        goalsPageInfo.totalCount +
        standaloneActionsPageInfo.totalCount +
        supportingActionsPageInfo.totalCount,
      records,
      rowData,
    },
  };
};

const createRowData = (
  record: FormGoal | FormAction,
  includeType: boolean,
  timezone: string,
) => {
  const data = [
    record.createdAt &&
      momentTz(record.createdAt).tz(timezone).format('D MMM YYYY'),
    record.description,
    record.assignedTo?.name,
    record.dueBy && momentTz(record.dueBy).tz(timezone).format('D MMM YYYY'),
    record.statusLabel,
  ];

  if (includeType) {
    data.splice(1, 0, record.typeLabel);
  }

  return {
    id: record.id,
    data,
    record,
  };
};
