/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { memo, useState } from 'react';

export type CommentFieldProps = {
  focusOnMount?: boolean;
  id: string;
  isEnabled?: boolean;
  label?: string;
  onBlur?: () => void;
  onChange: (to: string) => void;
  onDiscard: () => void;
  onSubmit: () => void;
  style?: SerializedStyles;
  submitLabel?: string;
  value: string;
};

/**
 * Field for adding or replying to comments.
 */
export const CommentField: React.FC<CommentFieldProps> = memo(
  function CommentFieldView({
    focusOnMount,
    id,
    isEnabled = true,
    label = 'Add Comment',
    onBlur,
    onChange,
    onDiscard,
    onSubmit,
    style,
    submitLabel = 'Comment',
    value,
  }) {
    const [isShowing, setIsShowing] = useState(false);

    const handleSubmitComment = () => {
      onSubmit();
      setIsShowing(false);
    };

    const handleDiscardClicked = () => {
      onDiscard();
      setIsShowing(false);
    };

    const handleKeyDown = (key: string) => {
      if (key === 'Enter') {
        handleSubmitComment();
      }
    };

    const handleBlur = (e: React.FocusEvent) => {
      // Prevents the blur being fired if a child node has been selected under the div.
      if (e.currentTarget.contains(e.relatedTarget as Node)) {
        return;
      }

      onBlur?.();
      setIsShowing(false);
    };

    return (
      <div css={[styles.base, style]} onBlur={handleBlur}>
        <TextField
          isEnabled={isEnabled}
          id={id}
          label={label}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            onFocus: () => setIsShowing(true),
            autoFocus: focusOnMount,
          }}
        />
        <Transition.Collapse
          in={Boolean(isShowing || value)}
          style={styles.controls}
        >
          <div css={styles.controlsInner}>
            <TextButton onClick={handleDiscardClicked}>{'Discard'}</TextButton>
            <TextButton
              color={'white'}
              icon={Icons.send}
              iconSide={'RIGHT'}
              iconSize={20}
              iconStyle={styles.submitIcon}
              onClick={handleSubmitComment}
              buttonStyle={styles.submitButton}
            >
              {submitLabel}
            </TextButton>
          </div>
        </Transition.Collapse>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  controls: {
    marginTop: 6,
    alignSelf: 'flex-end',
  },
  controlsInner: css({
    display: 'flex',
  }),
  submitButton: css({
    marginLeft: 6,
    padding: '4px 10px',
    background: COLORS.BLUE,
    '&:hover': {
      background: color.create(COLORS.BLUE).brighten(0.2).css(),
    },
  }),
  submitIcon: {
    fill: 'white',
  },
};
