/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FormFileAction } from '../../../redux/form-file/types.ts';

export type StatusIconButtonProps = {
  isError?: boolean;
  canRetry?: boolean;
  action?: FormFileAction;
  canDownload?: boolean;
  canView?: boolean;
  onDownloadClick: () => void;
  style?: SerializedStyles;
};

export const StatusIconButton: React.FC<StatusIconButtonProps> = ({
  isError,
  action,
  onDownloadClick,
  canDownload,
  canRetry,
  canView,
  style,
}) => {
  const showError = isError && !canRetry && action !== 'downloading';
  if (showError)
    return (
      <div css={[styles.iconOuter, styles.iconShift, style]}>
        <Icons.errorOutline fill={COLORS.ERROR_RED} />
      </div>
    );

  if (canView)
    return (
      <div css={[styles.iconOuter, style]}>
        <Icons.playArrow
          fill={isError ? COLORS.ERROR_RED : COLORS.BLUE}
          onClick={onDownloadClick}
          tooltip={'Play'}
        />
      </div>
    );

  if (canDownload || isError)
    return (
      <div css={[styles.iconOuter, style]}>
        <Icons.fileDownload
          fill={isError ? COLORS.ERROR_RED : COLORS.BLUE}
          onClick={onDownloadClick}
          tooltip={'Download'}
        />
      </div>
    );
};

const styles = {
  iconOuter: css({
    flex: '30px 1 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  }),
  iconShift: css({
    position: 'relative',
    top: -3,
  }),
};
