/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import React from 'react';
import { IFormSelectionListItem } from '../../types/types.ts';

export interface IDropdownAnswerProps {
  error?: string;
  floatingText?: string;
  group?: string;
  helperText?: string;
  id: string;
  isEnabled?: boolean;
  isDropdownLoading: boolean;
  label?: string;
  onChange: (to: IFormSelectionListItem) => void;
  onInputChange?: (to: string, reason: 'reset' | 'input') => void;
  selectionDisplayValue?: string;
  selectionId?: string;
  selections?: ISelectionListItem<IListItemLabel>[];
  value?: ISelectionListItem<IListItemLabel>;
}

/**
 * Form component that allows capturing of a dropdown item as a form answer.
 */
export const DropdownAnswer: React.FC<IDropdownAnswerProps> = ({
  error,
  floatingText,
  helperText,
  id,
  isDropdownLoading,
  isEnabled,
  label,
  onChange,
  onInputChange,
  selections,
  value,
}) => {
  const { isPdfExport } = usePdfExportContext();

  const styles = {
    base: css({
      flex: '1 1 275px',
    }),
    markdownOuter: css({
      marginBottom: 2,
      height: 31,
    }),
    dropdownOuter: css({
      marginLeft: label ? 20 : undefined,
      flex: '1 1 auto',
    }),
    textContainer: css({
      display: 'flex',
      flexDirection: 'column',
    }),
    textLabel: css({
      fontSize: 12,
      color: '#808080',
      paddingBottom: 5,
    }),
  };

  const elMarkdown = label && (
    <div css={styles.markdownOuter}>
      <Markdown text={label} />
    </div>
  );

  const elText = isPdfExport ? (
    <div css={styles.textContainer}>
      <Text style={styles.textLabel}>{floatingText}</Text>
      <Text>{value?.content?.text || '-'}</Text>
    </div>
  ) : (
    <TextFieldDropdown
      id={id}
      isEnabled={isEnabled}
      label={floatingText}
      helperText={helperText}
      value={value}
      isLoading={isDropdownLoading}
      onChange={onChange}
      onInputChanged={onInputChange}
      error={error}
      selections={selections}
    />
  );

  return (
    <div css={styles.base}>
      {elMarkdown}
      <div css={styles.dropdownOuter}>{elText}</div>
    </div>
  );
};
