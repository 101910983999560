/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { InputProps as MaterialInputProps } from '@mui/material/Input';
import { InputLabelProps as MaterialInputLabelProps } from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { isFuzzyMatch } from '@seeeverything/ui.util/src/str/str.fuzzy.ts';
import { is } from 'ramda';
import { isValidElement, useCallback, useEffect, useMemo } from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { useViewport } from '../../hooks/useViewport.ts';
import { IconContent } from '../IconContent/IconContent.tsx';
import { IListItemLabel, ISelectionListItem } from '../SelectionList/types.ts';
import { Spinner } from '../Spinner/index.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type TextFieldDropdownProps<T = any, V = any> = {
  dataTest?: string;
  defaultValue?: ISelectionListItem<T, V>;
  disableClearable?: boolean;
  emptyLabel?: string;
  emptyValueLabel?: string;
  error?: string;
  formatItem?: (item: any) => ISelectionListItem<T, V>;
  hasNextPage?: boolean;
  helperText?: string;
  id: string;
  InputLabelProps?: MaterialInputLabelProps;
  inputLabelRootStyle?: SerializedStyles;
  inputLabelShrinkStyle?: SerializedStyles;
  InputProps?: MaterialInputProps;
  inputRootStyle?: SerializedStyles;
  inputValue?: string;
  isEnabled?: boolean;
  isLoading?: boolean;
  isLoadingNextPage?: boolean;
  isOpen?: boolean;
  label?: string;
  listBoxStyle?: SerializedStyles;
  onChange?: (to: ISelectionListItem<T, V>) => void;
  onInputChanged?: (to: string, reason: AutocompleteInputChangeReason) => void;
  onNextPageRequested?: () => void;
  onOpen?: () => void;
  openOnFocus?: boolean;
  placeholder?: string;
  popperWidth?: string | number;
  selectionLabel?: (selection: ISelectionListItem<T, V>) => string;
  selections?: ISelectionListItem<T, V>[];
  shouldFilter?: boolean;
  size?: 'medium' | 'small';
  style?: SerializedStyles;
  value?: ISelectionListItem<T, V> | null;
  variant?: 'standard' | 'filled' | 'outlined';
};

export const TextFieldDropdown: React.FC<TextFieldDropdownProps> = ({
  dataTest,
  defaultValue,
  disableClearable = true,
  emptyLabel = 'Nothing to display.',
  emptyValueLabel,
  error,
  formatItem,
  hasNextPage,
  helperText,
  id,
  InputLabelProps,
  inputLabelRootStyle,
  inputLabelShrinkStyle,
  InputProps,
  inputRootStyle,
  inputValue,
  isEnabled = true,
  isLoading,
  isLoadingNextPage,
  isOpen,
  label,
  listBoxStyle,
  onChange,
  onInputChanged,
  onNextPageRequested,
  onOpen,
  openOnFocus,
  placeholder,
  popperWidth,
  selections = [],
  shouldFilter = true,
  size,
  style,
  value = null, // Empty value should be set to `null` to specify this is a controlled component.
  variant = 'standard',
}) => {
  const [trackLoadMoreRef, inViewport] = useViewport({ delay: 30 });

  useEffect(() => {
    if (!onNextPageRequested) return;
    if (!inViewport) return;
    if (!hasNextPage) return;
    if (isLoadingNextPage) return;

    onNextPageRequested();
  }, [hasNextPage, inViewport, isLoadingNextPage, onNextPageRequested]);

  const selectionLabel = useCallback(
    (selection?: ISelectionListItem): string => {
      if (!selection) return '';
      if (!selection.content) return selection.value as string;
      if (is(String, selection.content)) return selection.content as string;
      if ((selection.content as IListItemLabel).text)
        return (selection.content as IListItemLabel).text as string;

      return selection.value as string;
    },
    [],
  );

  const filterSelectionListItem = useCallback(
    (options: ISelectionListItem[], state: { inputValue: string }) => {
      const input = state.inputValue;

      return options.filter((option) => {
        if (is(String, option.content))
          return isFuzzyMatch(input, option.content as string);

        const text = (option.content as IListItemLabel)?.text ?? option.value;
        return isFuzzyMatch(input, text);
      });
    },
    [],
  );

  const computedStyles = {
    autocomplete: css({
      '.MuiInputLabel-root': css(styles.inputLabelRoot, inputLabelRootStyle),
      '.MuiInputLabel-shrink': css(
        styles.inputLabelShrink,
        inputLabelShrinkStyle,
      ),
      '.MuiAutocomplete-inputRoot': css(styles.inputRoot, inputRootStyle),
      '.MuiOutlinedInput-root': css({
        padding: '7px 9px 6px 9px',
      }),
    }),
  };

  const options = useMemo(() => {
    if (isLoading && !isLoadingNextPage) return [];
    if (!value) return selections;

    const selectionValue =
      selections.find((selection) => selection.id === value.id) ?? value;

    const otherSelections = selections.filter(
      (selection) => selection.id !== value?.id,
    );

    return [selectionValue ?? value, ...otherSelections];
  }, [isLoading, isLoadingNextPage, selections, value]);

  return (
    <div
      css={[
        styles.base,
        error ? CommonStyles.AnimationShake : undefined,
        style,
      ]}
      data-test={dataTest}
    >
      <FormControl fullWidth={true} error={Boolean(error)}>
        <Autocomplete
          autoComplete={false}
          defaultValue={defaultValue}
          disableClearable={disableClearable}
          disabled={!isEnabled}
          filterOptions={shouldFilter ? filterSelectionListItem : undefined}
          filterSelectedOptions={false}
          getOptionLabel={selectionLabel}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          groupBy={({ category }) => category}
          id={id}
          inputValue={inputValue}
          loading={isLoading}
          loadingText={
            <div css={styles.spinner}>
              <Spinner center={true} />
            </div>
          }
          multiple={false}
          noOptionsText={emptyLabel}
          onChange={(_, to) => {
            if (to !== null && to.isEnabled !== false) onChange?.(to);
          }}
          onInputChange={(_, to, reason) => {
            if (to !== undefined) onInputChanged?.(to, reason);
          }}
          onOpen={onOpen}
          open={isOpen}
          openOnFocus={openOnFocus}
          options={options}
          renderInput={(textFieldProps) => (
            <Tooltip title={label} placement={'top'}>
              <TextField
                {...textFieldProps}
                label={label}
                placeholder={placeholder}
                variant={variant}
                error={Boolean(error)}
                slotProps={{
                  input: {
                    type: 'text',
                    autoComplete: 'off',
                    ...textFieldProps.InputProps,
                    ...InputProps,
                  },
                  inputLabel: {
                    ...textFieldProps.InputLabelProps,
                    ...InputLabelProps,
                  },
                }}
              />
            </Tooltip>
          )}
          renderOption={(selectionProps, selection) => {
            if (isValidElement(selection.content)) return selection.content;

            const formattedSelection = formatItem
              ? formatItem(selection)
              : selection;

            const isLast =
              selections.findIndex((s) => s.id === selection.id) ===
              selections.length - 1;

            return (
              <Box
                {...selectionProps}
                key={selection.id}
                component={'li'}
                sx={styles.autocompleteOption}
              >
                <IconContent
                  dataTest={
                    selection.dataTest
                      ? `primitives-textFieldDropdown-${selection.dataTest}`
                      : undefined
                  }
                  key={selection.id}
                  icon={formattedSelection.icon}
                  iconProps={
                    formattedSelection.iconColor
                      ? {
                          fill: formattedSelection.iconColor.default.toString(),
                        }
                      : undefined
                  }
                  content={formattedSelection.content}
                  emptySelectionLabel={emptyValueLabel}
                />
                {isLast && hasNextPage && (
                  <div
                    ref={!isLoadingNextPage ? trackLoadMoreRef : undefined}
                    css={styles.lastPage}
                  >
                    <Spinner />
                  </div>
                )}
              </Box>
            );
          }}
          slotProps={{
            popper: {
              style:
                popperWidth !== undefined
                  ? { width: popperWidth, paddingLeft: 8 }
                  : undefined,
            },
            paper: {
              sx: css({
                '.MuiAutocomplete-listbox': css(styles.listBox, listBoxStyle),
                '.MuiListSubheader-root': styles.listSubheader,
                '.MuiAutocomplete-groupLabel': styles.groupLabel,
              }),
            },
          }}
          sx={computedStyles.autocomplete}
          selectOnFocus={true}
          size={size}
          value={value}
        />
      </FormControl>
      {Boolean(error || helperText) && (
        <FormHelperText error={Boolean(error)}>
          {error || helperText}
        </FormHelperText>
      )}
    </div>
  );
};

const styles = {
  base: css({
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    paddingTop: 4,
  }),
  spinner: css({
    height: 30,
  }),
  autocompleteOption: css({
    '&.MuiAutocomplete-option': {
      paddingLeft: 14,
    },
  }),
  lastPage: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  inputRoot: css({
    bottom: 3,
    paddingBottom: 0,
  }),
  inputLabelRoot: css({
    top: -3,
  }),
  inputLabelShrink: css({
    top: -3,
  }),
  listSubheader: css({
    fontSize: 12,
    lineHeight: 2,
    backgroundColor: '#F4F4F4',
    textTransform: 'uppercase',
    fontWeight: 900,
    textShadow: `0px 0px ${color.format(1)}`,
    color: color.format(-0.35),
  }),
  listBox: css({
    padding: 0,
  }),
  groupLabel: css({
    top: 0,
  }),
};
