import { ISectionLine, ISliderLine } from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import { SliderDefinition } from './types/slider.types.ts';

export function parseSliderAnswer(
  item: SliderDefinition,
  parentSection: ISectionLine,
  indices: number[],
): ISliderLine | undefined {
  if (!item) return;

  return {
    group: getProp(item, 'group')?.toString(),
    highlightOnHover: true,
    id: getProp(item, 'id', defaultId(indices)),
    isEnabled: getProp(item, 'isEnabled', true),
    isRequired: getProp(item, 'isRequired', false),
    parentId: parentSection.id,
    marks: getProp(item, 'marks'),
    question: getProp(item, 'question'),
    showIndex: false,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    type: 'sliderAnswer',
    value: getProp(item, 'value'),
  };
}
