/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { Attendee } from '../../redux/form-instance/types.ts';
import { AttendeeRow } from './components/AttendeeRow.tsx';

export interface IAttendanceProps {
  attendees: Attendee[];
  isFollowUpMandatory: boolean;
  onAttendeeChecked: (attendeeId: string, isChecked: boolean) => void;
  onAttendeeFollowUpClick: (attendeeId: string, isChecked: boolean) => void;
  onAllAttendedChecked: (isChecked: boolean) => void;
  canUpdate: boolean;
}

/**
 * Form control for tracking attendance in a form instance.
 */
export const Attendance: React.FC<IAttendanceProps> = ({
  attendees,
  canUpdate,
  isFollowUpMandatory,
  onAllAttendedChecked,
  onAttendeeChecked,
  onAttendeeFollowUpClick,
}) => {
  if (attendees.length === 0) {
    return (
      <div css={styles.base}>
        <Text color={color.format(-0.5)} italic={true}>
          {'No attendees to display.'}
        </Text>
      </div>
    );
  }

  const numberAttended = attendees.filter(
    ({ isAttending }) => isAttending,
  ).length;

  const allAttended = numberAttended === attendees.length;

  return (
    <div css={styles.base}>
      <Checkbox
        isChecked={allAttended}
        indeterminate={Boolean(!allAttended && numberAttended)}
        style={styles.selectAllCheck}
        label={'All Attended'}
        labelPlacement={'end'}
        onCheck={onAllAttendedChecked}
        isEnabled={canUpdate}
      />
      {attendees.map((attendee) => (
        <AttendeeRow
          key={`attendee-${attendee.id}`}
          attendee={attendee}
          onAttendeeChecked={onAttendeeChecked}
          onFollowUpActionClick={onAttendeeFollowUpClick}
          canUpdate={canUpdate}
          hasFollowUpSwitch={!isFollowUpMandatory}
        />
      ))}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    padding: 12,
    background: color.format(-0.02),
    borderRadius: 4,
    border: `1px solid ${color.format(-0.1)}`,
  }),
  selectAllCheck: css({ marginLeft: 14 }),
};
