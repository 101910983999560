/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Button } from '../Button/Button.tsx';
import { Icons } from '../Icon/Icons.tsx';
import { Text } from '../Text/Text.tsx';

export interface IErrorRetryProps {
  message: string;
  onClick: () => void;
  style?: SerializedStyles;
}

/**
 * Error splash button for prompting user retry.
 */
export const ErrorRetry: React.FC<IErrorRetryProps> = ({
  message,
  onClick,
  style,
}) => (
  <Button onClick={onClick} style={css(styles.base, style)}>
    <>
      <Text cursor={'pointer'} align={'center'}>
        {message}
      </Text>
      <Icons.refresh size={80} />
    </>
  </Button>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    cursor: 'pointer',
    borderRadius: 3,
    '&:hover': {
      backgroundColor: color.format(-0.08),
    },
  }),
};
