/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';

export interface ISwitchAnswerProps {
  id: string;
  label?: string;
  value?: boolean;
  isEnabled: boolean;
  error?: string;
}

/**
 * Switch used in form instances.
 */
export const SwitchAnswer: React.FC<ISwitchAnswerProps> = ({
  error,
  isEnabled,
  label,
  value,
}) => (
  <div css={styles.base}>
    <Markdown
      text={label}
      style={error ? styles.error : undefined}
      cursor={'pointer'}
    />
    <Switch
      isChecked={value}
      isEnabled={isEnabled}
      error={error}
      label={value ? 'Yes' : 'No'}
      labelStyle={{ marginRight: 0 }}
    />
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  error: css({
    color: COLORS.ERROR_RED,
  }),
};
