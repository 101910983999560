/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MaterialSlider from '@mui/material/Slider';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { head, is, last } from 'ramda';

export type SliderMark = {
  value: number;
  label?: string;
  description?: string;
};

export type SliderProps = {
  error?: string;
  errorStyle?: React.CSSProperties;
  helperText?: string;
  isEnabled?: boolean;
  label?: string;
  marks?: boolean | SliderMark[];
  onSelectedIndexChanged?: (toIndex: number) => void;
  step?: number;
  style?: SerializedStyles;
  track?: 'normal' | false | 'inverted';
  value?: number | number[];
};

export const Slider: React.FC<SliderProps> = ({
  error,
  errorStyle,
  helperText,
  isEnabled = true,
  label,
  marks = false,
  onSelectedIndexChanged,
  step,
  style,
  track,
  value = 0,
}) => {
  const min = (!is(Boolean, marks) && head(marks)?.value) || 0;
  const max = (!is(Boolean, marks) && last(marks)?.value) || 10;

  const numMarks = !is(Boolean, marks) ? marks.length : max;

  const materialMarks =
    (!is(Boolean, marks) &&
      marks.map((mark) => ({
        ...mark,
        label: (
          <Text
            size={16}
            style={styles.label}
            tooltip={mark.description || mark.label}
          >
            {mark.label}
          </Text>
        ),
      }))) ||
    marks;

  const hint = error || helperText;

  return (
    <div css={[styles.base, style]}>
      <FormControl fullWidth={true}>
        {label && <FormLabel>{label}</FormLabel>}
        {hint && (
          <FormHelperText error={Boolean(error)} style={errorStyle}>
            {hint}
          </FormHelperText>
        )}
        <MaterialSlider
          min={min}
          max={max}
          marks={materialMarks}
          value={value}
          step={step}
          sx={css({
            '&.MuiSlider-root': styles.root,
            '.MuiSlider-markLabel': styles.markLabel,
            '.MuiSlider-markLabel[data-index="0"]': styles.markLabelFirst,
            [`.MuiSlider-markLabel[data-index="${numMarks - 1}"]`]:
              styles.markLabelLast,
            '.MuiSlider-mark': styles.mark,
            '.MuiSlider-markActive': styles.markActive,
          })}
          onChange={(_, toIndex: number) => onSelectedIndexChanged?.(toIndex)}
          disabled={!isEnabled}
          track={track}
        />
      </FormControl>
    </div>
  );
};

const styles = {
  base: css({
    width: '100%',
    height: 60,
  }),
  label: css({
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    textWrap: 'wrap',
    overflow: 'hidden',
    display: '-webkit-box',
    whiteSpace: 'wrap',
    cursor: 'pointer',
    padding: '0px 10px',
    margin: '0px -10px',
    ':hover': {
      backgroundColor: color.format(-0.1),
    },
  }),
  markLabel: css({
    maxWidth: 120,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  }),
  markLabelFirst: css({
    transform: 'translateX(0)',
  }),
  markLabelLast: css({
    transform: 'translateX(-100%)',
  }),
  mark: css({
    height: 8,
    width: 8,
    marginLeft: -4,
    borderRadius: '100%',
  }),
  markActive: css({
    height: 8,
    width: 8,
    marginLeft: -4,
    border: `solid 1px ${color.format(-0.35)}`,
    opacity: 1,
  }),
  root: css({
    height: 1,
  }),
};
