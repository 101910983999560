/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanAction } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconContent } from '@seeeverything/ui.primitives/src/components/IconContent/IconContent.tsx';
import { Paper } from '@seeeverything/ui.primitives/src/components/Paper/Paper.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import momentTz from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { FormActionSource } from '../../../redux/formAction/formActionSlice.ts';
import { formActionSlice } from '../../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { formatDueBy } from '../../../util/util.instance.ts';

const getTheme = (status: 'Open' | 'Completed' | 'Cancelled' | 'Overdue') => {
  if (status === 'Cancelled')
    return {
      border: color.format(-0.15),
      description: color.format(-0.4),
      meta: {
        assignedToIcon: color.format(-0.3),
        assignedTo: color.format(-0.4),
      },
      icon: Icons.cancel,
      tooltip: 'Canceled',
    };

  if (status === 'Completed')
    return {
      border: color.create(COLORS.GREEN).alpha(0.4).css(),
      description: color.format(-0.4),
      meta: {
        assignedToIcon: color.format(-0.3),
        assignedTo: color.format(-0.4),
      },
      icon: Icons.verifiedUser,
      tooltip: 'Completed',
    };

  if (status === 'Open')
    return {
      border: color.create(COLORS.BLUE).alpha(1).css(),
      description: color.format(-0.8),
      meta: {
        assignedToIcon: color.format(-0.45),
        assignedTo: color.format(-0.6),
        dueIcon: color.format(-0.45),
        due: color.format(-0.6),
      },
      icon: Icons.info,
      tooltip: 'Open',
    };

  if (status === 'Overdue')
    return {
      border: COLORS.ERROR_RED,
      description: color.format(-0.8),
      meta: {
        assignedToIcon: color.format(-0.45),
        assignedTo: color.format(-0.6),
        dueIcon: COLORS.ERROR_RED,
        due: COLORS.ERROR_RED,
      },
      icon: Icons.accessTime,
      tooltip: 'Overdue',
    };
};

export type ActionProps = {
  action: FormActionPlanAction;
  source: FormActionSource;
};

export const Action: React.FC<ActionProps> = ({ action, source }) => {
  const dispatch = useFormsDispatch();

  const isEditable = useMemo(
    () => action.status !== 'Cancelled' && action.status !== 'Completed',
    [action.status],
  );

  const hasGoals = useFormsSelector(
    (state) => state.tenantState.tenant.module === 'coaching',
  );

  const dateContext = useDateContext();

  const dueInDisplay = useMemo(
    () =>
      formatDueBy({
        dueBy: action.dueBy,
        timezone: dateContext.tenantTimezone,
      }),
    [action.dueBy, dateContext.tenantTimezone],
  );

  const theme = useMemo(() => getTheme(action.status), [action.status]);

  const actionLabel = useFormsSelector((state) =>
    action.issueId
      ? str.titleCase(state.tenantState.tenant.locale.label.issueAction)
      : str.titleCase(state.tenantState.tenant.locale.label.action),
  );

  const handleLoadAction = useCallback(
    () => dispatch(formActionSlice.load({ actionId: action.id, source })),
    [action.id, dispatch, source],
  );

  const elDescription = (
    <div
      data-test={`forms-actionPlanItem-${action.description}`}
      css={styles.description}
    >
      <Text
        color={theme.description}
        size={18}
        cursor={'inherit'}
        ellipsis={true}
        block={true}
        italic={!isEditable}
      >
        {str.removeMarkdownAndSpecialCharacters(action.description)}
      </Text>
    </div>
  );

  const elAssignedTo = action.assignedTo && (
    <IconContent
      icon={'face'}
      iconProps={{
        fill: theme.meta.assignedToIcon,
        size: 12,
      }}
      content={action.assignedTo.name}
      contentStyle={styles.paddingLeft}
      textProps={{
        color: theme.meta.assignedTo,
        size: 12,
        weight: FontWeight.light,
        cursor: 'inherit',
        italic: !isEditable,
        ellipsis: true,
      }}
      style={styles.actionDataContainer}
    />
  );

  const elDueDate = action.dueBy && isEditable && (
    <>
      <IconContent
        icon={'notifications'}
        iconProps={{
          fill: theme.meta.dueIcon,
          size: 12,
        }}
        content={momentTz(action.dueBy)
          .tz(dateContext.tenantTimezone)
          .format(`D MMM YY`)}
        contentStyle={styles.paddingLeft}
        textProps={{
          color: theme.meta.due,
          size: 12,
          weight: FontWeight.light,
          cursor: 'inherit',
          ellipsis: true,
        }}
        style={styles.actionDataContainer}
      />
      {dueInDisplay && (
        <Text
          size={12}
          color={theme.meta.due}
          weight={FontWeight.light}
          cursor={'inherit'}
          ellipsis={true}
        >
          {dueInDisplay}
        </Text>
      )}
    </>
  );

  const Icon = theme.icon;

  const computedStyles = {
    statusIcon: {
      padding: '0 6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      background: theme.border,
    },
  };

  const elType = hasGoals && (
    <div css={styles.leftContainer}>
      <Icons.formatListBulleted
        fill={color.format(-0.3)}
        tooltip={actionLabel}
      />
    </div>
  );

  return (
    <div css={styles.base}>
      <div css={styles.outer}>
        {elType}
        <Paper
          style={styles.contentPaper}
          variant={!isEditable ? 'outlined' : 'elevation'}
        >
          <Button onClick={handleLoadAction} style={styles.button}>
            <div css={styles.itemOuter}>
              <Icon
                fill={'white'}
                tooltip={theme.tooltip}
                style={computedStyles.statusIcon}
              />
              <div css={styles.itemContainer}>
                {elDescription}
                <div css={styles.metaContainer}>
                  {elAssignedTo}
                  {elDueDate}
                </div>
              </div>
            </div>
          </Button>
        </Paper>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  itemContainer: css({
    padding: '5px 22px',
    flex: '1 1 auto',
    width: 0, // Allows text to ellipsis.
    boxSizing: 'border-box',
  }),
  outer: css({
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    alignItems: 'center',
    gap: 8,
  }),
  leftContainer: css({
    flex: '0 0 auto',
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
  }),
  itemOuter: css({
    display: 'flex',
    alignItems: 'stretch',
  }),
  paddingLeft: css({
    paddingLeft: 5,
  }),
  metaContainer: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    ...CommonStyles.MaterialCubicTransitions,
  }),
  button: css({
    display: 'flex',
    flex: '1 1 0px',
    flexDirection: 'column',
    background: '#fcfcfc',
    overflow: 'hidden',
    alignItems: 'stretch',
    width: '100%',
    position: 'relative',
    '&:hover > div div': {
      color: COLORS.BLUE,
    },
    '&:hover > div div div svg': {
      fill: `${COLORS.BLUE} !important`,
    },
    cursor: 'pointer',
  }),
  description: css({
    width: '100%',
    paddingTop: 8,
    paddingBottom: 7,
    marginBottom: 3,
    borderBottom: `solid 1px ${color.format(-0.1)}`,
    cursor: 'pointer',
  }),
  actionDataContainer: css({
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0',
    marginRight: 12,
    cursor: 'pointer',
  }),
  contentPaper: css({
    flex: '1 1 auto',
    borderRadius: 4,
    width: '100%',
    margin: '6px 0',
    maxWidth: '100%',
    zIndex: 0,
  }),
};
