/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export type StreamHeaderProps = {
  title: string;
  onCloseClick?: () => void;
};

/**
 * Header on stream player.
 */
export const StreamHeader: React.FC<StreamHeaderProps> = ({
  title,
  onCloseClick,
}) => (
  <div css={styles.base}>
    <div css={styles.titleContainer}>
      <Icons.movie fill={color.format(-0.8)} />
      <Text style={styles.titleText}>{title}</Text>
    </div>
    <Icons.clear onClick={onCloseClick} />
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    padding: '6px 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'none',
    borderRadius: '3px 3px 0 0',
    background: 'white',
  }),
  titleContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  }),
  titleText: css({
    color: color.format(-0.8),
    marginLeft: 6,
  }),
};
