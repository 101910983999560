/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import {
  FormActionPlanAction,
  FormAnswerAutomatedAction,
  FormInsight,
} from '@se/data/forms/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { ascend, prop, sortWith } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { formActionSlice } from '../../../redux/formAction/index.ts';
import { getActionTitle } from '../../../redux/formAction/util.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { SelectAutomatedActionPopup } from '../../SelectAutomatedActionPopup/SelectAutomatedActionPopup.tsx';
import { SelectLearningAreaPopup } from './SelectLearningAreaPopup.tsx';

const sortAutomatedActions = sortWith<FormAnswerAutomatedAction>([
  ascend(prop('formQuestionIndex')),
  ascend(prop('issueInsightClassificationIndex')),
  ascend(prop('label')),
]);

type CreateActionArgs = {
  automatedAction?: FormAnswerAutomatedAction;
  insightLearningArea?: FormInsight;
};

export type AddActionProps = {
  error?: boolean;
  goalId?: string;
  instanceId: string;
  issueId?: string;
  message: string;
  showRecommendedActions?: boolean;
  showInsightLearningAreas?: boolean;
  style?: Interpolation<Theme>;
  existingActions: FormActionPlanAction[];
};

export const AddAction: React.FC<AddActionProps> = ({
  error,
  goalId,
  instanceId,
  issueId,
  message,
  showRecommendedActions,
  showInsightLearningAreas,
  style,
  existingActions,
}) => {
  const dispatch = useFormsDispatch();
  const [dropdownShowing, setDropdownShowing] = useState(false);

  const locale = useTenantLocale();

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const answerAutomatedActions = useFormsSelector(
    (state) => state.automatedAction.answerAutomatedActions,
  );

  const isLoading = useFormsSelector((state) => {
    if (!showRecommendedActions) return false;
    return state.automatedAction.isLoading;
  });

  const insights = useFormsSelector((state) => state.formInsight.insights);

  const recommendedActions = useMemo(() => {
    if (!showRecommendedActions) return [];

    const unsorted = Object.values(answerAutomatedActions)
      .filter((aa) => !aa.isLoading)
      .map((aa) => aa.automatedActions)
      .flat()
      .filter((aa) => !aa.createdActionId);

    return sortAutomatedActions(unsorted);
  }, [answerAutomatedActions, showRecommendedActions]);

  const insightLearningAreas = useMemo<FormInsight[]>(() => {
    if (!showInsightLearningAreas) return [];
    if (recommendedActions.length) return [];
    return insights.filter((i) => {
      if (
        existingActions?.some(
          (existingAction) =>
            existingAction.insightId === i.id &&
            existingAction.status !== 'Cancelled',
        )
      )
        return false;

      return (
        i.type === 'LearningArea' ||
        (i.type === 'Neutral' && i.classificationValues.length > 0)
      );
    });
  }, [
    showInsightLearningAreas,
    recommendedActions.length,
    insights,
    existingActions,
  ]);

  const assignedTo = useMemo(
    () =>
      instanceSubject?.kind === 'Person'
        ? { id: instanceSubject.id, name: instanceSubject.name }
        : undefined,
    [instanceSubject],
  );

  const handleNewActionDraft = useCallback(
    ({ automatedAction, insightLearningArea }: CreateActionArgs = {}) => {
      const overrideIssueId = automatedAction?.issueId ?? issueId;
      const overrideInsightId = automatedAction?.insightId;

      const insightId = insightLearningArea?.id ?? overrideInsightId;

      dispatch(
        formActionSlice.newDraft({
          actionId: uuid.generate(),
          title: getActionTitle(locale, insightId, overrideIssueId),
          assignedTo,
          goalId,
          insightId,
          issueId: overrideIssueId,
          formInstanceSubject: instanceSubject
            ? {
                id: instanceSubject.id,
                label: instanceSubject.name,
                type: instanceSubject.kind === 'Person' ? 'PERSON' : 'TEAM',
              }
            : undefined,
          openedFromFormInstanceId: instanceId,
          description: automatedAction?.description,
          answerAutomatedActionId: automatedAction?.id,
        }),
      );
    },
    [
      assignedTo,
      dispatch,
      goalId,
      instanceId,
      instanceSubject,
      issueId,
      locale,
    ],
  );

  const hideDropdown = useCallback(() => setDropdownShowing(false), []);
  const showDropdown = useCallback(() => setDropdownShowing(true), []);

  const computedStyles = {
    button: css({
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'flex-start',
      color: error ? COLORS.ERROR_RED : COLORS.BLUE,
    }),
  };

  return (
    <div css={[styles.base, style]}>
      <TextButton
        color={error ? COLORS.ERROR_RED : COLORS.BLUE}
        cursor={'inherit'}
        dataTest={'forms-actionPlan-addActionButton'}
        highlightOnHover={true}
        icon={Icons.plus}
        iconStyle={error ? { fill: COLORS.ERROR_RED } : undefined}
        onClick={
          !isLoading && recommendedActions.length
            ? showDropdown
            : handleNewActionDraft
        }
        buttonStyle={computedStyles.button}
      >
        <div css={styles.buttonContent}>{message}</div>
      </TextButton>
      {isLoading && <Spinner />}
      {!isLoading && recommendedActions?.length > 0 && (
        <Button onClick={showDropdown}>
          <>
            <div css={styles.automatedActions}>
              <Icons.bulbAndSparkles fill={'white'} />
              <Text
                uppercase={true}
                color={'white'}
                size={15}
                cursor={'pointer'}
              >{`${recommendedActions.length} recommended ${str.plural('action', recommendedActions.length)}`}</Text>
            </div>
            {dropdownShowing && recommendedActions?.length > 0 && (
              <SelectAutomatedActionPopup
                onHide={hideDropdown}
                automatedActions={recommendedActions}
                onCreateAction={(automatedAction) =>
                  handleNewActionDraft({ automatedAction })
                }
                showIssueLabel={true}
              />
            )}
          </>
        </Button>
      )}
      {!isLoading && insightLearningAreas.length > 0 && (
        <Button onClick={showDropdown}>
          <>
            <div css={styles.learningAreas}>
              <Icons.school fill={COLORS.BLUE} />
              <Text
                color={COLORS.BLUE}
                size={15}
                cursor={'pointer'}
                weight={300}
              >{`${insightLearningAreas.length} ${str.plural('learning area', insightLearningAreas.length)} without follow-up ${str.plural(locale.label.action)}`}</Text>
            </div>
            {dropdownShowing && insightLearningAreas.length > 0 && (
              <SelectLearningAreaPopup
                onHide={hideDropdown}
                insightLearningAreas={insightLearningAreas}
                onCreateAction={(insightLearningArea) =>
                  handleNewActionDraft({ insightLearningArea })
                }
              />
            )}
          </>
        </Button>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  }),
  buttonContent: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  }),
  automatedActions: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    padding: '3px 15px 3px 8px',
    backgroundColor: COLORS.ORANGE,
    border: `solid 1px ${color.format(-0.1)}`,
    borderRadius: 5,
    ':hover': {
      backgroundColor: color.create(COLORS.ORANGE).brighten(0.3).hex(),
    },
  }),
  learningAreas: css({
    border: `solid 1px ${COLORS.BLUE}`,
    borderRadius: 10,
    fill: 'white',
    padding: '2px 8px',
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
  }),
};
