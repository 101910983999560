/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback } from 'react';
import { FormFileAction } from '../../redux/form-file/types.ts';
import Content from './components/Content.tsx';
import FileName from './components/FileName.tsx';
import { Header } from './components/Header.tsx';
import { StatusIconButton } from './components/StatusIconButton.tsx';

export interface IFormFileProps {
  action?: FormFileAction;
  canDelete?: boolean;
  canDownload?: boolean;
  canRetry?: boolean;
  canView?: boolean;
  displaySize: string;
  error?: string;
  extension: string;
  id: string;
  name: string;
  onDelete?: (fileId: string) => void;
  onDownload?: (fileId: string) => void;
  onView?: (fileId: string) => void;
  uploadedByName: string;
  uploadedDate?: string;
}

/**
 * Preview file thumbnail on a form instance.
 */
export const FormFile: React.FC<IFormFileProps> = ({
  action,
  canDelete = false,
  canDownload = false,
  canRetry,
  canView = false,
  displaySize,
  error,
  extension,
  id,
  name,
  onDelete,
  onDownload,
  onView,
  uploadedByName,
  uploadedDate,
}) => {
  const handleDownloadClicked = useCallback(
    () => onDownload?.(id),
    [id, onDownload],
  );

  const handleDeleteClicked = useCallback(() => onDelete?.(id), [id, onDelete]);

  const handleViewClicked = useCallback(() => onView?.(id), [id, onView]);

  const hasMetadata = uploadedByName || uploadedDate;

  const isProcessing = Boolean(action && !error);

  const computedStyles = {
    base: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 165,
      maxWidth: 165,
      height: 251,
      border: 'solid 1px #dedede',
      borderRadius: 10,
      boxShadow: `0 2px 6px 0 ${color.format(-0.1)}`,
      overflow: 'hidden',
      position: 'relative',
      boxSizing: 'border-box',
      backgroundColor: error ? color.RED : undefined,
    }),
    noMetaFileName: css({
      maxWidth: canDownload || canView || Boolean(error) ? '83%' : '100%',
    }),
  };

  return (
    <div css={computedStyles.base}>
      {isProcessing ? (
        <div css={styles.processing}>
          <Spinner />
          <Text color={color.format(-0.7)} size={13} marginTop={45}>
            {`${str.titleCase(action)}...`}
          </Text>
        </div>
      ) : (
        <>
          <Header
            extension={extension}
            onDeleteClick={canDelete ? handleDeleteClicked : undefined}
            onViewClick={canView ? handleViewClicked : undefined}
            size={hasMetadata ? 'SMALL' : 'LARGE'}
          />
          {hasMetadata ? (
            <>
              <FileName name={name} size={displaySize} />
              <Content
                uploadedBy={uploadedByName}
                uploadedAt={uploadedDate}
                error={error}
              />
              <StatusIconButton
                isError={Boolean(error)}
                action={action}
                canDownload={canDownload}
                onDownloadClick={
                  canView ? handleViewClicked : handleDownloadClicked
                }
                canRetry={canRetry}
                canView={canView}
              />
            </>
          ) : (
            <div css={styles.downloadAndName}>
              <FileName
                name={name}
                size={displaySize}
                style={css(
                  styles.noMetaFileName,
                  computedStyles.noMetaFileName,
                )}
              />
              <StatusIconButton
                isError={Boolean(error)}
                action={action}
                canDownload={canDownload}
                onDownloadClick={
                  canView ? handleViewClicked : handleDownloadClicked
                }
                canRetry={canRetry}
                canView={canView}
                style={styles.noMetaStatusIcon}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  processing: css({
    height: 162,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '45px 0 0 5px',
    marginTop: -45,
  }),
  downloadAndName: css({
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: 10,
    width: '100%',
  }),
  noMetaFileName: css({
    flex: '1 1 auto',
  }),
  noMetaStatusIcon: css({
    flex: 0,
  }),
};
