import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CoachingSummarySection,
  CoachingSummarySectionFilter,
  CoachingSummarySectionInsight,
  CoachingSummarySectionIssue,
  CoachingSummarySectionType,
} from '@se/data/forms/types.ts';

type LineId = string;

export type FormCoachingSummaryState = {
  instanceId?: string;
  sections: Record<LineId, CoachingSummarySection>;
  loading: Record<LineId, { isLoading: boolean; stale: boolean }>;
  loadErrors: Record<LineId, boolean>;
};

const DEFAULT_STATE: FormCoachingSummaryState = {
  sections: {},
  loading: {},
  loadErrors: {},
};

const slice = createSlice({
  name: 'libs/forms/coachingSummary',
  initialState: DEFAULT_STATE,
  reducers: {
    clearCoachingSummaries: () => DEFAULT_STATE,
    markSectionAsLoading(
      state,
      action: PayloadAction<{
        instanceId: string;
        lineId: string;
        stale: boolean;
        clearExisting: boolean;
      }>,
    ) {
      state.loading[action.payload.lineId] = {
        stale: action.payload.stale,
        isLoading: true,
      };

      if (action.payload.clearExisting) {
        const section = state.sections[action.payload.lineId];
        if (section.sectionType === 'LearningAreasIdentified') {
          section.learningAreas = [];
        }
        if (section.sectionType === 'HighlightsIdentified') {
          section.highlights = [];
        }
        if (section.sectionType === 'IssuesIdentified') {
          section.issues = [];
        }
      }
    },
    loadCoachingSummary(
      state,
      action: PayloadAction<{
        instanceId: string;
        lineId: string;
        sectionType: CoachingSummarySectionType;
        sectionFilter: CoachingSummarySectionFilter;
      }>,
    ) {
      state.instanceId = action.payload.instanceId;
      state.loadErrors[action.payload.lineId] = false;
      state.loading[action.payload.lineId] = {
        stale: false,
        isLoading: true,
      };
    },
    loadCoachingSummaryError(
      state,
      action: PayloadAction<{
        instanceId: string;
        lineId: string;
      }>,
    ) {
      state.instanceId = action.payload.instanceId;
      state.loadErrors[action.payload.lineId] = true;
      state.loading[action.payload.lineId] = {
        stale: false,
        isLoading: false,
      };
    },
    loadedCoachingSummary(
      state,
      action: PayloadAction<{
        instanceId: string;
        lineId: string;
        issues?: CoachingSummarySectionIssue[];
        insights?: CoachingSummarySectionInsight[];
        sectionType: CoachingSummarySectionType;
        sectionFilter: CoachingSummarySectionFilter;
      }>,
    ) {
      if (action.payload.sectionType === 'LearningAreasIdentified') {
        state.sections[action.payload.lineId] = {
          sectionType: 'LearningAreasIdentified',
          sectionFilter: action.payload.sectionFilter,
          learningAreas: action.payload.insights,
        };
      }

      if (action.payload.sectionType === 'HighlightsIdentified') {
        state.sections[action.payload.lineId] = {
          sectionType: 'HighlightsIdentified',
          sectionFilter: action.payload.sectionFilter,
          highlights: action.payload.insights,
        };
      }

      if (action.payload.sectionType === 'IssuesIdentified') {
        state.sections[action.payload.lineId] = {
          sectionType: 'IssuesIdentified',
          sectionFilter: action.payload.sectionFilter,
          issues: action.payload.issues,
        };
      }

      state.instanceId = action.payload.instanceId;
      state.loadErrors[action.payload.lineId] = false;
      state.loading[action.payload.lineId] = {
        isLoading: false,
        stale: false,
      };
    },
  },
});

export const {
  clearCoachingSummaries,
  loadCoachingSummary,
  loadCoachingSummaryError,
  loadedCoachingSummary,
  markSectionAsLoading,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormCoachingSummaryState;
