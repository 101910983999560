import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy, PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  FormAction,
  FormActionStatusWithOverdue,
  FormActionVerificationStatus,
} from '../types.ts';

type ActionsResponse = {
  pageInfo: PageInfoResponse;
  actions: FormAction[];
};

type GetActionsArgs = {
  actionLabel: string;
  endDate?: string;
  fetchAllPages?: boolean;
  hasParentGoal?: boolean;
  issueActionLabel: string;
  isSupporting?: boolean;
  orderBy: OrderBy[];
  pageNumber?: number;
  pageSize?: number;
  personId?: string;
  startDate?: string;
  status: {
    include?: FormActionStatusWithOverdue[];
    exclude?: FormActionStatusWithOverdue[];
  };
  teamId?: string;
};

export const getActions = async (
  client: IGraphQLClient,
  args: GetActionsArgs,
): Promise<QueryResult<ActionsResponse>> => {
  if (!args.fetchAllPages) return getActionsPage(client, args);

  try {
    const actions = await getActionsRecursive(client, args);
    return {
      isSuccess: true,
      data: {
        actions,
        pageInfo: {
          currentPage: 1,
          hasNextPage: false,
          pageSize: actions.length,
          totalCount: actions.length,
          totalPages: 1,
        },
      },
    };
  } catch (error) {
    log.error(error);
    return { isSuccess: false, error, errorReason: 'UNKNOWN' };
  }
};

const getActionsRecursive = async (
  client: IGraphQLClient,
  args: GetActionsArgs,
  actions: FormAction[] = [],
  pageNumber = 1,
): Promise<FormAction[]> => {
  const response = await getActionsPage(client, {
    actionLabel: args.actionLabel,
    endDate: args.endDate,
    hasParentGoal: args.hasParentGoal,
    issueActionLabel: args.issueActionLabel,
    isSupporting: args.isSupporting,
    orderBy: args.orderBy,
    pageNumber,
    pageSize: args.pageSize,
    personId: args.personId,
    startDate: args.startDate,
    status: args.status,
    teamId: args.teamId,
  });

  if (!response.isSuccess) {
    throw new Error(
      `Failed to retrieve actions for page ${pageNumber} and entity id ${args.personId ?? args.teamId}`,
    );
  }

  const combinedActions = actions.concat(response.data.actions);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return combinedActions;

  return getActionsRecursive(
    client,
    args,
    combinedActions,
    pageInfo.currentPage + 1,
  );
};

const getActionsPage = async (
  client: IGraphQLClient,
  args: GetActionsArgs,
): Promise<QueryResult<ActionsResponse>> => {
  try {
    const response = await client.query<{
      forms: {
        formActions: {
          pageInfo: {
            currentPage: number;
            hasNextPage: boolean;
            pageSize: number;
            totalCount: number;
            totalPages: number;
          };
          nodes: Array<{
            id: string;
            assignedTo: {
              id: string;
              name: string;
            };
            description: string;
            status: FormActionStatusWithOverdue;
            dueBy: string;
            issueId: string;
            createdAt: string;
            verification?: {
              status: FormActionVerificationStatus;
            };
          }>;
        };
      };
    }>({
      query: gql`
        query FormActions(
          $orderBy: [OrderByInput!]
          $pageNumber: Int!
          $pageSize: Int!
          $personId: ID
          $teamId: ID
          $startDate: String
          $endDate: String
          $status: FormActionStatusFilterInput!
          $hasParentGoal: Boolean
          $isSupporting: Boolean
        ) {
          forms {
            formActions(
              assignedToPersonId: $personId
              assignedToTeamId: $teamId
              endDate: $endDate
              hasParentGoal: $hasParentGoal
              isSupporting: $isSupporting
              orderBy: $orderBy
              pagination: { size: $pageSize, pageNumber: $pageNumber }
              startDate: $startDate
              status: $status
            ) {
              pageInfo {
                currentPage
                hasNextPage
                pageSize
                totalCount
                totalPages
              }
              nodes {
                id
                assignedTo {
                  id
                  name
                }
                goalId
                description
                status
                dueBy
                issueId
                createdAt
                verification {
                  status
                }
              }
            }
          }
        }
      `,
      variables: {
        orderBy: args.orderBy,
        personId: args.personId,
        teamId: args.teamId,
        status: args.status,
        startDate: args.startDate,
        endDate: args.endDate,
        hasParentGoal: args.hasParentGoal,
        isSupporting: args.isSupporting,
        pageNumber: args.pageNumber ?? 1,
        pageSize: args.pageSize ?? 10,
      },
      fetchPolicy: 'network-only',
    });

    const formActions = response.data.forms.formActions;

    return {
      isSuccess: true,
      data: {
        pageInfo: formActions.pageInfo,
        actions: formActions.nodes.map((a) => ({
          id: a.id,
          assignedTo: a.assignedTo && {
            id: a.assignedTo.id,
            name: a.assignedTo.name,
          },
          description: a.description ?? undefined,
          status: a.status,
          dueBy: a.dueBy ?? undefined,
          issueId: a.issueId ?? undefined,
          createdAt: a.createdAt,
          verification: a.verification
            ? { status: a.verification.status }
            : undefined,
          type: 'FormAction',
          typeLabel: a.issueId
            ? args.issueActionLabel
            : args.isSupporting
              ? `${args.actionLabel} (Supporting)`
              : args.actionLabel,
          statusLabel:
            a.verification?.status === 'Returned'
              ? `${a.status} (Returned)`
              : a.status,
        })),
      },
    };
  } catch (error) {
    log.error(
      `Failed to retrieve actions page for entity id ${args.personId ?? args.teamId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
