import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';
import { Team } from './types.ts';

export type UseTeamsQueryOptions = {
  inputFilter?: string;
  isEnabled: boolean;
};

export const useTeamsQuery = (
  client: IGraphQLClient,
  args: UseTeamsQueryOptions,
) => {
  const { data, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    enabled: args.isEnabled,
    queryKey: [
      {
        key: 'teamsDropdown.teams',
        inputFilter: args.inputFilter,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const response = await client.query<{
        orgHierarchy: { teams: { nodes: Team[] } };
      }>({
        query: gql`
          query DropdownTeams($name: String) {
            orgHierarchy {
              teams(
                name: $name
                pagination: { pageNumber: 1, size: 50 }
                orderBy: { fieldName: "path", direction: Ascending }
                includeInactive: false
              ) {
                nodes {
                  id
                  name
                  businessUnit
                  path
                }
              }
            }
          }
        `,
        fetchPolicy: 'cache-first',
        variables: {
          name: queryKey[0].inputFilter?.toLowerCase(),
        },
      });

      const people = response.data.orgHierarchy.teams.nodes;
      if (!people?.length) return [];

      return response.data.orgHierarchy.teams.nodes.map(
        (team): ISelectionListItem<IListItemLabel, Team> => ({
          id: team.id,
          icon: Icons.face,
          value: team,
          content: { description: team.path, text: team.name },
        }),
      );
    },
  });

  return {
    teams: data,
    isFetchingTeams: isFetching,
  };
};
