/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';

export const IssueGroupSkeleton = () => {
  const elHeader = (
    <div css={styles.line}>
      <Skeleton
        variant={'rectangular'}
        height={45}
        width={15}
        style={css({ borderRadius: 3 })}
      />
      <Skeleton
        variant={'rectangular'}
        height={45}
        width={'40%'}
        style={css({ borderRadius: 3 })}
      />
    </div>
  );

  const elSection = (
    <div css={styles.line}>
      <Skeleton
        variant={'rectangular'}
        height={120}
        width={'100%'}
        style={css({ borderRadius: 3 })}
      />
    </div>
  );

  const elIssue = (
    <div css={styles.line}>
      <Skeleton
        variant={'rectangular'}
        height={45}
        width={'100%'}
        style={css({ borderRadius: 3 })}
      />
    </div>
  );

  const elIssueAction = (
    <div css={styles.line}>
      <div css={styles.padding} />
      <Skeleton variant={'circular'} height={30} width={30} />
      <Skeleton
        variant={'rectangular'}
        height={30}
        width={'20%'}
        style={css({ borderRadius: 3, flex: '1 1 auto' })}
      />
      <Skeleton
        variant={'rectangular'}
        height={30}
        width={'40%'}
        style={css({ borderRadius: 3, flex: '1 1 auto' })}
      />
      <Skeleton
        variant={'rectangular'}
        height={30}
        width={30}
        style={css({ borderRadius: 3, alignSelf: 'flex-end' })}
      />
    </div>
  );
  return (
    <div css={styles.base}>
      {elHeader}
      {elSection}
      {elIssue}
      {elIssueAction}
      {elIssueAction}
      {elIssue}
      {elIssueAction}
      {elIssueAction}
      <div css={styles.section} />
      {elHeader}
      {elSection}
      {elIssue}
      {elIssueAction}
      {elIssueAction}
      {elIssue}
      {elIssueAction}
      {elIssueAction}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginTop: -16,
  }),
  line: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    padding: 8,
  }),
  padding: css({
    width: 30,
  }),
  section: css({
    height: 45,
  }),
};
