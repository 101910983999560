import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GoalsActionsGridQueryArgs } from '@se/data/forms/query/goalsActionsGrid.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';

export type GoalsActionsGridState = {
  reloadFlag: boolean;
  orderBy?: OrderBy;
  orderDueByDirection: OrderBy['direction'];
  filter: 'Open' | 'All';
};

const DEFAULT_STATE: GoalsActionsGridState = {
  reloadFlag: false,
  orderDueByDirection: 'Ascending',
  filter: 'Open',
};

const slice = createSlice({
  name: 'libs/forms/goalsActionsGrid',
  initialState: DEFAULT_STATE,
  reducers: {
    downloadGrid(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        columnHeaderLabels: string[];
        queryArgs: GoalsActionsGridQueryArgs;
        title: string;
      }>,
    ) {
      return state;
    },
    columnOrderBy(
      state,
      action: PayloadAction<{
        columnId: string;
        direction: 'Ascending' | 'Descending';
      }>,
    ) {
      const columnId = action.payload.columnId;
      if (columnId === 'dueBy') {
        state.orderDueByDirection = action.payload.direction;
        state.orderBy = undefined;
      } else {
        state.orderBy = {
          fieldName: action.payload.columnId,
          direction: action.payload.direction,
        };
      }
    },
    reloadGrid(state) {
      state.reloadFlag = !state.reloadFlag;
    },

    loadMoreClicked(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        dashboardId: string;
        dateRangeLabel: string;
        endDate: string;
        entityId: string;
        entityType: 'PERSON' | 'TEAM';
        startDate: string;
      }>,
    ) {
      return state;
    },
    updateFilter(state, action: PayloadAction<{ to: 'Open' | 'All' }>) {
      state.filter = action.payload.to;
    },
  },
});

export const {
  columnOrderBy,
  downloadGrid,
  loadMoreClicked,
  reloadGrid,
  updateFilter,
} = slice.actions;
export const reducer = slice.reducer;
export type State = GoalsActionsGridState;
