/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import React, { useCallback } from 'react';
import { SignoffEvent } from '../../redux/form-instance/types.ts';
import { FormInstanceAppealState } from '../../redux/formInstanceAppeal/formInstanceAppealSlice.ts';
import {
  AppealInputChangeEvent,
  AppealInputChangeEventHandler,
  AppealInputValidationEventHandler,
  AppealStateErrors,
} from '../../redux/formInstanceAppeal/types.ts';
import { ISignoffProps, Signoff } from '../Signoff/Signoff.tsx';
import { AppealInput } from './components/AppealInput.tsx';

export interface ISignoffWithAppealProps extends ISignoffProps {
  appeal: FormInstanceAppealState;
  appealOutcomeReasons?: ISelectionListItem[];
  appealReasonCategories?: ISelectionListItem[];
  assignedToLabel: string;
  canChangeAppealDetails?: boolean;
  onAppealInputChange?: AppealInputChangeEventHandler;
  onAppealInputValidation?: AppealInputValidationEventHandler;
  reviewerLabel: string;
  subjectLabel: string;
}

/**
 * The signoff component for one or more users with appeals section
 */
export const SignoffWithAppeal: React.FC<ISignoffWithAppealProps> = ({
  appeal,
  appealOutcomeReasons,
  appealReasonCategories,
  assignedToLabel,
  canAppealAsManager,
  canAppealAsSubject,
  canChangeAppealDetails,
  cannotAppeal,
  errorPreventSignOff,
  instanceId,
  instanceStatus,
  isSpinning,
  onAppealClick,
  onAppealInputChange,
  onAppealInputValidation,
  onlyManagerCanAppeal,
  onRevertSignoffClick,
  onSignoffClick,
  reviewerCanRevert,
  reviewerCanSignOff,
  reviewerDescription,
  reviewerId,
  reviewerLabel = 'Reviewer',
  reviewerName,
  reviewerSignOff,
  reviewerSignOffRequired,
  subjectCanRevert,
  subjectCanSignOff,
  subjectDescription,
  subjectId,
  subjectLabel = 'Subject',
  subjectName,
  subjectSignOff,
  subjectSignOffRequired,
}) => {
  const handleSignoffClick = useCallback(
    (e: SignoffEvent) => {
      if (!onSignoffClick) return;

      const appealErrors = getAppealErrors(appeal, e.personType);
      onAppealInputValidation?.({ errors: appealErrors });
      if (appealErrors.errorCount) return;

      const { outcome, outcomeReason, reason, response } = appeal;

      onSignoffClick({
        ...e,
        appealResponse: { outcome, outcomeReason, reason, response },
      });
    },
    [appeal, onAppealInputValidation, onSignoffClick],
  );

  const handleAppealInputChange = useCallback(
    (e: AppealInputChangeEvent) => {
      onAppealInputChange(e);

      if (!appeal.errors?.errorCount) return;

      onAppealInputValidation?.({
        errors: getAppealErrors({ ...appeal, [e.field]: e.to }, 'REVIEWER'),
      });
    },
    [appeal, onAppealInputChange, onAppealInputValidation],
  );

  return (
    <div css={styles.base}>
      {appeal?.request && (
        <div css={styles.appeals}>
          <AppealInput
            appeal={appeal}
            appealOutcomeReasons={appealOutcomeReasons}
            appealReasonCategories={appealReasonCategories}
            assignedToLabel={assignedToLabel}
            canChange={canChangeAppealDetails}
            instanceId={instanceId}
            onAppealInputChange={handleAppealInputChange}
          />
        </div>
      )}
      <Signoff
        canAppealAsManager={canAppealAsManager}
        canAppealAsSubject={canAppealAsSubject}
        cannotAppeal={cannotAppeal}
        errorPreventSignOff={errorPreventSignOff}
        instanceId={instanceId}
        instanceStatus={instanceStatus}
        isSpinning={isSpinning}
        onAppealClick={onAppealClick}
        onlyManagerCanAppeal={onlyManagerCanAppeal}
        onRevertSignoffClick={onRevertSignoffClick}
        onSignoffClick={handleSignoffClick}
        reviewerCanRevert={reviewerCanRevert}
        reviewerCanSignOff={reviewerCanSignOff}
        reviewerDescription={reviewerDescription}
        reviewerId={reviewerId}
        reviewerLabel={reviewerLabel}
        reviewerName={reviewerName}
        reviewerSignOff={reviewerSignOff}
        reviewerSignOffRequired={reviewerSignOffRequired}
        subjectCanRevert={subjectCanRevert}
        subjectCanSignOff={subjectCanSignOff}
        subjectDescription={subjectDescription}
        subjectId={subjectId}
        subjectLabel={subjectLabel}
        subjectName={subjectName}
        subjectSignOff={subjectSignOff}
        subjectSignOffRequired={subjectSignOffRequired}
      />
    </div>
  );
};

const getAppealErrors = (
  appeal: FormInstanceAppealState,
  source: 'REVIEWER' | 'SUBJECT',
): AppealStateErrors => {
  const { outcome, outcomeReason, reason, request, response } = appeal;

  if (!appeal.request || source === 'SUBJECT') return { errorCount: 0 };

  const errors = {
    outcomeError: !outcome,
    outcomeReasonError: (!outcome || outcome === 'Declined') && !outcomeReason,
    reasonError: !reason,
    requestError: !request,
    responseError: !response,
  };

  return {
    ...errors,
    errorCount: Object.values(errors).filter((value) => value === true).length,
  };
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  appeals: css({
    paddingBottom: 20,
  }),
};
